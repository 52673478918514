import { loadUserFromStorage, signinRedirect } from '@services/oauthService'
import appStore from '@stores/appStore'
import ui from '@stores/dashboard'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'

const AuthWrapper = ({ children, onReady }) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      ui.didmount('a.Tab', '.Tabs-Inner')
    }, 1000)

    window.addEventListener('beforeunload', cleanUp)
    if (!appStore.authenticated) {
      bootstrap().then(() => {
        setReady(true)
        onReady && onReady()
      })
    }
    return () => {
      cleanUp()
    }
  }, [])

  const bootstrap = async () => {
    const user = await loadUserFromStorage()
    if (user) {
      await appStore.init()
    } else if (window.location.pathname !== '/signin-oidc') {
      await signinRedirect({ redirectUri: window.location.pathname })
    }
    return true
  }

  const cleanUp = useCallback(() => {
    ui.unmount()
    window.removeEventListener('beforeunload', cleanUp)
  }, [])

  return appStore.authenticated && ready ? <>{children}</> : null
}

export default observer(AuthWrapper)
