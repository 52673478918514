import { LoadingOutlined } from '@ant-design/icons'
import { ApiService } from '@services/ApiService'
import router from '@stores/router'
import { getQueryParams } from '@utils/functions'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'

const Template = () => {
  const { id, insurerItemId, type, treatmentPlanId } = getQueryParams(router.location.search, router.location.state)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    document.title = getTabName(type)
    if (id) {
      setLoading(true)
      getTemplate(id, getFunctionGetTemplate(type), insurerItemId, treatmentPlanId)
    } else {
      setError(new Error('Missing parameter id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTabName = type => {
    switch (type) {
      case 'finalDisbursement':
        return 'Final disbursement'
      case 'demandLetter':
        return 'Demand Letter'
      case 'terminationLetter':
        return 'Termination Letter'
      case 'logTemplate':
        return 'Letter of Guarantee'
      case 'loaTemplate':
        return 'Letter of Authorization'
      default:
        return 'kawaconn Template'
    }
  }

  const getFunctionGetTemplate = type => {
    switch (type) {
      case 'finalDisbursement':
        return ApiService.MvaService.PrintFinalDisbursement
      case 'demandLetter':
        return ApiService.MvaService.PrintDemandLetter
      case 'terminationLetter':
        return ApiService.MvaService.PrintTerminationLetter
      case 'logTemplate':
        return ApiService.MvaService.PrintLog
      case 'loaTemplate':
        return ApiService.MvaService.PrintLoa
      default:
        return null
    }
  }

  const getTemplate = async (id, functionGetTemplate, insurerItemId = '', treatmentPlanId = '') => {
    try {
      const { data } = insurerItemId
        ? await functionGetTemplate(id, insurerItemId)
        : await functionGetTemplate(id, treatmentPlanId)
      setData(data)
      setLoading(false)
      if (!data) throw new Error('Cannot found')
    } catch (err) {
      setLoading(false)
      setError(error)
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center" style={{ height: window.innerHeight }}>
        <LoadingOutlined style={{ fontSize: '75px', color: 'var(--blue-400)' }} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="container text-lg">
        <h1>Something Error</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  if (!data) return null

  return <div className="flex justify-center items-center mt-12" dangerouslySetInnerHTML={{ __html: data }}></div>
}

export default observer(Template)
