import { ApiRequest, CreateParams, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/clinical-records'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function ConfirmationLetter(recordId) {
  queryClient.invalidateQueries([BaseRoute, recordId])
  return ApiRequest.get(`${BaseRoute}/${recordId}/ConfirmationLetter`)
}

function LOATemplate(recordId) {
  queryClient.invalidateQueries([BaseRoute, recordId])
  return ApiRequest.get(`${BaseRoute}/${recordId}/LOATemplate`)
}
function MedicalRequest(recordId) {
  queryClient.invalidateQueries([BaseRoute, recordId])
  return ApiRequest.get(`${BaseRoute}/${recordId}/MedicalRequest`)
}

function Rebook(recordId, isSub) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.post(`${BaseRoute}/${recordId}/duplicateRecord?isSubCR=${isSub}`)
}

function SendReportStatus(recordId, payload = {}) {
  let qs = CreateParams(payload)
  return ApiRequest.post(`${BaseRoute}/${recordId}/sendReportStatus?${qs.join('&')}`)
}

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

const SendEmail = (id, data) => {
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${data}`)
}
const MergeFiles = (id) => {
  return ApiRequest.post(`${BaseRoute}/${id}/MergeFiles`)
}
const API = {
  ...baseFunctions,
  Rebook,
  SendReportStatus,
  ConfirmationLetter,
  LOATemplate,
  MedicalRequest,
  ExportSearchResult,
  SendEmail,
  MergeFiles,
}

export default API
