import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/system-update'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function ReSendEmail(systemUpdateId) {
  return ApiRequest.get(`${BaseRoute}/sendEmail/${systemUpdateId}`)
}

function History(params) {
  return ApiRequest.get(`${BaseRoute}/history`, { params })
}

const API = {
  ...baseFunctions,
  ReSendEmail,
  History,
}

export default API
