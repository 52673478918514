import MLPLocalization from './locales/MLP.json'
import LCLocalization from './locales/LC.json'

const resources = {
  MLP: MLPLocalization,
  LC: LCLocalization,
}

const settings = {
  language: 'MLP',
  fallbackLang: 'MLP',
  resources: resources,
}

export const changeLanguage = lang => {
  settings.language = lang
}

export const translate = key => {
  if (settings.resources[settings.language][key]) {
    return settings.resources[settings.language][key]
  }

  if (settings.resources[settings.fallbackLang][key]) {
    return settings.resources[settings.fallbackLang][key]
  }

  return key
}

export const initTranslator = options => {
  Object.assign(settings, options)
}
