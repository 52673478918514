import { DashboardRouteService } from 'routes-setting/dashboardRoute'
import './ContentItem.scss'
import { useMemo } from 'react'

const ContentItem = ({ path, tabId, route }) => {
  const routeSetting = useMemo(() => {
    return DashboardRouteService.getRouteByPathname(route)
  }, [route])
  return <routeSetting.Component tabId={tabId} path={path} {...routeSetting.props} />
}

export default ContentItem
