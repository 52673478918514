import { observer } from 'mobx-react'
import ModalRecentChange, { columnsWithReferenceTable } from '@components/ModalRecentChange'
import { ModalManager } from './store'

const PageModal = () => {
  const { recentChange } = ModalManager
  return (
    <div>
      {ModalManager.recentChange.open && (
        <ModalRecentChange
          open={true}
          columns={recentChange.columns}
          entityId={recentChange.entityId}
          optionsValue={recentChange.optionsValue}
          propertyName={recentChange.propertyName}
          propertyReferenceTable={recentChange.propertyReferenceTable}
          onCancel={() => ModalManager.closeRecentChange()}
          modalWidth={recentChange.modalWidth}
        />
      )}
    </div>
  )
}

export default observer(PageModal)
