import dayjs from 'dayjs'
import _ from 'lodash'

const stores = []

/**
 * @template T
 * @param {function(new: T)} type
 * @param {string} storeKey
 * @returns {T}
 */
export const getStore = <T, U>(type: new (dataInitiated?: U) => T, storeKey: string, dataInitiated?: U): T => {
  if (!(type as any).storeName) throw new Error('Missing storeName in store class')
  const storeName = (type as any).storeName
  let key = `${storeName}-${storeKey}`
  let store = stores[key]
  if (!store) {
    store = new type(dataInitiated)
    stores[key] = store
  }

  return store
}

export const checkDataChange = (originData, data) => {
  const obj1 = _.cloneDeep(originData)
  const obj2 = _.cloneDeep(data)
  return !_.isEqual(obj1, obj2)
}
export const getDueDayinMonthOption = (): any[] => {
  let result = []
  for (let i = 1; i <= 25; i++) {
    result.push({ id: i, displayName: `${i}${nthNumber(i)}` })
  }
  return result
}

export const nthNumber = number => {
  if (number > 3 && number < 21) return 'th'
  switch (number % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const getSpecialistProviderNo = (specialist, isCaseManager = false) => {
  if (!specialist) {
    return []
  }
  return [
    specialist.providerNumber
      ? {
          id: 1,
          displayName: `${specialist.providerNumber} ${
            !isCaseManager ? (specialist.providerNumberNote ? '- ' + specialist.providerNumberNote : '') : ''
          }`,
        }
      : null,
    specialist.providerNumber2
      ? {
          id: 2,
          displayName: `${specialist.providerNumber2} - ${
            !isCaseManager ? (specialist.providerNumber2Note ? '- ' + specialist.providerNumber2Note : '') : ''
          }`, 
        }
      : null,
    specialist.providerNumber3
      ? {
          id: 3,
          displayName: `${specialist.providerNumber3} - ${
            !isCaseManager ? (specialist.providerNumber3Note ? '- ' + specialist.providerNumber3Note : '') : ''
          }`,
        }
      : null,
    specialist.providerNumber4
      ? {
          id: 4,
          displayName: `${specialist.providerNumber4} - ${
            !isCaseManager ? (specialist.providerNumber4Note ? '- ' + specialist.providerNumber4Note : '') : ''
          }`,
        }
      : null,
  ].filter(val => val)
}

