import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/uhg'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Connect() {
  return ApiRequest.get(`${BaseRoute}/connect`)
}

const API = {
  ...baseFunctions,
  Connect,
}

export default API
