import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { AppButton } from '@components/AppComponents'
import { TableFilterContext } from '@components/table/TableContext'
import { checkIsJSON } from '@utils/functions'
import React, { useContext } from 'react'

interface FilterButtonProps {
  filter: any
  onPress?: Function
}
const UN_COUNT = ['skipCount', 'maxResultCount', 'serviceType', 'filterText', 'showBookingToday']

const FilterButton = ({ filter = {}, onPress }: FilterButtonProps) => {
  const defaultFilter = React.useMemo(() => filter, [])

  const filterContext = useContext(TableFilterContext)

  const count = React.useMemo(() => {
    let count = 0
    Object.keys(filter).map(key => {
      let defaultValue = checkIsJSON(defaultFilter[key]) ? JSON.parse(defaultFilter[key]) : defaultFilter[key]
      let filterValue = checkIsJSON(filter[key]) ? JSON.parse(filter[key]) : filter[key]

      // validate other filter values
      if (Array.isArray(defaultValue)) {
        defaultValue = JSON.stringify(defaultValue)
      }

      if (Array.isArray(filterValue)) {
        filterValue = JSON.stringify(filterValue)
      }

      if (filterValue !== defaultValue && !UN_COUNT.includes(key)) {
        count++
      } else {
        if (filterValue === defaultValue && key === 'isActive') {
          count++
        }
      }
    })
    return count
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter, filter])

  const handleClickFilter = () => {
    const isShow = !filterContext.isShowFilterSection
    filterContext.setShowFilterSection(isShow)
    if (onPress) onPress(isShow)
  }

  return (
    <AppButton onClick={handleClickFilter} className="flex items-center justify-center">
      <span>Filters {`${count ? `(${count})` : ''}`}</span>
      <span className="icon mx-1">
        {filterContext.isShowFilterSection ? (
          <UpOutlined className="inline" width="14" height="14" />
        ) : (
          <DownOutlined className="inline" width="14" height="14" />
        )}
      </span>
    </AppButton>
  )
}

export default FilterButton
