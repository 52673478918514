import { translate } from 'translator'

export const FIELD_LABEL = {
  authorizationNo: 'Authorization No',
  fundingProviderId: 'Lender',
  fundingAgreementId: 'Facility Agreement No',
  amount: 'Amount',
  requestDate: 'Loan Date',
  authorizationDate: 'Authorization Date',
  maturityDate: 'Maturity Date',
  parishFiled: 'Parish Filed',
  clientType: `${translate('Client')} Type`,
  firstAdvance: '1st Advance',
  caseManagerId: 'CaseManager',
}
export const ERROR_MESSAGES = {
  required: 'This field is required',
}
export const INITIAL_ID = '00000000-0000-0000-0000-000000000000' // DEFAULT ID WHEN CREATE

export const ReviewStatus = {
  PENDING: 'Pending',
  UNDER_REVIEW: 'Under review',
  PASSED: 'Passed',
  FAILED: 'Failed',
  NONE: 'N/A',
}

export const ReviewStatusConst = {
  PENDING: 'PENDING',
  UNDER_REVIEW: 'UNDER_REVIEW',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  NONE: 'NONE',
}
