import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/notifications/email-trackings'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function getEmailTrackingByModule(module, objectId) {
  const url = `${BaseRoute}/module?fromModule=${module}&objectId=${objectId}`
  queryClient.invalidateQueries([url])
  return ApiRequest.get(url)
}

function SyncOutlookContent(trackingId) {
  const url = `${BaseRoute}/${trackingId}/sync-outlook`
  queryClient.invalidateQueries([url])
  return ApiRequest.post(url)
}

function Resend(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/resend`)
}

function MarkAsSuccess(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/markAsSuccess`)
}

const API = {
  ...baseFunctions,
  getEmailTrackingByModule,
  SyncOutlookContent,
  Resend,
  MarkAsSuccess,
}

export default API
