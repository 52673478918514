import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/languages'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const API = {
  ...baseFunctions,
  GetAll,
  AskChatGPT,
  GetLanguageLookupConfig,
}

function AskChatGPT(prompt) {
  return ApiRequest.post(`${BaseRoute}/askChatGPT?prompt=${prompt}`)
}

function GetAll(params = {}) {
  return ApiRequest.get(`${BaseRoute}`, { params })
}

function GetLanguageLookupConfig(params = {}) {
  return ApiRequest.get(`${BaseRoute}/lookupConfig`, { params })
}

export default API
