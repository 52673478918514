import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/serviceProviders'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Enable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

function CheckDuplicate(params) {
  return ApiRequest.get(`${BaseRoute}/isDupplicateName`, { params })
}

function GetSimilarItemsByName(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByName`, { params })
}

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

const API = {
  ...baseFunctions,
  Enable,
  Disable,
  getSimilarItems: CheckDuplicate,
  GetSimilarItemsByName,
  ExportSearchResult,
}

export default API
