import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import AppModal from '.'
import TooltipInfo from '@components/TooltipInfo'
type Props = {
  onCancel?: () => void
  modalParams: any
  hideWarningIcon?: boolean
  avoidCancelOnSubmit?: boolean
  btnYesText?: string
  btnNoText?: string
  btnCustomText?: string
}
const ModalConfirm = ({
  onCancel = () => {},
  modalParams,
  hideWarningIcon = false,
  avoidCancelOnSubmit = false,
  btnYesText = 'Yes',
  btnNoText = 'No, return',
  btnCustomText = 'Custom',
}: Props) => {
  const {
    message,
    onOk,
    functionCallbackCancel,
    footerCloseButton,
    modelWidth = 600,
    title = '',
    isShowWarningIcon = true,
    customButton,
    bodyStyle = {},
    functionCallbackCustomButton,
    btnYesClass = 'button-yes',
    btnNoClass = 'button-no',
    btnCustomClass = 'button-custom',
    btnYesTooltip = '',
    btnCustomTooltip = '',
    isUseDropDownButton = false,
  } = modalParams

  const handleOnOkClick = () => {
    !avoidCancelOnSubmit && onCancel()
    onOk && onOk()
  }

  const handleCancel = () => {
    functionCallbackCancel && functionCallbackCancel()
    onCancel()
  }

  const handleCustomFunction = () => {
    functionCallbackCustomButton && functionCallbackCustomButton()
    onCancel()
  }

  return (
    <AppModal
      title={title}
      open={true}
      width={modelWidth}
      bodyStyle={bodyStyle}
      className="custom-popup-confirm"
      footer={
        <>
          {customButton && customButton}
          {footerCloseButton || !onOk ? (
            <Button onClick={handleCancel}>Close</Button>
          ) : (
            <>
              {isUseDropDownButton ? (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'btnYes',
                        label: modalParams?.btnYesText ?? btnYesText,
                        onClick: handleOnOkClick,
                      },
                      {
                        key: 'btnCustom',
                        label: modalParams?.btnCustomText ?? btnCustomText,
                        onClick: handleCustomFunction,
                      },
                    ],
                  }}
                >
                  <Button className={btnYesClass} type="primary" color="blue">
                    Save
                    <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button className={btnYesClass} type="primary" onClick={handleOnOkClick}>
                  {modalParams?.btnYesText ?? btnYesText}
                  {modalParams?.btnYesTooltip && <TooltipInfo text={btnYesTooltip} />}
                </Button>
              )}

              <Button className={btnNoClass} onClick={handleCancel}>
                {modalParams?.btnNoText ?? btnNoText}
              </Button>
            </>
          )}
        </>
      }
    >
      <div className="custom-content-popup-confirm flex items-center flex-col">
        {!hideWarningIcon && isShowWarningIcon && (
          <ExclamationCircleOutlined style={{ color: 'orange', fontSize: 55 }} />
        )}
        <div className="modal-confirm-message mt-5">{message}</div>
      </div>
    </AppModal>
  )
}

export default ModalConfirm
