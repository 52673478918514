import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/ringCentral'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Connect() {
  return ApiRequest.get(`${BaseRoute}/authorize`)
}

function CallResult(payload) {
  return ApiRequest.post(`${BaseRoute}/callresult`, payload)
}

const API = {
  ...baseFunctions,
  Connect,
  CallResult,
}

export default API
