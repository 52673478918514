import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/video-meetings'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetSession(sessionId) {
  return ApiRequest.get(`${BaseRoute}/sessions/${sessionId}`)
}

function GetVideoMeetingDetail(videoId) {
  return ApiRequest.get(`${BaseRoute}/${videoId}`)
}

function CloseMeeting(meetingId, token) {
  return ApiRequest.post(`${BaseRoute}/closeMeeting?token=${token}&&meetingId=${meetingId}`)
}

const API = {
  ...baseFunctions,
  GetSession,
  GetVideoMeetingDetail,
  CloseMeeting,
}

export default API
