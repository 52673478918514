import { observer } from 'mobx-react'
import router from '@stores/router'
import { EmptyRoutes } from 'routes-setting/emptyRoute'
import AuthWrapper from '@pages/auth/AuthWrapper'
import dashboardStore from '@stores/dashboard'
import { getRouteByPathname } from 'routes-setting/functions'

const EmptyLayout = () => {
  const validateRoute = () => {
    const routeConfig = getRouteByPathname(router.location.pathname, EmptyRoutes)

    if (!routeConfig) {
      router.push('/')
      return
    }

    if (dashboardStore.isValidRoute(routeConfig) === false) {
      window.location.href = '/'
    }
  }

  const renderContent = () => {
    const ViewComponent = getRouteByPathname(router.location.pathname, EmptyRoutes)?.Component
    return <ViewComponent />
  }

  return <AuthWrapper onReady={validateRoute}>{renderContent()}</AuthWrapper>
}

export default observer(EmptyLayout)
