import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/xero'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Connect() {
  return ApiRequest.get(`${BaseRoute}/connect`)
}

function UploadTenantInvoice(invoiceId) {
  return ApiRequest.post(`${BaseRoute}/tenantXeroUpload?invoiceId=` + invoiceId)
}

function GetAccountCodes({ filterText = '', accountCodeType = null, isActive = null }) {
  return ApiRequest.get(`${BaseRoute}/accountCodes`, { params: { filterText, accountCodeType, isActive } })
}

function CreateAccountCode(param) {
  return ApiRequest.post(`${BaseRoute}/accountCodes?`, param)
}

function UpdateAccountCode(id: string, data: any) {
  return ApiRequest.put(`${BaseRoute}/${id}/accountCodes`, data)
}

const API = {
  ...baseFunctions,
  Connect,
  UploadTenantInvoice,
  GetAccountCodes,
  CreateAccountCode,
  UpdateAccountCode,
}

export default API
