import { useEffect, useState } from 'react'

const PdfViewer = ({ rawData }) => {
  const [fileUrl, setFileUrl] = useState(null)

  useEffect(() => {
    // Convert raw data to a Blob
    const blob = new Blob([rawData], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    setFileUrl(url)

    // Cleanup URL object
    return () => URL.revokeObjectURL(url)
  }, [rawData])

  if (!fileUrl) {
    return <div>Loading...</div>
  }
  return <iframe src={fileUrl} width="100%" height="100%" style={{ height: '100vh'}} title="PDF Viewer"></iframe>
}

export default PdfViewer
