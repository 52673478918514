import { FROM_MODULE_TYPE } from '@utils/constants'
import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/disbursements/funding-transactions'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function CheckCaseNo(caseNo, fromModule) {
  if (!caseNo) return null
  caseNo = caseNo?.trim()
  // let fromModule = ''
  // if (caseNo.includes(FROM_MODULE_TYPE.FR) || caseNo.includes('SR')) {
  //   fromModule = FROM_MODULE_TYPE.SUPP
  // } else if (caseNo.includes(FROM_MODULE_TYPE.OT) || caseNo.includes(FROM_MODULE_TYPE.IME) || caseNo.includes('IM')) {
  //   fromModule = FROM_MODULE_TYPE.IME
  // } else {
  //   fromModule = caseNo.slice(0, caseNo?.indexOf('-'))
  // }
  return ApiRequest.get(`${BaseRoute}/checkCaseNo`, { params: { caseNo, fromModule: fromModule.toUpperCase() } })
}
const API = {
  ...baseFunctions,
  ExportSearchResult,
  CheckCaseNo,
}

export default API
