export const ImpersonateService = {
  handleImpersonate: () => {
    const queryParams = new URLSearchParams(window.location.search)
    const hasImpersonate = queryParams.has('impersonatorUserId')
    if (hasImpersonate) {
      // Clear all current logged user
      localStorage.clear()
      sessionStorage.clear()
    }
  },
}
