import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import store from './store'
import { downloadFileFromResponse, getQueryParams } from '@utils/functions'
import router from '@stores/router'

import { LoadingOutlined } from '@ant-design/icons'
import { ApiService } from '@services/ApiService'

const InvoicePrint = () => {
  const { id, invoiceNo } = getQueryParams(router.location.search, router.location.state)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    document.title = invoiceNo ? invoiceNo : 'Invoice View'

    if (id) {
      setLoading(true)
      async function fetchData() {
        try {
          const data = await store.fetchData(id)
          setData(data)

          if (!data) throw new Error('Cannot found')
        } catch (error) {
          setError(error)
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    } else {
      setError(new Error('Missing parameter id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadInvoice = () => {
    ApiService.Payment.PrintInvoice(id, { type: 'pdf' }).then(res => {
      downloadFileFromResponse(res, 'Invoice.pdf')
    })
  }
  if (loading) {
    return (
      <div className="container text-center">
        <LoadingOutlined style={{ fontSize: '50px', color: 'var(--blue-400)' }} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="container text-lg">
        <h1>Something Error</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  if (!data) return null

  return (
    <div>
      {/* <AppButton className="fixed right-10 top-10" color="green" onClick={downloadInvoice}>
        Download
      </AppButton> */}
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  )
}

export default observer(InvoicePrint)
