import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/users'

const baseFunctions = { ...GetBaseServiceFunctions(BaseRoute) }

function Enable(userId) {
  queryClient.invalidateQueries([BaseRoute, userId])
  return ApiRequest.put(`${BaseRoute}/${userId}/enable`)
}

function Disable(userId) {
  queryClient.invalidateQueries([BaseRoute, userId])
  return ApiRequest.put(`${BaseRoute}/${userId}/disable`)
}

function Lock(userId) {
  queryClient.invalidateQueries([BaseRoute, userId])
  return ApiRequest.put(`${BaseRoute}/${userId}/lock`)
}

function Unlock(userId) {
  queryClient.invalidateQueries([BaseRoute, userId])
  return ApiRequest.put(`${BaseRoute}/${userId}/unlock`)
}

function DeleteUser(userId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(`${BaseRoute}/${userId}`)
}

function UpdateUser(userId, data) {
  queryClient.invalidateQueries([BaseRoute, userId])
  return ApiRequest.put(`${BaseRoute}/${userId}`, data)
}

function getUser(userId) {
  return ApiRequest.get(`${BaseRoute}/${userId}`)
}

function CheckDuplicate(params) {
  return ApiRequest.post(`${BaseRoute}/checkDuplicateEmail`, params)
}

function ResetPassword(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/resendPassword`)
}

function SetPassword(id, data) {
  return ApiRequest.put(`${BaseRoute}/${id}/setPassword`, data)
}

function ResendPassword(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/resendPassword`)
}

function CreateForTenant(tenantId, params) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.post(`${BaseRoute}/createForTenant?tenantId=${tenantId}`, params)
}

function LoginHistory(params) {
  return ApiRequest.get(`${BaseRoute}/loginHistory`, {
    params,
  })
}
function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function GetStaffs({
  filterText = '',
  maxResultCount = 20,
  isActive = true,
  isSelectionList = true,
  sorting = 'Name',
  isGetBSOFromCM = false,
} = {}) {
  return baseFunctions.GetAll({
    filterText,
    maxResultCount,
    isActive,
    isSelectionList,
    roleNames: ['ADMIN_STAFF', 'STAFF'],
    sorting,
    isGetBSOFromCM,
  })
}

const API = {
  ...baseFunctions,
  Enable,
  Disable,
  Lock,
  Unlock,
  DeleteUser,
  UpdateUser,
  getUser,
  CheckDuplicate,
  ResetPassword,
  ResendPassword,
  CreateForTenant,
  GetStaffs,
  LoginHistory,
  SetPassword,
  ExportSearchResult,
}

export default API
