import { RouteConfig } from './interface'

export const isMatchPathname = (pathname, routes: { path: string }[]) => {
  return routes.some(route => {
    return matchPathnameWithRoute(pathname, route.path)
  })
}

export function matchPathnameWithRoute(pathname, routePath) {
  // Convert the route path with :id to a regular expression
  const regex = new RegExp(
    '^' +
      routePath
        .replace(/:[^\s/]+/g, '([^/]+)')
        .replace(/\//g, '\\/')
        .replace(/\?/g, '\\?') +
      '$',
  )

  // Test if the pathname matches the regular expression
  const isMatch = regex.test(pathname)

  return isMatch
}

export const getRouteByPathname = (pathname: string, routes: RouteConfig[]) => {
  return routes.find(route => {
    return matchPathnameWithRoute(pathname, route.path)
  })
}

export function getPathParams(pathname, routePath): { [key: string]: string } | null {
  // Convert the route path with :id to a regular expression
  const regex = new RegExp(
    '^' +
      routePath
        .replace(/:[^\s/]+/g, '([^/]+)')
        .replace(/\//g, '\\/')
        .replace(/\?/g, '\\?') +
      '$',
  )

  // Test if the pathname matches the regular expression
  const match = pathname.match(regex)

  // If there is a match, extract the parameter values
  if (match) {
    const params = routePath.match(/:[^\s/]+/g) || []
    const paramValues = match.slice(1)
    const paramMap = {}
    params.forEach((param, index) => {
      paramMap[param.slice(1)] = paramValues[index]
    })
    return paramMap
  }

  // If there is no match, return null
  return {}
}

export const generateUrlFromPath = (params: { [key: string]: string }, path: string) => {
  if (Object.keys(params).length === 0) {
    return path
  }
  let parsedPath = path
  const pathVariables = path.match(/:[^/]+/g)
  if (pathVariables) {
    pathVariables.forEach(variable => {
      const paramName = variable.substring(1) // remove ':'
      const paramValue = params[paramName]
      parsedPath = parsedPath.replace(variable, paramValue)
    })
  }

  return parsedPath
}
