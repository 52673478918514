import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/system-invoice'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetSystemInvoice(params = {}) {
  return ApiRequest.get(`${BaseRoute}`, { params })
}

function PreviewInvoice(invoiceId) {
  return ApiRequest.get(`${BaseRoute}/${invoiceId}/preview`)
}

function DownloadInvoice(invoiceId) {
  return ApiRequest.get(`${BaseRoute}/${invoiceId}/preview?type=pdf`, { responseType: 'arraybuffer' })
}

function SendInvoice(invoiceId) {
  return ApiRequest.post(`${BaseRoute}/${invoiceId}/sendInvoice`)
}

function MarkAsPaid(invoiceId) {
  return ApiRequest.post(`${BaseRoute}/${invoiceId}/markAsPaid`)
}

function CancelInvoice(invoiceId) {
  return ApiRequest.post(`${BaseRoute}/${invoiceId}/cancelInvoice`)
}

function CalculateSystemInvoice(data) {
  return ApiRequest.post(`${BaseRoute}/calculate`, data)
}

const API = {
  ...baseFunctions,
  GetSystemInvoice,
  PreviewInvoice,
  DownloadInvoice,
  SendInvoice,
  CancelInvoice,
  MarkAsPaid,
  CalculateSystemInvoice,
}

export default API
