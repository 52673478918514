import * as Icon from '@ant-design/icons'
import Icon8 from '@components/shared/Icon8'

import { ReactComponent as ChatGpt } from "./icons/chatgpt.svg";
import { ReactComponent as Calendar } from "./icons/calendar.svg";
import { ReactComponent as Accounting } from "./icons/accounting.svg";
import { ReactComponent as FacilityDisbursement } from "./icons/facility-disbursement.svg";
import { ReactComponent as Subscription } from "./icons/subscription.svg";
import { ReactComponent as Resources } from "./icons/resources.svg";
import { ReactComponent as CaseManagement } from "./icons/document-case.svg";
import { ReactComponent as DarkMode } from "./icons/dark-mode.svg";
import { ReactComponent as LightMode } from "./icons/light-mode.svg";

const Icons = {
  ChatGpt,
  Calendar,
  CaseManagement,
  Accounting,
  FacilityDisbursement,
  Subscription,
  Resources,
  LightMode,
  DarkMode,
  ...Icon
};


const AppIcon = ({ name, materialIcon = false, className = '', title = null }) => {

  const AntdIcon = Icons[name]

  if (!AntdIcon) return <Icon8 name={name} className={className} title={title} materialIcon={materialIcon} />

  return <AntdIcon className={className} />
}

export default AppIcon
