import { AppDateRangePicker, AppSelectInput } from '@components/AppComponents'
import { AppTable } from '@components/table'
import appStore from '@stores/appStore'
import { observer } from 'mobx-react'
import { useState } from 'react'
import store from './store'
const TableFilter = () => {
  const { loading, filter, tenantsData } = store
  const [showFilter, changeShowFilter] = useState(false)

  const handleSubmit = () => {
    store.refetch({ skipCount: 0 })
  }

  const handleReset = () => {
    store.resetStore()
  }

  const handleRefresh = () => {
    store.refetch()
  }

  return (
    <AppTable.FilterContainer>
      <AppTable.FilterHeader>
        {appStore.isHostTenant && (
          <AppSelectInput
            disabled={loading}
            prefixLabel={'Tenants: '}
            value={filter.tenantId}
            options={tenantsData}
            onChange={value => {
              store.updateFilter({ tenantId: value, skipCount: 0 })
              store.fetchData()
            }}
            rounded
            textKey="name"
            className="w-[300px] mb-0"
            formItemClassName="mb-0"
          />
        )}

        <>
          Select Months:
          <AppDateRangePicker
            value={[filter.fromMonth, filter.toMonth]}
            format={'MM/YYYY'}
            picker="month"
            onChange={value =>
              store.updateFilter({
                fromMonth: value ? value[0] : null,
                toMonth: value ? value[1] : null,
              })
            }
            placeholder={['From Month', 'To Month']}
            disabled={loading}
            className="rounded-full"
          />
        </>
        <AppTable.DefaultButton
          handleSubmit={handleSubmit}
          handleRefresh={handleRefresh}
          handleReset={handleReset}
          loading={loading}
        />
      </AppTable.FilterHeader>
    </AppTable.FilterContainer>
  )
}

export default observer(TableFilter)
