import { InfoCircleOutlined } from '@ant-design/icons'
import { Flex } from '@components/Layout'
import { Tooltip } from 'antd'
import cx from 'classnames'
import { useMemo, useRef } from 'react'

type Props = {
  text?: any
  children?: any
  props?: object
  overlayStyle?: object
  iconColor?: string
  onOpenChange?: (isVisible: boolean) => void
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
  tooltipIcon?: React.ReactNode
  childrenClassName?: string
  ignoreDefaultChildrenClass?: boolean
}

const TooltipInfo = ({
  text,
  children = null,
  props = {},
  iconColor = 'text-gray-400',
  onOpenChange,
  placement = 'top',
  tooltipIcon,
  childrenClassName,
  ignoreDefaultChildrenClass = false,
}: Props) => {
  const handleOnClick = e => {
    const tooltip = document.getElementsByClassName('ant-tooltip')
    if (!!tooltip && !!tooltip[0]) {
      const hiddenClass = 'ant-tooltip-hidden'
      if (tooltip[0].className.indexOf(hiddenClass) < 0) {
        tooltip[0].className = 'hidden'
      }
    }
  }
  const className = useMemo(() => {
    if (ignoreDefaultChildrenClass) {
      return childrenClassName
    }
    return ['shrink-0', childrenClassName]
  }, [childrenClassName])
  return (
    <Flex>
      {children && <span className={cx(className)}>{children}</span>}
      {text && (
        <Tooltip
          zIndex={9000}
          color="white"
          destroyTooltipOnHide
          placement={placement}
          overlayInnerStyle={{
            borderRadius: '10px',
            color: 'black',
            padding: '1rem',
            maxHeight: '40vh',
            overflow: 'auto',
          }}
          overlayStyle={{ maxWidth: '450px' }}
          title={
            <p onClick={handleOnClick} style={{ userSelect: 'text' }}>
              {text}
            </p>
          }
          onOpenChange={onOpenChange}
          {...props}
        >
          {tooltipIcon ? (
            tooltipIcon
          ) : (
            <InfoCircleOutlined
              style={{ alignSelf: 'center' }}
              className={cx(['cursor-pointer font-bold', iconColor])}
            />
          )}
        </Tooltip>
      )}
    </Flex>
  )
}

export default TooltipInfo
