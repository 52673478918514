// Docs: https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-8.0&tabs=visual-studio-code
import * as signalR from '@microsoft/signalr'
import { API_ACCESS_TOKEN } from '@services/baseService'
import { FROM_MODULE_TYPE } from '@utils/constants'
import { ActivityType, UserActivity } from './types'
import _ from 'lodash'
import dashboardStore from '@stores/dashboard'
import { showNotification } from '@utils/errors'
import { makeAutoObservable } from 'mobx'
import appStore from '@stores/appStore'

class RealtimeNotificationStore {
  constructor() {
    makeAutoObservable(this)
  }

  connection: signalR.HubConnection
  activityLogs: UserActivity[] = []

  private registerEventListeners() {
    this.onReceiveActivityLog()
    this.onActivityLogsResult()
  }
  updateActivityLogs(activityLogs: UserActivity[]) {
    this.activityLogs = activityLogs
  }

  getUserActivities(fromModule: FROM_MODULE_TYPE, objectId: string, activity: ActivityType) {
    const result = this.activityLogs.filter(
      item =>
        item.fromModule === fromModule &&
        item.objectId === objectId &&
        item.activityType === activity &&
        item.userId !== appStore.currentUser?.id,
    )

    return result
  }

  async setupRealtimeNotification() {
    if (this.connection) {
      return
    }

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.VITE_API_URL + '/signalr-hubs/notification', { accessTokenFactory: () => API_ACCESS_TOKEN })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    const start = async () => {
      try {
        await this.connection.start()
        AppConsole.log('SignalR Connected.')
      } catch (err) {
        AppConsole.log(err)
        setTimeout(start, 5000)
      }
    }

    this.connection.onclose(async () => {
      await start()
    })

    // Start the connection.
    await start()

    this.registerEventListeners()
  }

  onNotification = callback => {
    if (this.connection) {
      this.connection.on('ReceiveMessage', callback)
    }
  }

  sendRequest = (functionName, data) => {
    if (this.connection) {
      return this.connection.invoke(functionName, JSON.stringify(data))
    }
  }

  logActivity = (fromModule: FROM_MODULE_TYPE, objectId: string, activityType: ActivityType) => {
    this.sendRequest('AddActivityLog', {
      FromModule: fromModule,
      ObjectId: objectId,
      ActivityType: activityType,
    })
  }

  onReceiveActivityLog = () => {
    this.connection.on('ReceiveActivityLog', activity => {
      const isSameObjectId =
        dashboardStore.currentTab &&
        dashboardStore.currentTab?.objectId == activity.objectId &&
        dashboardStore.currentTab?.fromModule == activity.fromModule

      if (isSameObjectId) {
        if (activity.activityType == 'ITEM_VIEWING') {
          showNotification('User Editing Item', `${activity.user?.name} is viewing this record`, 'info')
        }
        if (activity.activityType == 'ITEM_EDITING') {
          showNotification('User Editing Item', `${activity.user?.name} is editing this record`, 'info')
        }
      }

      this.getActivityLogs(activity.fromModule, activity.objectId, activity.activityType)
    })
  }

  getActivityLogs = (fromModule: FROM_MODULE_TYPE, objectId: string, activityType: ActivityType) => {
    return this.sendRequest('GetActivityLogs', {
      FromModule: fromModule,
      ObjectId: objectId,
      ActivityType: activityType,
    })
  }

  onActivityLogsResult = () => {
    if (this.connection) {
      this.connection.on('ActivityLogsResult', activityLogs => {
        // console.log('Received activity logs: ' + activityLogs.length)
        this.activityLogs = activityLogs
      })
    }
  }
}

export default new RealtimeNotificationStore()
