import appStore from '@stores/appStore'
import { DatePicker } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useMemo } from 'react'

/**
 * AppDatePicker component.
 *
 * @component
 * @param {string} format - The format of the date displayed in the input field. Defaults to null.
 * @param {string} size - The size of the DatePicker. Defaults to 'middle'.
 * @param {string} value - The value of the DatePicker. Defaults to null.
 * @param {boolean} disabled - Whether the DatePicker is disabled. Defaults to false.
 * @param {boolean} allowClear - Whether to show the clear button. Defaults to true.
 * @param {boolean} fullwidth - Whether the DatePicker should take up the full width. Defaults to false.
 * @param {Function} disabledDate - A function that determines if a date should be disabled. Defaults to null.
 * @param {string} className - Additional CSS class names for the DatePicker. Defaults to an empty string.
 * @param {boolean} enableTime - Whether to enable time selection. Defaults to false.
 * @param {string} timezone - The timezone to use for date calculations. Defaults to the system's timezone.
 * @param {Function} onChange - A callback function that is called when the date value changes. Defaults to an empty function.
 * @param {string} placeholder - The placeholder text to display in the input field. Defaults to 'dd Mmm yyyy'.
 * @returns {JSX.Element} The rendered AppDatePicker component.
 */
export function AppDatePicker({
  format = 'DD MMM YYYY',
  size = 'middle',
  value = null,
  disabled = false,
  allowClear = true,
  fullwidth = false,
  disabledDate = null,
  className = '',
  enableTime = false,
  timezone = dayjs.tz.guess(),
  onChange = (dateString?: string) => {},
  placeholder = 'DD MMM YYYY',
  ...props
}) {
  const handleOnChange = date => {
    if (enableTime) {
      onChange(date?.toISOString())
    } else {
      onChange(date?.format('YYYY-MM-DD'))
    }
  }
  const dateFormats = useMemo(() => {
    return [format || 'DD MMM YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', appStore.currentUser.dateFormat].filter(i => i)
  }, [format, appStore.currentUser])

  const inputValue = useMemo(() => {
    if (value && typeof value === 'string') {
      const isDateOnly = value.length === 10
      const isIgnoreTimeZone = !enableTime && (isDateOnly || (!isDateOnly && value.endsWith('T00:00:00Z')))

      if (isIgnoreTimeZone) {
        return dayjs.utc(value)
      } else {
        return appStore.getMomentDateTime(value, timezone)
      }
    }
    return value
  }, [enableTime, timezone, value])

  return (
    <DatePicker
      format={dateFormats}
      size={size as SizeType}
      value={inputValue}
      disabled={disabled}
      allowClear={allowClear}
      onChange={handleOnChange}
      className={cx([fullwidth && 'w-full', className])}
      disabledDate={date => {
        if (disabledDate) {
          return disabledDate(date)
        }
        return false
      }}
      placeholder={disabled ? '' : placeholder}
      {...props}
    />
  )
}
