import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/system-error'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function MarkAsResolved(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/markAsResolved`)
}

function MarkAsUnResolved(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/markAsUnresolved`)
}

const API = { ...baseFunctions, MarkAsResolved, MarkAsUnResolved }
export default API
