import { AppTable } from '@components/table'

const RecentChangeForm = ({ dataRecentChange = [], columns }) => {
  return (
    <AppTable
      tableKey="recent_change_list"
      hasPaginate={false}
      hasEmptyTitle={true}
      columns={columns}
      data={dataRecentChange}
      pageSize={100}
      totalCount={dataRecentChange.length}
      borderTable
    />
  )
}
export default RecentChangeForm
