import { ApiRequest, CreateParams, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/appointment-search-history'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)
const Export = params => {
  let qs = CreateParams(params)
  return ApiRequest.post(`${BaseRoute}/export`, params, { responseType: 'arraybuffer' })
}

const API = {
  ...baseFunctions,
  Export,
}

export default API
