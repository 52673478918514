import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/tenants'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function InitDefaultData(tenantId, data = {}) {
  return ApiRequest.post(`${BaseRoute}/${tenantId}/InitDefaultData`, data)
}

function SeedCompaniesData(tenantId) {
  return ApiRequest.post(`${BaseRoute}/${tenantId}/SeedCompanies`)
}
function SeedClinics() {
  return ApiRequest.post(`${BaseRoute}/SeedClinics?maxItem=10000`)
}

function SeedCities() {
  return ApiRequest.post(`${BaseRoute}/SeedCities`)
}

function GetRolesByTenantsId(tenantId) {
  return ApiRequest.get(`${BaseRoute}/${tenantId}/roles`)
}

function SetDefaultPermissions(tenantId, roleName) {
  return ApiRequest.put(`${BaseRoute}/${tenantId}/roles/${roleName}/setDefaultPermissions`)
}

function SetBillingSetting(tenantId, payload) {
  return ApiRequest.put(`${BaseRoute}/${tenantId}/billingSetting`, payload)
}

function GetBillingSettingList(params) {
  return ApiRequest.get(`${BaseRoute}/billingSetting`, { params: params })
}

function GetBillingSetting(id) {
  return ApiRequest.get(`${BaseRoute}/billingSetting/${id}`)
}

function GetFeatures(tenantId) {
  return ApiRequest.get(`${BaseRoute}/${tenantId}/features`)
}

function GetKawaconnSetting() {
  return ApiRequest.get(`${BaseRoute}/kawaconnSetting`)
}

function GenerateItemIndex(tenantId = null) {
  return ApiRequest.post(`${BaseRoute}/generateItemIndex`)
}

const API = {
  ...baseFunctions,
  InitDefaultData,
  SeedCompaniesData,
  GetRolesByTenantsId,
  SetDefaultPermissions,
  GetBillingSetting,
  GetBillingSettingList,
  SetBillingSetting,
  GetFeatures,
  SeedClinics,
  SeedCities,
  GetKawaconnSetting,
  GenerateItemIndex,
}

export default API
