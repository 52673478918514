import { queryClient } from '../baseService'
import { ApiRequest } from '../baseService'

const BaseRoute = 'app/actionstep-app'

const SendRequest = (method, url, data?) => {
  return ApiRequest.post(`${BaseRoute}/send-request`, {
    method: method,
    data: data,
    url: url,
  })
}

const GetMatterDetail = (id, tenantId, companyPMSId) => {
  return ApiRequest.get(`${BaseRoute}/matters/${id}?tenantId=${tenantId ?? ''}&companyPMSId=${companyPMSId ?? ''}`)
}

const GetContactDetail = id => {
  return SendRequest(
    'Get',
    `/contacts/${id}.json?fields=id,name,first_name,last_name,is_client,middle_name,type,primary_email_address`,
  )
}

const SyncClient = (matterId, companyPMSId = null) => {
  return ApiRequest.post(`${BaseRoute}/sync-client`, {
    matterId,
    companyPMSId,
  })
}

const SyncUser = (userId, companyPMSId) => {
  return ApiRequest.post(`${BaseRoute}/sync-user`, {
    userId,
    companyPMSId,
  })
}

const RegisterWebHooks = () => {
  return ApiRequest.post(`${BaseRoute}/register-webhooks`, {})
}

const GetRegisteredHooks = (params = {}) => {
  return ApiRequest.get(`${BaseRoute}/register-webhooks`, {})
}

const SyncMatter = matterId => {
  return ApiRequest.post(`${BaseRoute}/sync-matter?matterId=${matterId}`)
}

const GetListMatters = (method, url, caseNo?) => {
  return ApiRequest.post(`${BaseRoute}/matter`, {
    method: method,
    url: url,
    caseNo: caseNo,
  })
}

const GetListParticipant = (method, url, caseNo?) => {
  return ApiRequest.post(`${BaseRoute}/participant`, {
    method: method,
    url: url,
    caseNo: caseNo,
  })
}

const API = {
  SendRequest,
  GetMatterDetail,
  SyncClient,
  SyncUser,
  RegisterWebHooks,
  GetContactDetail,
  GetRegisteredHooks,
  GetListMatters,
  GetListParticipant,
  SyncMatter,
}
export default API
