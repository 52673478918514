import { observer } from 'mobx-react'
import React from 'react'
import router from '@stores/router'
import { AnonymousRoutes } from './routes'
import { getRouteByPathname } from 'routes-setting/functions'

class Anonymous extends React.Component<any> {
  renderTitle() {
    const routeConfig = getRouteByPathname(router.location.pathname, AnonymousRoutes)

    if (!routeConfig) {
      router.push('/')
      return
    }
    const config = this.props.Component ? this.props : routeConfig
    return (
      <React.Fragment>
        {config.title && <h1 className="text-4xl">{config.title}</h1>}
        {config['description'] && <p>{config['description']}</p>}
      </React.Fragment>
    )
  }
  renderContent() {
    const routeConfig = getRouteByPathname(router.location.pathname, AnonymousRoutes)
    if (!routeConfig) {
      router.push('/')
      return
    }

    const { Component } = this.props.Component ? this.props : routeConfig
    return <Component />
  }
  render() {
    const isVideo = router.location.pathname === '/video-meeting'
    if (isVideo) {
      return <div>{this.renderContent()}</div>
    }
    return (
      <div className="Anonymous">
        <div className="relative justify-evenly flex flex-col pb-[200px]">
          <div className="w-full flex-1 my-0 mx-auto px-4 pt-32 pb-8">
            {this.renderTitle()}
            {this.renderContent()}
          </div>
        </div>
      </div>
    )
  }
}

export default observer(Anonymous)
