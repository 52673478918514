import { observer } from 'mobx-react'
import cx from 'classnames'
import { useMemo } from 'react'

interface Props {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  disabled?: boolean
  color?: 'blue' | 'red' | 'black'
}

const ButtonLink = ({ children, onClick, disabled = false, color = 'blue', className }: Props) => {
  const colorClassName = useMemo(() => {
    if (color === 'red') {
      return 'text-red-400 hover:text-red-500'
    }
    if (color === 'blue') {
      return 'text-blue-400 hover:text-blue-500'
    }
    if (color === 'black') {
      return 'text-black hover:text-blue-500'
    }
    return ''
  }, [color])

  const handleClick = e => {
    e.preventDefault()
    if (!disabled && onClick) {
      onClick()
    }
  }

  if (disabled) {
    return <span className="font-medium">{children}</span>
  }

  return (
    <a onClick={handleClick} className={cx(colorClassName, 'font-medium', className)}>
      {children}
    </a>
  )
}
export default observer(ButtonLink)
