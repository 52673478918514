import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/fileTemplates'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function DownloadFileTemplates(fileName = '') {
  return ApiRequest.get(`${BaseRoute}?fileName=${fileName}`, {
    responseType: 'arraybuffer',
  })
}

const API = {
  ...baseFunctions,
  DownloadFileTemplates,
}

export default API
