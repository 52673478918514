import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'
import { TableFilterContext } from './TableContext'

const TableFilterContainer = ({
  children,
  defaultShowFilterSection = false,
  onToggleFilter = (value: boolean) => {},
}) => {
  const [showFilterSection, setShowFilterSection] = useState(defaultShowFilterSection)

  const handleChangeShowFilterSection = value => {
    setShowFilterSection(value)
    onToggleFilter && onToggleFilter(value)
  }
  const contextValue = useMemo(
    () => ({
      isShowFilterSection: showFilterSection,
      setShowFilterSection: handleChangeShowFilterSection,
    }),
    [showFilterSection, setShowFilterSection],
  )
  return <TableFilterContext.Provider value={contextValue}>{children}</TableFilterContext.Provider>
}
export default observer(TableFilterContainer)
