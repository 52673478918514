import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/disbursements/funding-requests'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function SendRequest(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.get(`${BaseRoute}/${id}/sent`)
}

const API = {
  ...baseFunctions,
  SendRequest,
}

export default API
