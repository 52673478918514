import { AppButton, AppForm, AppFormInput } from '@components/AppComponents'
import AppModal from '@components/AppModal'
import { ApiService } from '@services/ApiService'
import { validateForm } from '@services/formService'
import appStore from '@stores/appStore'
import dashboardStore from '@stores/dashboard'
import { EMAIL_DETAIL_TEMPLATE_TAB, ERROR_MESSAGES, FROM_MODULE_TYPE } from '@utils/constants'
import { showNotification } from '@utils/errors'
import { Badge, Divider, Spin, Tabs } from 'antd'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'

const SUPPORTED_TEMPLATES = {
  IME: [
    'TEMPLATE_118',
    'TEMPLATE_123',
    'TEMPLATE_5A',
    'TEMPLATE_39',
    'TEMPLATE_39_PHONE',
    'TEMPLATE_40',
    'TEMPLATE_40_PHONE',
    'TEMPLATE_78',
    'TEMPLATE_81',
    'TEMPLATE_81B',
    'TEMPLATE_81C',
    'TEMPLATE_01B',
    'TEMPLATE_16',
    'TEMPLATE_08',
    'TEMPLATE_08B',
    'TEMPLATE_08B_PHONE',
    'TEMPLATE_01',
    'TEMPLATE_01A',
    'TEMPLATE_1A',
    'TEMPLATE_04',
    'TEMPLATE_04_OT',
    'TEMPLATE_04B',
    'TEMPLATE_04C',
    'TEMPLATE_04C_PHONE',
    'TEMPLATE_04D',
    'TEMPLATE_04D_PHONE',
    'TEMPLATE_04E',
    'TEMPLATE_04F',
    'TEMPLATE_04G',
    'TEMPLATE_04G_PHONE',
    'TEMPLATE_04H',
    'TEMPLATE_04H_PHONE',
    'TEMPLATE_04I',
    'TEMPLATE_04J',
    'TEMPLATE_04K',
    'TEMPLATE_04L',
    'TEMPLATE_5B',
    'TEMPLATE_01C',
    'TEMPLATE_01D',
    'TEMPLATE_01E',
    'TEMPLATE_01F',
    'TEMPLATE_01G',
    'TEMPLATE_01H',
    'TEMPLATE_01I',
    'TEMPLATE_01J',
    'TEMPLATE_148',
    'TEMPLATE_149',
    'TEMPLATE_71',
    'TEMPLATE_141',
    'TEMPLATE_107',
    'TEMPLATE_108',
    'TEMPLATE_135',
    'TEMPLATE_12',
    'TEMPLATE_12A',
    'TEMPLATE_12B',
    'TEMPLATE_103',
    'TEMPLATE_103B',
    'TEMPLATE_95',
    'TEMPLATE_96',
    'TEMPLATE_97',
    'TEMPLATE_104',
    'TEMPLATE_31',
    'TEMPLATE_31_Resend',
    'TEMPLATE_02',
    'TEMPLATE_03',
    'TEMPLATE_139',
    'TEMPLATE_38',
    'TEMPLATE_38_SMS',
    'TEMPLATE_102_Resend',
    'TEMPLATE_102',
    'TEMPLATE_137',
    'TEMPLATE_24',
    'TEMPLATE_122',
    'TEMPLATE_121',
    'TEMPLATE_59',
    'TEMPLATE_100',
    'TEMPLATE_100_Resend',
    'TEMPLATE_101',
    'TEMPLATE_101_Resend',
    'TEMPLATE_177',
    'TEMPLATE_105',
    'TEMPLATE_106',
    'TEMPLATE_PAPERWORK_READINESS',
    'TEMPLATE_167',
    'APPOINTMENT_CONFIRMATION_PIC',
    'APPOINTMENT_CONFIRMATION_PIC_OT',
    'TEMPLATE_APPOINTMENT_REQUEST_REMINDER',
    'TEMPLATE_118B',
    'TEMPLATE_178',
    'TEMPLATE_179',
    'TEMPLATE_180',
    'TEMPLATE_181',
  ],
  MN: [
    'TEMPLATE_MN_11A',
    'TEMPLATE_MN_11B',
    'TEMPLATE_MN_11E',
    'TEMPLATE_MN_12A',
    'TEMPLATE_MN_13A',
    'TEMPLATE_MN_13',
    'TEMPLATE_MN_1A',
    'TEMPLATE_MN_2A',
    'TEMPLATE_MN_2B',
    'TEMPLATE_MN_3A',
    'TEMPLATE_MN_3A_PO',
    'TEMPLATE_MN_3',
    'TEMPLATE_MN_3B',
    'TEMPLATE_MN_3C',
    'TEMPLATE_MN_4A',
    'TEMPLATE_MN_5A',
    'TEMPLATE_MN_5B',
    'TEMPLATE_MN_6A',
    'TEMPLATE_MN_8A',
    'TEMPLATE_MN_8B',
    'TEMPLATE_MN_8C',
    'TEMPLATE_MN_9B',
    'TEMPLATE_MN_14',
    'TEMPLATE_MN_14_PO',
  ],
  SUPP: [
    'TEMPLATE_82',
    'TEMPLATE_82B',
    'TEMPLATE_70',
    'TEMPLATE_85A',
    'TEMPLATE_85B',
    'TEMPLATE_85C',
    'TEMPLATE_73A',
    'TEMPLATE_73A_FR',
    'TEMPLATE_102_Resend',
    'TEMPLATE_102',
    'TEMPLATE_137',
    'TEMPLATE_24',
    'TEMPLATE_122',
    'TEMPLATE_121',
    'TEMPLATE_59',
    'TEMPLATE_100',
    'TEMPLATE_100_Resend',
    'TEMPLATE_101',
    'TEMPLATE_101_Resend',
    'TEMPLATE_107',
    'TEMPLATE_86A',
    'TEMPLATE_87A',
    'TEMPLATE_88A',
    'TEMPLATE_89',
    'TEMPLATE_90',
    'TEMPLATE_105',
    'TEMPLATE_106',
    'TEMPLATE_108',
    'TEMPLATE_135',
    'TEMPLATE_141',
    'TEMPLATE_PAPERWORK_READINESS',
    'TEMPLATE_168',
  ],
  MR: [
    'TEMPLATE_141',
    'TEMPLATE_108',
    'TEMPLATE_135',
    'TEMPLATE_107',
    'TEMPLATE_107B',
    'TEMPLATE_127',
    'TEMPLATE_128',
    'TEMPLATE_70A',
    'TEMPLATE_83',
    'TEMPLATE_35',
    'TEMPLATE_35_LC',
    'TEMPLATE_35A',
    'TEMPLATE_99',
    'TEMPLATE_109',
    'TEMPLATE_99_NoUploadLink',
    'TEMPLATE_63',
    'TEMPLATE_63A',
    'TEMPLATE_50',
    'TEMPLATE_105',
    'TEMPLATE_106',
    'TEMPLATE_PAPERWORK_READINESS',
    'TEMPLATE_85A_MR',
    'TEMPLATE_85B',
    'TEMPLATE_85C',
    'TEMPLATE_168',
  ],
  MVA: [
    'TEMPLATE_MVA_INVOICE_1A',
    'TEMPLATE_MVA_INVOICE_1B',
    'TEMPLATE_MVA_LIEN_REQUEST',
    'TEMPLATE_MVA_AUTHORIZATION_LETTER',
    'TEMPLATE_MVA_GUARANTEE_LETTER',
    'TEMPLATE_MVA_LOA_1',
    'TEMPLATE_MVA_LOR_1A',
    'TEMPLATE_MVA_LOR_1B',
    'TEMPLATE_MVA_LOR_1C',
    'TEMPLATE_MVA_LOR_1D',
    'TEMPLATE_MVA_OFFER_1A',
    'TEMPLATE_MVA_OFFER_1B',
    'TEMPLATE_MVA_OFFER_1C',
    'TEMPLATE_MVA_INSURER_RESPONSE_1A',
    'TEMPLATE_MVA_INSURER_RESPONSE_1B',
    'TEMPLATE_MVA_PAYMENT_1A',
    'TEMPLATE_MVA_PAYMENT_1B',
    'TEMPLATE_MVA_PAYMENT_2A',
    'TEMPLATE_MVA_PAYMENT_2B',
    'TEMPLATE_MVA_SETTLEMENT_1A',
    'TEMPLATE_MVA_SETTLEMENT_2A',
    'TEMPLATE_MVA_SETTLEMENT_3A',
    'TEMPLATE_MVA_SETTLEMENT_4A',
    'TEMPLATE_DEMAND_LETTER_1A',
    'TEMPLATE_DEMAND_LETTER_1B',
    'TEMPLATE_DEMAND_LETTER_1C',
    'TEMPLATE_DEMAND_LETTER_PRINT',
    'TEMPLATE_CLIENT_FEES_AGREEMENT_1A',
    'TEMPLATE_MVA_REMINDER_EXPENSE_BILL',
    'TEMPLATE_MVA_REMINDER_NUMBER_OF_ACTUAL_VISITS_1A',
    'TEMPLATE_MVA_REMINDER_NUMBER_OF_ACTUAL_VISITS_1B',
    'TEMPLATE_MVA_REMINDER_FEE_AGREEMENT_SIGNED',
    'TEMPLATE_MVA_FINAL_DISBURSEMENT_1A',
    'TEMPLATE_MVA_TERMINATION_TO_CLIENT',
    'TEMPLATE_MVA_FINAL_TERMINATION_LETTER_PRINT',
  ],
  INVOICE: ['BILLING_PRINT'],
  OT_QUOTATION: ['OT_QUOTE_LETTER_1A', 'OT_QUOTE_LETTER_2A'],
}

const EmailTemplatePreview = () => {
  const [emailAddress, setEmailAddress] = useState('')
  const form = useRef(null)
  const [openSend, setOpenSend] = useState(false)
  const [loading, setLoading] = useState(false)

  const props = appStore.previewEmailTemplateData
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)

    try {
      const res = await ApiService.EmailTemplate.GetById(props.name)
      appStore.previewEmailTemplateData.data = {
        detail: res.data,
        preview: null,
      }
      await getPreviewData()
    } finally {
      setLoading(false)
    }
  }
  const getPreviewData = async () => {
    let dataEmailPreview = null
    if (props.fromModuleId && isShowPreviewData()) {
      switch (props.fromModule) {
        case FROM_MODULE_TYPE.IME:
          const emailPreviewResp = await ApiService.EmailTemplatePreview.previewEmailIME({
            assessmentId: props.fromModuleId,
            templateKey: props.name,
            ...(props.params || {}),
          })
          dataEmailPreview = _.cloneDeep(emailPreviewResp.data)
          break
        case FROM_MODULE_TYPE.OT_QUOTATION:
          const emailPreviewOT = await ApiService.EmailTemplatePreview.previewEmailOTQUOTATION({
            otQuotationId: props.fromModuleId,
            templateKey: props.name,
            ...(props.params || {}),
          })
          dataEmailPreview = _.cloneDeep(emailPreviewOT.data)
          break
        case FROM_MODULE_TYPE.MN:
          const emailPreviewRespMN = await ApiService.EmailTemplatePreview.previewEmailMN({
            medNegId: props.fromModuleId,
            templateKey: props.name,
            ...(props.params || {}),
          })
          dataEmailPreview = _.cloneDeep(emailPreviewRespMN.data)
          break

        case FROM_MODULE_TYPE.SUPP:
          const emailPreviewSUPP = await ApiService.EmailTemplatePreview.previewEmailSUPP({
            supplementaryId: props.fromModuleId,
            templateKey: props.name,
            ...(props.params || {}),
          })
          dataEmailPreview = _.cloneDeep(emailPreviewSUPP.data)
          break
        case FROM_MODULE_TYPE.MR:
          const emailPreviewMR = await ApiService.EmailTemplatePreview.previewEmailMR({
            medicalRecordId: props.fromModuleId,
            templateKey: props.name,
            ...(props.params || {}),
          })
          dataEmailPreview = _.cloneDeep(emailPreviewMR.data)
          break
        case FROM_MODULE_TYPE.MVA:
          const emailPreviewMVA = await ApiService.EmailTemplatePreview.previewEmailMVA({
            mvaClaimId: props.mvaClaimId ?? props.fromModuleId,
            mvaMedicalProviderId: props.fromModuleId,
            mvaMedicalTreatmentPlanId: props.fromModuleId,
            mvaInsurerOfferId: props.fromModuleId,
            insurerItemId: props.fromModuleId,
            templateKey: props.name,
            ...(props.params || {}),
          })
          dataEmailPreview = _.cloneDeep(emailPreviewMVA.data)
          break
        case FROM_MODULE_TYPE.INVOICE:
          window.open('/view/invoices/print-invoice?id=' + props.fromModuleId, '_blank')
          appStore.hidePreviewEmailTemplate()
          break
        default:
          break
      }
    }

    appStore.previewEmailTemplateData.data.preview = dataEmailPreview
  }

  const isShowPreviewData = () => {
    let templateSupported = []
    if (props.fromModule) {
      templateSupported = SUPPORTED_TEMPLATES[props.fromModule]
    }

    return templateSupported?.includes(props.name)
  }

  const renderTemplateContent = templateContent => {
    if (!templateContent) return null

    return (
      <div className="html-editor-container">
        <div dangerouslySetInnerHTML={{ __html: templateContent }} />
      </div>
    )
  }

  const openDetailEmailTemplate = () => {
    const { name } = props
    appStore.hidePreviewEmailTemplate()
    dashboardStore.close('/view/mail-templates')
    setTimeout(() => {
      dashboardStore.open(
        `/view/mail-templates?mode=detail&templateKey=${name}&tab=${EMAIL_DETAIL_TEMPLATE_TAB.Content}`,
      )
    })
  }

  const sendEmailToTestEmail = async () => {
    setLoading(true)
    setOpenSend(false)
    try {
      if (props.fromModuleId && isShowPreviewData()) {
        switch (props.fromModule) {
          case FROM_MODULE_TYPE.IME:
            await ApiService.EmailTemplatePreview.sendEmailIME(emailAddress, {
              assessmentId: props.fromModuleId,
              templateKey: props.name,
              ...(props.params || {}),
            })
            break
          case FROM_MODULE_TYPE.OT_QUOTATION:
            await ApiService.EmailTemplatePreview.sendEmailOTQUOTATION(emailAddress, {
              otQuotationId: props.fromModuleId,
              templateKey: props.name,
              ...(props.params || {}),
            })
            break
          case FROM_MODULE_TYPE.MN:
            await ApiService.EmailTemplatePreview.sendEmailMN(emailAddress, {
              medNegId: props.fromModuleId,
              templateKey: props.name,
              ...(props.params || {}),
            })
            break
          case FROM_MODULE_TYPE.SUPP:
            await ApiService.EmailTemplatePreview.sendEmailSUPP(emailAddress, {
              supplementaryId: props.fromModuleId,
              templateKey: props.name,
              ...(props.params || {}),
            })
            break
          case FROM_MODULE_TYPE.MR:
            await ApiService.EmailTemplatePreview.sendEmailMR(emailAddress, {
              medicalRecordId: props.fromModuleId,
              templateKey: props.name,
              ...(props.params || {}),
            })
            break
          case FROM_MODULE_TYPE.MVA:
            await ApiService.EmailTemplatePreview.sendEmailMVA(emailAddress, {
              mvaClaimId: props.mvaClaimId ?? props.fromModuleId,
              mvaMedicalProviderId: props.fromModuleId,
              mvaMedicalTreatmentPlanId: props.fromModuleId,
              mvaInsurerOfferId: props.fromModuleId,
              insurerItemId: props.fromModuleId,
              templateKey: props.name,
              ...(props.params || {}),
            })
            break
          default:
            break
        }
      }
      showNotification('Success', 'Email has been sent!', 'success')
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSendTestEmail = async () => {
    const isValid = await validateForm(form)
    if (!isValid) {
      return
    }
    sendEmailToTestEmail()
  }

  const bodyStyleModal = props.data?.preview ? { paddingTop: 0 } : {}

  return (
    <div>
      <AppModal
        open={true}
        width={1000}
        title={props.data.detail ? props.data?.detail?.subject : 'Preview Email Template'}
        bodyStyle={{ ...bodyStyleModal, overflow: 'auto' }}
        footer={
          <>
            <AppButton
              color="blue"
              onClick={() => {
                setTimeout(() => {
                  form?.current?.setFieldsValue?.({
                    emailAddress: appStore.currentTenant?.extraProperties?.AccountEmail || '',
                  })
                  setEmailAddress(appStore.currentTenant?.extraProperties?.AccountEmail || '')
                  setOpenSend(true)
                })
              }}
            >
              Send Preview
            </AppButton>
            {props.isShowEditTemplateTab && (
              <AppButton color="green" onClick={openDetailEmailTemplate}>
                Edit Template
              </AppButton>
            )}
            <AppButton color="red" onClick={() => appStore.hidePreviewEmailTemplate()}>
              Close
            </AppButton>
          </>
        }
      >
        <Spin spinning={loading}>
          {props.data?.detail && isShowPreviewData() ? (
            <Tabs defaultActiveKey="1" className="Tab-email-template">
              <Tabs.TabPane tab="Email Preview" tabKey="1" key={1}>
                {!!props.data?.preview ? (
                  <div>
                    <div className="text-lg">
                      Sender: <span className="font-bold">{props.data?.preview?.sender}</span>
                    </div>
                    <div className="text-lg">
                      Destination: <span className="font-bold">{props.data?.preview?.destination}</span>
                    </div>
                    <div className="text-lg">
                      Subject: <span className="font-bold">{props.data?.preview?.subject}</span>
                    </div>
                    <div className="text-lg">
                      From Module: <span className="font-bold">{props.data?.preview?.metaData?.fromModule}</span>
                    </div>
                    <Divider />

                    <div dangerouslySetInnerHTML={{ __html: props.data?.preview?.body }} />
                  </div>
                ) : (
                  <div>
                    <span>No Preview Data!</span>
                  </div>
                )}
              </Tabs.TabPane>
              {props.isShowEditTemplateTab && (
                <Tabs.TabPane
                  tabKey="2"
                  tab={
                    <>
                      <span>{props.name}</span>
                      <span className="ml-2">
                        <Badge
                          count={props.data?.detail.disabled ? 'Disabled' : ''}
                          style={{ backgroundColor: 'grey', fontSize: 10 }}
                        />
                      </span>
                    </>
                  }
                  key={2}
                >
                  {renderTemplateContent(props.data?.detail?.templateContent)}
                </Tabs.TabPane>
              )}
            </Tabs>
          ) : (
            renderTemplateContent(props.data?.detail?.templateContent)
          )}
        </Spin>
      </AppModal>
      {openSend && (
        <AppModal
          open={openSend}
          title="Send Preview"
          bodyStyle={{ ...bodyStyleModal, overflow: 'auto', zIndex: 10000 }}
          footer={
            <>
              <AppButton color="blue" onClick={onSendTestEmail}>
                Send
              </AppButton>
              <AppButton color="red" onClick={() => setOpenSend(false)}>
                Close
              </AppButton>
            </>
          }
        >
          <AppForm
            form={form}
            id="case-client-details"
            labelAlign="left"
            layout="vertical"
            initialValues={{ emailAddress }}
            onFieldChange={(_, fieldValue) => {
              form?.current?.setFieldsValue?.({ emailAddress: fieldValue })
              setEmailAddress(fieldValue)
            }}
            ready={true}
          >
            <AppFormInput
              rules={[{ type: 'email', message: ERROR_MESSAGES['email'] }]}
              required
              label="Recipient Email"
              name="emailAddress"
            />
          </AppForm>
        </AppModal>
      )}
    </div>
  )
}

export default observer(EmailTemplatePreview)
