import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import AppModal from '.'
import { Flex } from '@components/Layout'
type Props = {
  onCancel?: () => void
  modalParams: {
    saveLabel: string
    confirmMessage: string
    onSave: () => void
    discardChange: () => void
    onCancel?: () => void
  }
}
const WarningExitWithoutSave = ({ onCancel = () => {}, modalParams }: Props) => {
  const { onSave, discardChange } = modalParams

  const handleOnOkClick = () => {
    onCancel()
    onSave?.()
  }
  const handleDiscardChange = () => {
    onCancel()
    discardChange?.()
  }

  const handleCancel = () => {
    onCancel()
    modalParams.onCancel?.()
  }

  return (
    <AppModal
      title={''}
      open={true}
      width={600}
      className="custom-popup-confirm"
      footer={
        <>
          <Button className="button-yes" type="primary" onClick={handleOnOkClick}>
            {modalParams.saveLabel}
          </Button>
          <Button className="button-discard-changes" onClick={handleDiscardChange}>
            Discard changes
          </Button>
          <Button className="button-cancel" onClick={handleCancel}>
            Cancel
          </Button>
        </>
      }
    >
      <div className="custom-content-popup-confirm flex items-center flex-col">
        <ExclamationCircleOutlined style={{ color: 'orange', fontSize: 55 }} />
        <div className="modal-confirm-message mt-5">{modalParams.confirmMessage}</div>
      </div>
    </AppModal>
  )
}

export default WarningExitWithoutSave
