import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/pics'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetAssignedUsersCount(params) {
  return ApiRequest.get(`${BaseRoute}/assignedUsersCount`, { params })
}

const API = {
  ...baseFunctions,
  GetAssignedUsersCount: GetAssignedUsersCount,
}

export default API
