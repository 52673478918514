import { ApiRequest } from '../baseService'

const BaseRoute = '/app/attachments/attachmentTypes'

const API = {
  GetAll,
  SeedAttachmentTypes,
}

function GetAll(params = {}) {
  return ApiRequest.get(`${BaseRoute}`, { params })
}

function SeedAttachmentTypes(params = {}) {
  return ApiRequest.post(`app/tenants/seedAttachmentTypes`, { params })
}

export default API
