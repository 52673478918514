import qs from 'qs'
import { AppRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'statistic'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function CmBooking(params) {
  return AppRequest.get(`${BaseRoute}/cmBooking`, { params })
}

function SpecialistBooking(params) {
  return AppRequest.get(`${BaseRoute}/specialistBooking`, { params })
}

function StaffBooking(params) {
  return AppRequest.get(`${BaseRoute}/staffBooking`, { params })
}
function CountUploadCase(params) {
  return AppRequest.get(`${BaseRoute}/countUploadCase`, { params })
}
// function MyBookingCount(params) {
//   return AppRequest.get(`${BaseRoute}/myBookingCount`, { params })
// }

function GetOverdueAssessmentStatus(params) {
  return AppRequest.get(`${BaseRoute}/overdueAssessmentStatus`, { params })
}

function GetOverdueSupplementaryStatus(params) {
  return AppRequest.get(`${BaseRoute}/overdueSupplementaryStatus`, { params })
}

function GetOverdueClinicalRecordStatus(params) {
  return AppRequest.get(`${BaseRoute}/overdueClinicalRecordStatus`, { params })
}

function GetStatisticNewUser(params) {
  return AppRequest.get(`${BaseRoute}/statisticNewUser`, { params })
}

function GetStatisticToday(params) {
  return AppRequest.get(`${BaseRoute}/statisticToday`, { params })
}

function GetStaffsAssignedActionRequired(params) {
  return AppRequest.get(`action-required/assignedUsersCount`, { params })
}

function GetStatisticByCity(params) {
  return AppRequest.get(`${BaseRoute}/getStatisticByCity`, { params })
}

function GetUserReport(today) {
  return AppRequest.get(`${BaseRoute}/currentUserReport?today=${today}`)
}

function RefreshUserReport(today) {
  return AppRequest.get(`${BaseRoute}/refreshUserReport?today=${today}`)
}

function GetEmailSendingReport(data) {
  return AppRequest.get(`${BaseRoute}/reportEmailSending`, {
    params: data,
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  })
}

function GetDisbursementLoanReport(params) {
  return AppRequest.get(`${BaseRoute}/disbursementLoanReport`, { params })
}

const API = {
  ...baseFunctions,
  CmBooking,
  SpecialistBooking,
  StaffBooking,
  CountUploadCase,
  // MyBookingCount,
  GetOverdueAssessmentStatus,
  GetOverdueSupplementaryStatus,
  GetOverdueClinicalRecordStatus,
  GetStatisticNewUser,
  GetStaffsAssignedActionRequired,
  GetStatisticToday,
  GetStatisticByCity,
  GetUserReport,
  RefreshUserReport,
  GetEmailSendingReport,
  GetDisbursementLoanReport,
}

export default API
