import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/clinical-records'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function CheckDuplicate(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByLastName`, { params })
}

const API = {
  ...baseFunctions,
  CheckDuplicate,
}

export default API
