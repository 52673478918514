import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/health-status'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function SendTestEmailOffice365(payload: any) {
  return ApiRequest.post(`${BaseRoute}/sendTestEmail-office365`, payload)
}

function TestConnectionOffice365() {
  return ApiRequest.post(`${BaseRoute}/testConnection-office365`)
}

function CheckSms(payload: any) {
  return ApiRequest.post(`${BaseRoute}/check-twilio`, payload)
}

function CheckSendGrid(payload: any) {
  return ApiRequest.post(`${BaseRoute}/check-sendgrid`, payload)
}

function CheckIronPdfLicense() {
  return ApiRequest.post(`${BaseRoute}/check-ironpdf-license`)
}

const API = {
  ...baseFunctions,
  SendTestEmailOffice365,
  TestConnectionOffice365,
  CheckSendGrid,
  CheckSms,
  CheckIronPdfLicense
}

export default API
