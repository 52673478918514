import cx from 'classnames'
import { Collapse } from 'antd'
import styled from 'styled-components'

const { Panel } = Collapse

type Props = {
  id: string
  children: JSX.Element
  className?: string
  title: string
  defaultActiveKey?: string | string[]
  fontBold?: boolean
}

const StyledCollapse = styled(Panel)<{ fontBold: boolean }>`
  & > .ant-collapse-header {
    background-color: #f3f3f3;
    padding: 8px 16px !important;
    border-radius: 8px !important;
  }
  & .ant-collapse-header-text {
    font-weight: ${props => (props.fontBold ? 'bold' : 'normal')};
  }
`

export const AppCollapseSingleV2 = ({
  id,
  children,
  title,
  className,
  defaultActiveKey = null,
  fontBold = true,
}: Props) => {
  return (
    <div className={cx([className, 'mt-4'])}>
      <Collapse defaultActiveKey={defaultActiveKey} expandIconPosition="right" bordered={false} ghost>
        <StyledCollapse fontBold={fontBold} header={title} key={id}>
          {children}
        </StyledCollapse>
      </Collapse>
    </div>
  )
}
