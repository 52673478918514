import { observer } from 'mobx-react'
import { Space } from 'antd'
import cx from 'classnames'

type Props = {
  children?: JSX.Element | JSX.Element[]
  className?: string
  [x: string]: any
}

const TableFilterHeader = ({ className = '', children, ...props }: Props) => {
  return (
    <div className={cx('mb-4', className)} {...props}>
      <Space wrap>{children}</Space>
    </div>
  )
}
export default observer(TableFilterHeader)
