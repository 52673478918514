import { showNotificationError } from '@utils/functions'
import axios from 'axios'
import { isArray, isUndefined } from 'lodash-es'
import { QueryClient } from 'react-query'
import localAccountManager from './localAccountManager'

export let API_ACCESS_TOKEN = ''

export const GetAccessToken = () => {
  return API_ACCESS_TOKEN
}

if (import.meta.env.DEV) {
  API_ACCESS_TOKEN = sessionStorage.getItem('access_token') || ''
}

const headerConfig = {
  Authorization: 'Bearer ' + API_ACCESS_TOKEN,
  'X-Requested-With': 'XMLHttpRequest',
}

export const ApiRequest = axios.create({
  headers: headerConfig,
  baseURL: process.env.VITE_API_URL + '/api',
})

export const AppRequest = axios.create({
  headers: headerConfig,
  baseURL: process.env.VITE_API_URL + '/api/app',
})

export const RootRequest = axios.create({
  headers: headerConfig,
  baseURL: process.env.VITE_API_URL + '/',
})

// Define a function to refresh the access token
async function refreshAccessToken() {
  // Make a request to your backend to refresh the token
  // // await userManager.signinSilent()
  const resp = await RootRequest.post(
    '/connect/token',
    {
      refresh_token: localAccountManager.refreshToken, // Send the refresh token
      grant_type: 'refresh_token',
      client_id: 'ICS_App',
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )

  return resp.data
}

const handleRefreshToken = async config => {
  // Access token has expired; refresh it
  const data = await refreshAccessToken()
  localAccountManager.updateOidcUser(data)
  setAuthHeader(data?.access_token)
  return data?.access_token
}

// Add a request interceptor
ApiRequest.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config
    // Check if the error status code is 401 (unauthorized) and handle token refresh
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        // Refresh the token
        const accessToken = await handleRefreshToken(ApiRequest)
        originalRequest.headers.Authorization = 'Bearer ' + accessToken
        // Retry the original request with the new token
        return ApiRequest(originalRequest)
      } catch (refreshError) {
        // Handle refresh token failure (e.g., logout the user)
        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  },
)

const successResponseInterceptor = resp => {
  return resp
}

const errorResponseInterceptor = data => {
  const status = data.response?.status
  const config = data.response?.config
  if ((status === 401 || status == 500) && config.url === 'app/account/my-profile') {
    return Promise.reject(data)
  }

  if (status === 409) {
    const errorMessage =
      "The data you're working on has been modified by other user. Please refresh to get latest data and try again."
    showNotificationError(errorMessage)
    return Promise.reject(errorMessage)
  }

  if (status !== 500) {
    showNotificationError(data)
  }

  return Promise.reject(data)
}

ApiRequest.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor)
AppRequest.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor)
RootRequest.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor)

export const setAuthHeader = token => {
  ApiRequest.defaults.headers.Authorization = 'Bearer ' + token
  AppRequest.defaults.headers.Authorization = 'Bearer ' + token
  RootRequest.defaults.headers.Authorization = 'Bearer ' + token

  API_ACCESS_TOKEN = token

  if (import.meta.env.DEV) {
    sessionStorage.setItem('access_token', token)
  } else {
  }
}

export const clearAuthHeader = () => {
  ApiRequest.defaults.headers.Authorization = undefined
  AppRequest.defaults.headers.Authorization = undefined
  RootRequest.defaults.headers.Authorization = undefined
}

export const queryClient = new QueryClient()

export interface QueryOptions {
  disableCache?: boolean
  /**
   * Cache time in seconds
   */
  staleTime?: number
}
export function CreateParams(params) {
  let qs = []

  Object.keys(params).forEach(key => {
    const value = params[key]

    if (isArray(value)) {
      value.forEach(i => {
        qs.push(`${key}=${i}`)
      })
    } else {
      if (value || typeof value === 'boolean') {
        qs.push(`${key}=${isUndefined(value) ? '' : value}`)
      }
    }
  })
  return qs
}

export function GetBaseServiceFunctions(baseRoute) {
  async function GetAll(params: any = {}, options?: QueryOptions) {
    if (params && !params.maxResultCount) {
      params.maxResultCount = window.APP_CONFIG.maxResultCount
    }

    if (options?.disableCache) {
      await queryClient.invalidateQueries([baseRoute])
    }
    return queryClient.fetchQuery(
      [baseRoute, params],
      () => {
        return ApiRequest.get(baseRoute, {
          params: params,
          paramsSerializer: {
            indexes: true,
          },
        })
      },
      {
        staleTime: (options?.staleTime || 60) * 1000,
      },
    )
  }

  async function GetById(id, options?: QueryOptions) {
    if (options?.disableCache) {
      await queryClient.invalidateQueries([baseRoute])
    }
    return queryClient.fetchQuery(
      [baseRoute, id],
      () => {
        return ApiRequest.get(`${baseRoute}/${id}`)
      },
      {
        staleTime: 60000,
      },
    )
  }

  function GetMyDetail() {
    return ApiRequest.get(`${baseRoute}/myDetail`)
  }

  async function Create(data) {
    const resp = await ApiRequest.post(baseRoute, data)
    await queryClient.invalidateQueries([baseRoute])
    return resp
  }

  async function Update(id, data) {
    await queryClient.invalidateQueries([baseRoute, id])
    await queryClient.invalidateQueries([baseRoute])
    const resp = await ApiRequest.put(`${baseRoute}/${id}`, data)
    return resp
  }

  async function UpdateEmailUsername(id, data) {
    await queryClient.invalidateQueries([baseRoute, id])
    await queryClient.invalidateQueries([baseRoute])
    const resp = await ApiRequest.put(`${baseRoute}/UpdateEmailUsername/${id}`, data)
    return resp
  }

  async function Delete(id, data?) {
    const resp = await ApiRequest.delete(`${baseRoute}/${id}`, data ? { params: data } : null)
    await queryClient.invalidateQueries([baseRoute])
    return resp
  }

  return {
    GetAll: GetAll,
    GetById: GetById,
    GetMyDetail: GetMyDetail,
    Create: Create,
    Update: Update,
    Delete: Delete,
  }
}
