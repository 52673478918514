import { NotificationRequiredField, showNotification } from '@utils/errors'
import { notification } from 'antd'

export const validateForm = async (formRef, showNoti = true) => {
  try {
    const formInstance = formRef?.current
    if (!formInstance) return true
    await formInstance.validateFields()
    return true
  } catch (errorInfo) {
    AppConsole.log({ errorInfo })
    const { errorFields } = errorInfo
    const form = formRef?.current

    if (showNoti) {
      notification.destroy()
      showNotification('Error', NotificationRequiredField)
    }

    const errorFieldInstance = form.getFieldInstance(errorFields[0]?.name[0])
    if (errorFieldInstance?.focus) {
      errorFieldInstance.focus()
    } else {
      form.scrollToField(errorFields[0]?.name[0], { behavior: 'smooth', block: 'centre' })
    }
    return false
  }
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const handleDownloadFile = (fileUrl, fileName) => {
  if (isSafari && (fileUrl.startsWith('blob:') || fileUrl.startsWith('data:'))) {
    if (fileUrl.startsWith('blob:') || fileUrl.startsWith('data:')) {
      // Open in a new tab if it's Safari with blob or data URL
      window.open(fileUrl, '_blank')
    }
  } else {
    // Normal download for other browsers
    const link: any = document.createElement('a')
    link.href = fileUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    setTimeout(() => {
      document.body.removeChild(link)
    }, 500)
  }
}
