import { translate } from 'translator'

export const TAB_TITLES = [
  {
    title: 'View Assessment',
    key: 'AssessmentCaseId',
    tabName: 'View Assessment',
  },
  {
    title: 'Video Session',
    key: 'VideoSessionCaseId',
    tabName: 'Video Session',
  },
  {
    title: 'View Supplementary Report',
    key: 'SupplementaryCaseId',
    tabName: 'View Supplementary Report',
  },
  {
    title: 'View File Review',
    key: 'FileReviewCaseId',
    tabName: 'View File Review',
  },
  {
    title: 'Add Edit Staff',
    key: 'StaffName',
    tabName: 'Add Edit Staff',
  },
  {
    title: 'View Med Neg',
    key: 'MedNegCaseId',
    tabName: 'View Med Neg',
  },
  {
    title: 'View Medical Record',
    key: 'ClinicalRecordCaseId',
    tabName: 'View Medical Record',
  },
  {
    title: 'View Imaging Service',
    key: 'ImagingCaseId',
    tabName: 'View Imaging Service',
  },
  {
    title: 'New Factual Investigation',
    key: 'FactualInvestigationCaseId',
    tabName: 'New Factual Investigation',
  },
  { title: 'View Invoice', key: 'InvoiceCaseId', tabName: 'New Invoice' },
  { title: 'New Company', key: 'CompanyName', tabName: 'New Company' },
  { title: 'New Case Manager', key: 'CmName', tabName: 'New Case Manager' },
  { title: 'Add IRO Code', key: 'ImsApproval', tabName: 'Add IRO Code' },
  { title: 'New Contractor', key: 'TypistName', tabName: 'New Contractor' },
  { title: 'New Specialist', key: 'DoctorName', tabName: 'New Specialist' },
  { title: 'New Centre', key: 'ClinicName', tabName: 'New Centre' },
  { title: `New ${translate('Client')}`, key: 'ClientName', tabName: `New ${translate('Client')}` },
  { title: 'Add/Edit Job Title', key: 'JobTitle', tabName: 'Add Job Title' },
  {
    title: 'Add/Edit Type Of Claim',
    key: 'TypeOfClaims',
    tabName: 'Add Claim Type',
  },
  {
    title: 'Add/Edit Type Of Report',
    key: 'TypeOfReport',
    tabName: 'Add Report Type',
  },
  {
    title: 'Case Managers',
    key: 'ManageCmName',
    tabName: 'Case Managers',
  },
  {
    title: 'Search Cases',
    key: 'FindCases',
    tabName: 'Case & Documents Search',
  },
  {
    title: 'View Billing',
    key: 'InvoiceCaseId',
  },
  {
    title: 'Marketing Campaign',
    key: 'CampaignCaseId',
    tabName: 'Marketing Campaign',
  },
]
