import { ApiRequest } from '../baseService'

const BaseRoute = 'app/system-report'

function GetActiveAccountsByMonth(params = {}) {
  return ApiRequest.get(`${BaseRoute}/activeAccountsByMonth`, { params })
}

const API = {
  GetActiveAccountsByMonth,
}

export default API
