import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import store from './store'
import { getQueryParams } from '@utils/functions'
import router from '@stores/router'
import dayjs from 'dayjs'
import { Popover, notification } from 'antd'
import { InfoCircleFilled, LoadingOutlined, MailFilled, PrinterFilled } from '@ant-design/icons'
import { AppButton } from '@components/AppComponents'
import { ApiService } from '@services/ApiService'
import ModalConfirm from '../../../components/AppModal/ModalConfirm'

import EmailTemplateLink from '@components/EmailTemplateLink'

const DaySheetPrint = () => {
  const { dId, cId, fDate } = getQueryParams(router.location.search, router.location.state)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [specialist, setSpecialist] = useState<any>({})

  useEffect(() => {
    document.title = 'Booking Daysheet'

    if (dId && fDate) {
      setLoading(true)
      async function fetchData() {
        try {
          const data = await store.fetchData({
            specialistId: dId,
            centreId: cId,
            fromDate: dayjs(fDate).format('YYYY-MM-DD'),
          })

          try {
            const specialistResp = await ApiService.Specialist.GetById(dId)
            setSpecialist(specialistResp.data)
          } catch (err) {}

          setData(data)

          if (!data) throw new Error('Cannot found')
        } catch (error) {
          setError(error)
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    } else {
      setError(new Error('Missing parameter id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   // Đảm bảo gọi hàm print() sau khi ảnh được load
  //   // window.addEventListener('load', () => {
  //   //   if (data && !loading) {
  //   //     window.print()
  //   //   }
  //   // })
  // }, [data, loading])

  if (loading) {
    return (
      <div className="container text-center">
        <LoadingOutlined style={{ fontSize: '50px', color: 'var(--blue-400)' }} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="container text-lg">
        <h1>Something Error</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  const print = () => {
    window.print()
  }
  const emailDoctorBookingDaysheets = () => {
    setShowModal(true)
  }

  if (!data) return null

  return (
    <div>
      <div className="print:hidden" style={{ position: 'fixed', top: '20px', left: '300px', zIndex: 100000 }}>
        <div className="flex gap-2">
          <AppButton onClick={emailDoctorBookingDaysheets} color="blue" rounded className="flex items-center">
            <MailFilled /> <span className="mr-1">Email Daysheets</span>
            <Popover
              placement="top"
              content={
                <div>
                  Booking daysheets will be sent to specialist <EmailTemplateLink name="TEMPLATE_06" /> (no bookings),{' '}
                  <EmailTemplateLink name="TEMPLATE_07" /> (with bookings)
                </div>
              }
            >
              <InfoCircleFilled />
            </Popover>
          </AppButton>
          <AppButton color="green" rounded onClick={print}>
            <PrinterFilled /> <span>Print Daysheets</span>
          </AppButton>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
      {showModal && (
        <ModalConfirm
          modalParams={{
            message: 'Do you want to email booking daysheets of the selected dates to the specialist?',
            onOk: () => {
              setShowModal(false)
              ApiService.AppointmentSession.SendSpecialistBookingDaysheets({
                specialistId: dId,
                centreId: cId,
                fromDate: dayjs(fDate).format('YYYY-MM-DD'),
              }).then(() => {
                notification.success({
                  message: 'Success',
                  description: `Email with booking daysheet attached being sent to doctor (${specialist.personalEmail}).`,
                })
              })
            },
          }}
          onCancel={() => {
            setShowModal(false)
          }}
        />
      )}
    </div>
  )
}

export default observer(DaySheetPrint)
