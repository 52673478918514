const Icon8 = ({ name, materialIcon = false, className = '', title = undefined }) => {
  if (name === '') {
    return null
  }

  if (materialIcon) {
    return <img className={className} src={`/assets/icon/${name}.png`} alt="" title={title} />
  }
  return <img className={className} src={`//img.icons8.com/dusk/65/000000/${name}.png`} title={title} /> // eslint-disable-line jsx-a11y/alt-text
}

export default Icon8
