import { ApiService } from '@services/ApiService'
import appStore from '@stores/appStore'
import dashboardStore from '@stores/dashboard'
import { EMAIL_DETAIL_TEMPLATE_TAB } from '@utils/constants'
import { Dropdown, Menu } from 'antd'
import { observer } from 'mobx-react'
import _ from 'lodash'
import cx from 'classnames'
import './style.scss'

export type Props = {
  name: string
  disablePreview?: boolean
  fromModuleId?: string
  mvaClaimId?: string
  fromModule?: string
  params?: any
  previewButtonText?: string
  editButtonText?: string
  isShowEditTemplateTab?: boolean
  // modalWidth?: string
  withoutMargin?: boolean
}

const EmailTemplateLink = (props: Props) => {
  const { name, disablePreview } = props

  const openDetailEmailTemplate = () => {
    appStore.hidePreviewEmailTemplate()
    dashboardStore.close('/view/mail-templates')
    setTimeout(() => {
      dashboardStore.open(
        `/view/mail-templates?mode=detail&templateKey=${name}&tab=${EMAIL_DETAIL_TEMPLATE_TAB.Content}`,
      )
    })
  }

  const previewTemplate = async () => {
    ApiService.EmailTemplate.GetById(name).then(res => {
      appStore.showPreviewEmailTemplate({
        isOpen: true,
        name: props.name,
        fromModule: props.fromModule,
        fromModuleId: props.fromModuleId,
        params: props.params,
        mvaClaimId: props.mvaClaimId,
        data: {
          detail: null,
          preview: null,
        },
        isShowEditTemplateTab: props.isShowEditTemplateTab !== false ? true : props.isShowEditTemplateTab,
      })
    })
  }

  if (appStore.isAdminOrStaff) {
    return (
      <Dropdown
        overlayStyle={{ zIndex: 1000000 }}
        menu={{
          items: [
            {
              key: 'preview',
              label: props.previewButtonText ?? 'Preview Template',
              onClick: e => {
                e.domEvent.stopPropagation()
                previewTemplate()
              },
              disabled: disablePreview,
            },
            ...(props.isShowEditTemplateTab !== false
              ? [
                  {
                    key: 'edit',
                    label: props.editButtonText ?? 'Edit Template',
                    onClick: e => {
                      e.domEvent.stopPropagation()
                      openDetailEmailTemplate()
                    },
                  },
                ]
              : []),
          ],
        }}
      >
        <span className={cx(['text-blue-400 cursor-pointer hover:text-blue-500', !props.withoutMargin && 'mr-1'])}>
          {name}
        </span>
      </Dropdown>
    )
  } else {
    return <b>{name}</b>
  }
}

export default observer(EmailTemplateLink)
