import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/video-sessions'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetVideoInfo(assessmentId) {
  return ApiRequest.get(`${BaseRoute}/getVideoInfo`, {
    params: { assessmentId },
  })
}

function ViewVideo(params = {}) {
  return ApiRequest.post(`${BaseRoute}/viewVideo`, null, {
    params,
  })
}

const API = {
  ...baseFunctions,
  GetVideoInfo,
  ViewVideo,
}

export default API
