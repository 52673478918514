import { useEffect, useState } from 'react'
import { Input } from 'antd'
import appStore from 'stores/appStore'
export default function AppDateInfo({ format = 'DD MMM YYYY', date, type = 'label' }) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (date) {
      const dateString = typeof date === 'string' ? date : (date?.toISOString ? date.toISOString() : date)

      if (typeof dateString !== 'string') return

      const ignoreTimeZone = dateString ? dateString.endsWith('T00:00:00Z') : false
      if (ignoreTimeZone) {
        setValue(appStore.formatDateIgnoreTimezone(date, format))
      } else {
        setValue(appStore.formatDate(date, null, format))
      }
    } else {
      setValue('')
    }
  }, [date, format])

  return (
    <>
      {type === 'label' && <span className='whitespace-nowrap'>{value}</span>}
      {type === 'input' && <Input disabled value={value} />}
    </>
  )
}
