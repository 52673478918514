import cx from 'classnames'

interface Props {
  children: React.ReactNode
  gap?: number
  className?: string
}

const Flex = ({ children, gap = 2, className }: Props) => {
  return <div className={cx(['flex items-center', gap ? `gap-${gap}` : '', className])}>{children}</div>
}

export default Flex
