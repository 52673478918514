import { Spin } from 'antd'

interface TableWrapperProps {
  children: any
  loading?: boolean
  contentLoaderMode?: boolean
}

export default function TableWrapper({ children, loading = false, contentLoaderMode = true }: TableWrapperProps) {
  return (
    <div className="table-wrapper">
      {contentLoaderMode ? (
        <Spin spinning={false}>
          {children}
        </Spin>
      ) : (
        <Spin spinning={loading}>
          {children}
        </Spin>
      )}
    </div>
  )
}
