import React from 'react'
import ReactDOM from 'react-dom'

let portalRoot = document.querySelector('#portal-root')

;(() => {
  if (portalRoot) return

  const portal = document.createElement('div')
  portal.id = 'portal-root'
  document.body.appendChild(portal)
  portalRoot = portal
})()

class Portal extends React.Component<any> {
  el = null
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    portalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default Portal
