import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/correspondences'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

function SendEmail(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/SendEmail`)
}

function GetRCStaffPics(params) {
  return ApiRequest.get(`${BaseRoute}/RCStaffPic`, { params })
}

const API = {
  ...baseFunctions,
  ExportSearchResult,
  SendEmail,
  GetRCStaffPics,
}

export default API
