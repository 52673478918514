import { ApiRequest } from '../baseService'

const BaseRoute = 'app/practice-evolve-app'

const GetListMatters = (method, url, data?) => {
  return ApiRequest.post(`${BaseRoute}/matters`, {
    method: method,
    data: data,
    url: url,
  })
}

const GetClientMatters = (method, url, data?, companyPMSId = null) => {
  return ApiRequest.post(`${BaseRoute}/clients${companyPMSId == null ? '' : '?companyPMSId=' + companyPMSId}`, {
    method: method,
    data: data,
    url: url,
  })
}

const GetMatterDetail = (id, tenantId, companyPMSId = null) => {
  return ApiRequest.get(
    `${BaseRoute}/matters/${id}?tenantId=${tenantId ?? ''}${
      companyPMSId == null ? '' : '&companyPMSId=' + companyPMSId
    }`,
  )
}

const GetMatterAccount = (matterId, companyPMSId = null) => {
  return ApiRequest.get(
    `${BaseRoute}/mattersAccount/` + matterId + `${companyPMSId == null ? '' : '?companyPMSId=' + companyPMSId}`,
  )
}

const API = {
  GetListMatters,
  GetMatterDetail,
  GetMatterAccount,
  GetClientMatters,
}
export default API
