import React from 'react'

type ContextType = {
  isShowFilterSection?: boolean
  setShowFilterSection?: (isShowFilterSection: boolean) => void
}

export const TableFilterContext = React.createContext<ContextType>({
  isShowFilterSection: false,
  setShowFilterSection: () => {},
})
