const global: any = window
const FormID = 150000001966
export const initHelpDeskSystem = (currentTenant: any, currentUser: any) => {
  if (process.env.VITE_ENALBE_HELP_DESK != 'true') return
  global.fwSettings = {
    widget_id: FormID,
  }

  const script = document.createElement('script')

  script.src = `https://widget.freshworks.com/widgets/${FormID}.js`
  script.async = true
  script.defer = true
  document.body.appendChild(script)

  setTimeout(() => {
    if ('function' != typeof global.FreshworksWidget) {
      const n = function () {
        n.q.push(arguments)
      }
      n.q = []
      global.FreshworksWidget = n
    }
    initDefaultInputData(currentTenant, currentUser)
  }, 1000)
}

const initDefaultInputData = (currentTenant: any, currentUser: any) => {
  if (!currentTenant) return
  global.FreshworksWidget(
    'identify',
    'ticketForm',
    {
      name: currentUser.name,
      email: currentTenant.extraProperties?.Email, // Inquiry/Booking Email from Tenant Setting
    },
    {
      formId: FormID, // Ticket Form ID
    },
  )
}
