import appStore from '@stores/appStore'
import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/centries'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Enable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

function Delete(id) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(`${BaseRoute}/${id}/service-types`)
}

function AddServiceType(centreId, serviceTypeId, data) {
  return ApiRequest.post(`${BaseRoute}/${centreId}/service-types`, data)
}

function UpdateServiceType(centreId, serviceTypeId, data) {
  return ApiRequest.put(`${BaseRoute}/${centreId}/service-types/${serviceTypeId}`, data)
}

function RemoveServiceType(centreId, serviceTypeId) {
  return ApiRequest.delete(`${BaseRoute}/${centreId}/service-types/${serviceTypeId}`)
}

function GetSpecialists(centreId, params?) {
  return ApiRequest.get(`${BaseRoute}/${centreId}/specialists`, { params })
}

function AddSpecialist(centreId, data) {
  return ApiRequest.post(`${BaseRoute}/${centreId}/specialists`, data)
}

function RemoveSpecialist(centreId, specialistId) {
  return ApiRequest.delete(`${BaseRoute}/${centreId}/specialists/${specialistId}`)
}

function ReceiveBooking(centreId, specialistId) {
  return ApiRequest.put(`${BaseRoute}/${centreId}/receiveBooking/${specialistId}`)
}

function CheckDuplicate(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByName`, { params })
}

function CheckDuplicateEmail(params) {
  return ApiRequest.post(`${BaseRoute}/IsDuplicateEmail`, params)
}

function GetSimilarItemsByEmail(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByEmail`, { params })
}

function GetAll(params: any = {}, options?) {
  if (!params) {
    params = {}
  }
  if (params.includeHostTenantData === undefined) {
    params.includeHostTenantData = appStore.isCountryUS
  }
  return baseFunctions.GetAll(params, options)
}

const API = {
  ...baseFunctions,
  GetAll,
  Enable,
  Disable,
  Delete,
  AddServiceType,
  UpdateServiceType,
  RemoveServiceType,
  GetSpecialists,
  AddSpecialist,
  RemoveSpecialist,
  ReceiveBooking,
  CheckDuplicate,
  CheckDuplicateEmail,
  GetSimilarItemsByEmail,
}

export default API
