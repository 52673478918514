import { ApiRequest } from '../baseService'

const BaseRoute = 'app/entity-changes'

function getRecentChangeById(id, params) {
  return ApiRequest.get(`${BaseRoute}/${id}/property-changes`, { params })
}

function GetBookedAppointmentHistory(id, params) {
  return ApiRequest.get(`${BaseRoute}/${id}/BookedAppointmentHistory`, { params })
}

const API = {
  getRecentChangeById,
  GetBookedAppointmentHistory,
}

export default API
