import DyteVideoMeeting from '@components/VideoMeeting/DyteVideoMeeting'
import loadable from '@loadable/component'
import { RouteConfig } from 'routes-setting/interface'
const OneTimeAccess = loadable(() => import('./OneTimeAccess'))
const SigninOidc = loadable(() => import('./signin-oidc'))
const Page404 = loadable(() => import('@pages/errors/Page404'))
const AppLogin = loadable(() => import('./AppLogin'))
const Document = loadable(() => import('../../documents/Document'))
const AppVideoMeeting = loadable(() => import('@pages/video-meeting/AppVideoMeeting'))

export const AnonymousRoutes: RouteConfig[] = [
  {
    path: '/view/dyte-meeting-demo',
    Component: DyteVideoMeeting,
    title: 'Dyte Meeting Demo',
  },
  {
    path: '/signin-oidc',
    Component: SigninOidc,
    title: '',
  },
  // {
  //   path: '/subscription',
  //   Component: PricingTable,
  //   title: '',
  // },
  {
    path: '/not-found',
    Component: Page404,
    title: '',
  },
  {
    path: '/login',
    Component: AppLogin,
    title: '',
  },
  {
    title: 'One Time Access',
    path: '/view/onetimeaccess',
    Component: OneTimeAccess,
  },
  {
    path: '/view/document',
    Component: Document,
    title: 'Document',
  },
  {
    path: '/video-meeting',
    Component: AppVideoMeeting, //VideoMeeting,
    title: 'Video Meeting',
  },
]
