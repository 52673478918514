import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import OpenReplay from '@openreplay/tracker'

const EnableSessionStack = process.env.VITE_ENABLE_OPENREPLAY == 'true'

let openReplayTracker: OpenReplay

const integrations = [new Integrations.BrowserTracing()] as any[]
if (EnableSessionStack) {
  // integrations.push(Sentry.replayIntegration())
}
export const init = () => {
  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    integrations: integrations,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    debug: false,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    release: 'kawaconn-spa-@' + process.env.VITE_VERSION,
    environment: process.env.VITE_SENTRY_ENV,
  })

  // Init OpenReplay
  // const openReplayProjectKey = process.env.VITE_OPEN_REPLAY_KEY
  // if (EnableSessionStack) {
  //   openReplayTracker = new OpenReplay({
  //     projectKey: openReplayProjectKey,
  //     __DISABLE_SECURE_MODE: true,
  //     onStart: ({ sessionToken }) => {
  //       Sentry.setTag('openReplaySessionToken', sessionToken)
  //       // This is to link from Sentry -> OpenReplay (requires tracker v3.6.0+)
  //       Sentry.setTag('openReplaySessionURL', openReplayTracker.getSessionID())
  //     },
  //   })
  //   openReplayTracker.start()
  // }
}

export const initSessionStack = (user, tenant) => {
  if (EnableSessionStack) {
    Sentry.setUser({ email: user.email, id: `${user.email} - ${tenant?.name}` })
    Sentry.setExtras({ userMeta: user })
    // if (openReplayTracker) {
    //   openReplayTracker.setUserID(`${user.email} - ${tenant?.name}`)
    //   openReplayTracker.setMetadata('userMeta', JSON.stringify(user))
    // }
  }
}
