import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/editions'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetFeatures(editionId) {
  return ApiRequest.get(`${BaseRoute}/${editionId}/features`)
}
function SetDefaultFeatures(editionId) {
  return ApiRequest.post(`${BaseRoute}/${editionId}/setDefaultFeatures`)
}

const API = {
  ...baseFunctions,
  GetFeatures,
  SetDefaultFeatures,
}

export default API
