import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/adhocemail-templates'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Enable(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

function GetRenderedContent(id, data) {
  return ApiRequest.get(`${BaseRoute}/${id}/get-rendered-content`, {
    params: data,
  })
}

const AdHocTemplate = {
  ...baseFunctions,
  Enable,
  Disable,
  GetRenderedContent,
}

export default AdHocTemplate
