import React, { useEffect, useState } from 'react'
import AppModal from '@components/AppModal'
import { observer } from 'mobx-react'
import appStore from '@stores/appStore'
import { AppSelectInput } from '@components/AppComponents'
// import ColorPicker from './ColorPicker'
type Props = {}

const ThemeSettings = ({}: Props) => {
  return (
    <AppModal
      title="Theme Settings"
      open={true}
      width={600}
      onCancel={() => {
        appStore.showThemeSetting = false
      }}
    >
      <div>
        <AppSelectInput
          label="Sidebar Color"
          labelCol={{ span: 24 }}
          value={appStore.theme}
          options={[
            { displayName: 'Dark', id: 'dark' },
            { displayName: 'Dark Blue', id: 'dark-blue' },
            { displayName: 'Light', id: 'light' },
          ]}
          onChange={type => appStore.setTheme(type)}
        />

        {/* <ColorPicker
          defaultColor={appStore.colorLogo.LogoBackgroundColor}
          label="Sidebar Logo Background Color"
          className="mb-4"
          onChange={color => appStore.setLogoColor('LogoBackgroundColor', color)}
        /> */}

        {/* <ColorPicker
          defaultColor={appStore.colorLogo.LoginLogoBackgroundColor}
          label="Login Logo Background Color"
          onChange={color => appStore.setLogoColor('LoginLogoBackgroundColor', color)}
        /> */}
      </div>
    </AppModal>
  )
}

export default observer(ThemeSettings)
