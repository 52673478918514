import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/CasePermission'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const CreateOrUpdate = data => {
  return ApiRequest.post(`${BaseRoute}`, data)
}
const API = { ...baseFunctions, CreateOrUpdate }
export default API
