import { Pagination, Select } from 'antd'
import cx from 'classnames'
import './style.scss'
import React, { useMemo } from 'react'

const { Option } = Select

const PAGE_SIZES = [20, 50, 100]

function PaginateTable({ skipCount = 0, totalCount, pageSize, defaultPageSize = 20, onChangePageSize, onChangePage }) {
  const currentPage = React.useMemo(() => {
    return skipCount / pageSize + 1
  }, [pageSize, skipCount])

  const pageSizes = useMemo(() => {
    return Array.from(new Set([...PAGE_SIZES, defaultPageSize])).sort((a, b) => a - b)
  }, [defaultPageSize])

  return (
    <div className={cx('flex 2xl:relative flex-row items-center', 'paginate-table-container')}>
      <div className={cx('left-0 h-full flex items-center mb-0 mr-4')}>
        <span className="mr-2">Rows per page</span>
        <Select className="pagination-selector" size="small" value={pageSize} onChange={onChangePageSize}>
          {pageSizes.map(size => (
            <Option key={size} value={size}>
              {size}
            </Option>
          ))}
        </Select>
        {totalCount > 0 ? (
          <span className="ml-2">
            Showing {skipCount + 1} - {skipCount + pageSize <= totalCount ? skipCount + pageSize : totalCount} of{' '}
            {totalCount} {totalCount === 1 ? 'result' : 'results'}
          </span>
        ) : (
          <span className="ml-2">No result found</span>
        )}
      </div>
      <Pagination
        pageSize={pageSize}
        showSizeChanger={false}
        current={currentPage}
        className="text-center"
        total={totalCount}
        hideOnSinglePage
        onChange={onChangePage}
      />
    </div>
  )
}

export default PaginateTable
