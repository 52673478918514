import { GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/action-required/assignedUsersCount'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const API = {
  ...baseFunctions,
}

export default API
