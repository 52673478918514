import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/funding-providers'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function getFundingProviderInfo(id) {
  return ApiRequest.get(`${BaseRoute}/${id}`)
}

function getStaffByFundingId(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/staffs`)
}

function addStaffProvider(providerId, payload) {
  queryClient.invalidateQueries([BaseRoute, providerId])
  return ApiRequest.post(`${BaseRoute}/${providerId}/staffs`, payload)
}

function removeStaffProvider(providerId, staffId) {
  queryClient.invalidateQueries([BaseRoute, providerId])
  return ApiRequest.delete(`${BaseRoute}/${providerId}/staffs/${staffId}`)
}

const API = {
  ...baseFunctions,
  getFundingProviderInfo,
  getStaffByFundingId,
  addStaffProvider,
  removeStaffProvider,
}

export default API
