import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/caseclientdetails'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const GetItem = (fromModule, objectId, isGetSummary) => {
  return ApiRequest.get(`${BaseRoute}?fromModule=${fromModule}&objectId=${objectId}&isGetSummary=${isGetSummary}`)
}

const updateBillingStatus = (caseClientDetailId, data) => {
  return ApiRequest.put(`${BaseRoute}/${caseClientDetailId}/billingStatus`, data)
}

const API = {
  ...baseFunctions,
  GetItem,
  updateBillingStatus,
}
export default API
