import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/checklists'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetTaskChecklist(params) {
  return ApiRequest.get(`app/task-checklists`, {
    params,
  })
}

function GetTaskChecklistLmqStatus(fromModule = '', serviceType = null) {
  return ApiRequest.get(`app/task-checklists/countLmqStatus`, {
    params: { fromModule: fromModule, imeServiceType: serviceType },
  })
}

function UpdateTaskChecklist(payload) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.put(`app/task-checklists`, payload)
}

function UpdateTaskChecklistCriterias(taskChecklistId, payload) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.put(`app/task-checklists/${taskChecklistId}/criterias`, payload)
}

function ResetCriteria(taskChecklistId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.put(`app/task-checklists/${taskChecklistId}/reset-criterias`)
}

function CopyCheckList(id) {
  return ApiRequest.post(`app/checklists/${id}/copy`)
}

function ResetCheckListToDefault(id) {
  return ApiRequest.delete(`app/checklists/${id}/resetToDefault`)
}

function Enable(id) {
  return ApiRequest.put(`app/checklists/${id}/enable`)
}

function Disable(id) {
  return ApiRequest.put(`app/checklists/${id}/disable`)
}

const API = {
  ...baseFunctions,
  GetTaskChecklist,
  UpdateTaskChecklist,
  UpdateTaskChecklistCriterias,
  GetTaskChecklistLmqStatus,
  ResetCriteria,
  CopyCheckList,
  ResetCheckListToDefault,
  Enable,
  Disable,
}

export default API
