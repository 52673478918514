import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/onetime-access'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const OneTimeAccessInfo = token => {
  return ApiRequest.post(`${BaseRoute}/${token}`)
}
const PostOneTimeAccess = data => {
  return ApiRequest.post(`${BaseRoute}`, data)
}

const API = {
  ...baseFunctions,
  OneTimeAccessInfo,
  PostOneTimeAccess,
}
export default API
