import { MenuOutlined } from '@ant-design/icons'
import dashboardStore from '@stores/dashboard'
import './ShowSidebarIcon.scss'

const ShowSidebarIcon = () => {
  const { toggleSidebar } = dashboardStore
  return (
    <div
      className="DashboardHeaderIcon ShowSidebarIcon flex items-center justify-center text-white cursor-pointer"
      onClick={toggleSidebar}
    >
      <MenuOutlined />
    </div>
  )
}

export default ShowSidebarIcon
