import { ApiRequest } from '../baseService'

const BaseRoute = 'app/magconnect'

const SearchAssessment = params => {
  return ApiRequest.get(`${BaseRoute}/assessments`, { params: params })
}
const SearchSupplementary = params => {
  return ApiRequest.get(`${BaseRoute}/supplementaries`, { params: params })
}

const SearchFileReview = params => {
  return ApiRequest.get(`${BaseRoute}/fileReviews`, { params: params })
}

const SearchClinicalRecord = params => {
  return ApiRequest.get(`${BaseRoute}/clinicalRecords`, { params: params })
}

const SearchMedNeg = params => {
  return ApiRequest.get(`${BaseRoute}/medNegs`, { params: params })
}

const API = {
  SearchAssessment,
  SearchSupplementary,
  SearchFileReview,
  SearchClinicalRecord,
  SearchMedNeg,
}
export default API
