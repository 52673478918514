import { ApiRequest } from '../baseService'

const BaseRoute = 'app/smokeball-app'

const GetMatterDetail = (id, tenantId) => {
  return ApiRequest.get(`${BaseRoute}/matters/${id}?tenantId=${tenantId ?? ''}`)
}

const GetListMatters = (url, caseNo?) => {
  return ApiRequest.post(`${BaseRoute}/matters`, {
    method: 'Get',
    url: url,
    caseNo: caseNo,
  })
}

const GetListParticipant = (method, url, caseNo?) => {
  return ApiRequest.post(`${BaseRoute}/contacts`, {
    method: method,
    url: url,
    caseNo: caseNo,
  })
}

const GetMatterAccount = matterId => {
  return ApiRequest.post(`${BaseRoute}/mattersAccount/` + matterId)
}

const API = {
  GetMatterDetail,
  GetListMatters,
  GetListParticipant,
  GetMatterAccount,
}
export default API
