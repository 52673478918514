import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/mailboxs'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetAllAssignedCaseOnwers() {
  return ApiRequest.get(`${BaseRoute}/getAllAssignedCaseOnwers`)
}

function GetAllAssignedPic() {
  return ApiRequest.get(`${BaseRoute}/getAllAssignedPic`)
}

function GetStatusCount() {
  return ApiRequest.get(`${BaseRoute}/getStatusCount`)
}

function GetActionNotesByMailId(mailId) {
  return ApiRequest.get(`${BaseRoute}/${mailId}/actionnotes`)
}

function MoveMailBoxFolder(payload) {
  return ApiRequest.put(`${BaseRoute}/MoveMailBoxFolder`, payload)
}

function Update(payload) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.put(`${BaseRoute}/Update`, payload)
}

function AddActionNote(mailId, payload) {
  queryClient.invalidateQueries([BaseRoute, mailId])
  return ApiRequest.post(`${BaseRoute}/${mailId}/actionnotes`, payload)
}

function UpdateActionNote(mailId, actionId, payload) {
  queryClient.invalidateQueries([BaseRoute, mailId])
  return ApiRequest.put(`${BaseRoute}/${mailId}/actionnotes/${actionId}`, payload)
}

function DeleteActionNote(mailId, actionNoteId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(`${BaseRoute}/${mailId}/actionnotes/${actionNoteId}`)
}

function Forward(mailId, payload) {
  queryClient.invalidateQueries([BaseRoute, mailId])
  return ApiRequest.post(`${BaseRoute}/${mailId}/forward`, payload)
}

function Reply(mailId, payload) {
  queryClient.invalidateQueries([BaseRoute, mailId])
  return ApiRequest.post(`${BaseRoute}/${mailId}/reply`, payload)
}

const API = {
  ...baseFunctions,
  GetAllAssignedCaseOnwers,
  GetActionNotesByMailId,
  MoveMailBoxFolder,
  Update,
  AddActionNote,
  UpdateActionNote,
  DeleteActionNote,
  GetStatusCount,
  GetAllAssignedPic,
  Forward,
  Reply,
}

export default API
