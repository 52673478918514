import { AppButton } from '@components/AppComponents'
import TooltipInfo from '@components/TooltipInfo'
import { ApiService } from '@services/ApiService'
import { ApiRequest } from '@services/baseService'
import { showNotification } from '@utils/errors'
import { useState } from 'react'

const MigrationDB = () => {
  const [loading, setLoading] = useState(false)

  const handleMigration = async () => {
    setLoading(true)
    try {
      const res = await ApiService.Migration.Migrate()
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }
  const handleGenerateItemIndex = async () => {
    setLoading(true)
    try {
      const res = await ApiService.Tenant.GenerateItemIndex()
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateCaseManagerRecruitmentStatus = async () => {
    setLoading(true)
    try {
      const res = await ApiRequest.put(`app/case-managers/UpdateRecruitmentStatus`)
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateContractorRecruitmentStatus = async () => {
    setLoading(true)
    try {
      const res = await ApiRequest.put(`app/contractors/UpdateRecruitmentStatus`)
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }

  const handleMigrateSpecialistManagers = async () => {
    setLoading(true)
    try {
      const res = await ApiRequest.put(`app/specialists/SyncAccountManagerIdToAccountManagerIds`)
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }
  const handleMigrateSpecialistContracts = async () => {
    setLoading(true)
    try {
      const res = await ApiRequest.put(`app/specialists/SyncSpecialistContractToNewTable`)
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }

  const updateSpecialistRecruitingStatus = async () => {
    setLoading(true)
    try {
      const res = await ApiRequest.put(`app/specialists/UpdateRecruitmentStatus`)
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }

  const handleSeedClinicsData = async () => {
    setLoading(true)
    try {
      const res = await ApiService.Tenant.SeedClinics()
      showNotification('Success', 'Send request successfully.', 'success')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex justify-start gap-2">
      <AppButton onClick={handleMigration} loading={loading} disabled={loading} color="blue">
        Migrate Store Procedures
      </AppButton>
      {/* <AppButton onClick={handleGenerateItemIndex} loading={loading} disabled={loading} color="blue">
        Generate ItemIndex{' '}
        <TooltipInfo text={'Generate ItemIndex for all CaseManager, Contractor, Specialist, Centre, Client, Company'} />
      </AppButton> */}
      {/* <AppButton onClick={handleUpdateCaseManagerRecruitmentStatus} loading={loading} disabled={loading} color="blue">
        Update CaseManager Recruitment Status
      </AppButton> */}
      {/* <AppButton onClick={handleUpdateContractorRecruitmentStatus} loading={loading} disabled={loading} color="blue">
        Update Contractor Recruitment Status
      </AppButton> */}
      {/* <AppButton onClick={handleSeedClinicsData} loading={loading} disabled={loading} color="blue">
        Seed Clinics Data
      </AppButton> */}
      {/* <AppButton onClick={handleMigrateSpecialistManagers} loading={loading} disabled={loading} color="blue">
        Migrate Specialist Managers
      </AppButton> */}

      <AppButton onClick={handleMigrateSpecialistContracts} loading={loading} disabled={loading} color="blue">
        Migrate Specialist Contracts
      </AppButton>
    </div>
  )
}

export default MigrationDB
