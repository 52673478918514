import { ApiRequest } from '../baseService'

const BaseRoute = 'app/search'

function CompanyActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/companyActionRequiredSearch`, { params: params })
}

function CaseManagerActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/casemanagerActionRequiredSearch`, { params: params })
}

function SpecialistActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/specialistActionRequiredSearch`, { params: params })
}

function AssessmentActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/assessmentActionRequiredSearch`, { params: params })
}

function SupplementaryActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/supplementaryActionRequiredSearch`, { params: params })
}

function FileReviewActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/fileReviewActionRequiredSearch`, { params: params })
}

function ClinicalRecordActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/clinicalRecordActionRequiredSearch`, { params: params })
}

function InvoiceActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/invoiceActionRequiredSearch`, { params: params })
}

function MedNegActionRequiredSearch(params) {
  return ApiRequest.get(`${BaseRoute}/medNegActionRequiredSearch`, { params: params })
}

function DocumentSearch(params) {
  return ApiRequest.get(`${BaseRoute}/document`, { params: params })
}

function GetAssessmentPIC(params) {
  return ApiRequest.get(`${BaseRoute}/assessmentPicSearch`, { params: params })
}

function GetSuppPIC(params) {
  return ApiRequest.get(`${BaseRoute}/supplementaryPicSearch`, { params: params })
}
function GetFRPIC(params) {
  return ApiRequest.get(`${BaseRoute}/fileReviewPicSearch`, { params: params })
}
function GetClinicalPIC(params) {
  return ApiRequest.get(`${BaseRoute}/clinicalRecordPicSearch`, { params: params })
}

const API = {
  CompanyActionRequiredSearch,
  CaseManagerActionRequiredSearch,
  SpecialistActionRequiredSearch,
  SupplementaryActionRequiredSearch,
  FileReviewActionRequiredSearch,
  ClinicalRecordActionRequiredSearch,
  AssessmentActionRequiredSearch,
  DocumentSearch,
  InvoiceActionRequiredSearch,
  MedNegActionRequiredSearch,
  GetAssessmentPIC,
  GetSuppPIC,
  GetFRPIC,
  GetClinicalPIC,
}

export default API
