import { TableFilterContext } from '@components/table/TableContext'
import { Card, Form } from 'antd'
import cx from 'classnames'
import React, { useContext, useMemo } from 'react'

interface Props {
  children: React.ReactNode
  maxColumns?: number
}

const FilterSection = ({ children, maxColumns = 4 }: Props) => {
  const filterContext = useContext(TableFilterContext)
  const classNames = useMemo(() => {
    if (maxColumns == 4) {
      return 'grid gap-x-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'
    }

    if (maxColumns == 3) {
      return 'grid gap-x-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3'
    }

    if (maxColumns == 2) {
      return 'grid gap-x-4 grid-cols-1 sm:grid-cols-2'
    }
  }, [maxColumns])

  if (!filterContext.isShowFilterSection) return null
  return (
    <Card
      className={cx([
        !filterContext.isShowFilterSection ? 'hidden' : 'block',
        'mb-4 rounded-lg border border-grey-200',
      ])}
    >
      <Form layout="vertical">
        <div className={classNames}>{children}</div>
      </Form>
    </Card>
  )
}

export default FilterSection
