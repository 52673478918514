import { ApiRequest } from '../baseService'

const BaseRoute = 'app/tenants/roles'
function rolesAll() {
  return ApiRequest.get(`${BaseRoute}`)
}

function setDefaultPermissions(roleName) {
  return ApiRequest.put(`app/roles/setDefaultPermissions?roleName=${roleName}`)
}

const API = {
  rolesAll,
  setDefaultPermissions,
}

export default API
