import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/ndis'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

function SendEmail({ id = null, emailType = null, caseManagerId = null }) {
  return ApiRequest.post(
    `${BaseRoute}/${id}/emails?emailType=${emailType}${caseManagerId ? `&caseManagerId=${caseManagerId}` : ''}`,
  )
}
function SendNdisEmail(id = null, emailType = null, caseManagerId = null) {
  return ApiRequest.post(
    `${BaseRoute}/${id}/ndisEmails?emailType=${emailType}${caseManagerId ? `&caseManagerId=${caseManagerId}` : ''}`,
  )
}
function RebookCase(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/rebook`)
}

function GetAppointments({ id }) {
  return ApiRequest.get(`${BaseRoute}/${id}/appointment`)
}

function DeleteAttendees(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.delete(`${BaseRoute}/${id}/attendees`)
}

function DeleteSession(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.delete(`${BaseRoute}/${id}/session`)
}

function GetClinicalNotes(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/getClinicalNotes`)
}

function DeleteClinicalNote(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.delete(`${BaseRoute}/${id}/clinicalnote`)
}

function ViewServiceAgreement(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/printServiceAgreement?isPdf=true`, {
    headers: {
      Accept: 'application/pdf',
    },
  })
}
function ViewParticipantReferalForm(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/printParticipantReferalForm?isPdf=true`, {
    headers: {
      Accept: 'application/pdf',
    },
  })
}

function GenerateDocuSignServiceAgreement(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/generateDocuSignServiceAgreement`)
}

function SyncServiceAgreementDocuSignStatus(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/syncServiceAgreementDocuSignStatus`)
}

function GetKeyContact(ndisId) {
  return ApiRequest.get(`${BaseRoute}/${ndisId}/KeyContact`)
}

function CreateUpdateKeyContact(ndisId, data) {
  return ApiRequest.put(`${BaseRoute}/${ndisId}/KeyContact`, data)
}

function DeleteKeyContact(ndisId, contactId) {
  return ApiRequest.delete(`${BaseRoute}/${ndisId}/KeyContact/${contactId}`)
}

function GetImplementingProvider(ndisId) {
  return ApiRequest.get(`${BaseRoute}/${ndisId}/ImplementingProvider`)
}

function CreateUpdateImplementingProvider(ndisId, data) {
  return ApiRequest.put(`${BaseRoute}/${ndisId}/ImplementingProvider`, data)
}

function DeleteImplementingProvider(ndisId, contactId) {
  return ApiRequest.delete(`${BaseRoute}/${ndisId}/ImplementingProvider/${contactId}`)
}

function GetRestraints(ndisId) {
  return ApiRequest.get(`${BaseRoute}/${ndisId}/ChemicalRestraint`)
}

function AddUpdateRestraint(ndisId, data) {
  return ApiRequest.put(`${BaseRoute}/${ndisId}/ChemicalRestraint`, data)
}

function DeleteRestraint(ndisId, restraintId) {
  return ApiRequest.delete(`${BaseRoute}/${ndisId}/ChemicalRestraint/${restraintId}`)
}

const API = {
  ...baseFunctions,
  ExportSearchResult,
  SendEmail,
  SendNdisEmail,
  GetAppointments,
  DeleteAttendees,
  DeleteSession,
  RebookCase,
  GetClinicalNotes,
  DeleteClinicalNote,
  ViewServiceAgreement,
  ViewParticipantReferalForm,
  // ViewConsentForm,
  // ViewIntake,
  GenerateDocuSignServiceAgreement,
  SyncServiceAgreementDocuSignStatus,
  GetKeyContact,
  CreateUpdateKeyContact,
  DeleteKeyContact,
  GetImplementingProvider,
  CreateUpdateImplementingProvider,
  DeleteImplementingProvider,
  GetRestraints,
  AddUpdateRestraint,
  DeleteRestraint
}

export default API
