import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/disbursements/funding-agreements'
const AgreementsAdvanceRoute = 'app/disbursements/agreement-advance-requests'
const AgreementsAdvancePaymentsRoute = 'app/disbursements/agreement-advance-payments'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)
const agreementAdvanceBaseFunctions = GetBaseServiceFunctions(AgreementsAdvanceRoute)
const agreementAdvancePaymentBaseFunctions = GetBaseServiceFunctions(AgreementsAdvancePaymentsRoute)

function getFundingAgreementInfo(id) {
  return ApiRequest.get(`${BaseRoute}/${id}`)
}

function getAccruedPrincipalInterest(agreementId, params = {}) {
  return ApiRequest.get(`${BaseRoute}/${agreementId}/AccruedPrincipalInterest?fundingAgreementId=${agreementId}`, {
    params: { ...params },
  })
}

function calculateAccruedPrincipalInterest(agreementId) {
  return ApiRequest.post(`${BaseRoute}/${agreementId}/AccruedPrincipalInterest`)
}

function getFundingAgreementInterestRatesInfo(agreementId) {
  return ApiRequest.get(`${BaseRoute}/${agreementId}/interest-rates`)
}

function createFundingAgreementInterestRatesInfo(agreementId, data) {
  return ApiRequest.post(`${BaseRoute}/${agreementId}/interest-rates`, data)
}

function updateFundingAgreementInterestRatesInfo(agreementId, interestRatesId, data) {
  return ApiRequest.put(`${BaseRoute}/${agreementId}/interest-rates/${interestRatesId}`, { ...data })
}

function deleteFundingAgreementInterestRatesInfo(agreementId, interestRatesId) {
  return ApiRequest.delete(`${BaseRoute}/${agreementId}/interest-rates/${interestRatesId}`)
}
function getFundingAgreementRepaymentnfo(agreementId) {
  return ApiRequest.get(`${BaseRoute}/${agreementId}/repayment`)
}

function createFundingAgreementRepaymentInfo(agreementId, data) {
  return ApiRequest.post(`${BaseRoute}/${agreementId}/repayment`, data)
}

function updateFundingAgreementRepaymentInfo(agreementId, interestRatesId, data) {
  return ApiRequest.put(`${BaseRoute}/${agreementId}/repayment/${interestRatesId}`, { ...data })
}

function deleteFundingAgreementRepaymentInfo(agreementId, interestRatesId) {
  return ApiRequest.delete(`${BaseRoute}/${agreementId}/repayment/${interestRatesId}`)
}

function calculateDrawAmountAdvanceRequest(data) {
  return ApiRequest.post(`${AgreementsAdvanceRoute}/calculate`, data)
}

function calculateAdvancePaymentAmount(data) {
  return ApiRequest.get(`${AgreementsAdvancePaymentsRoute}/calculatePaymentAmount`, {
    params: {
      ...data,
    },
  })
}
function calculateAdvancePaymentLoanInterest(data) {
  return ApiRequest.put(
    `${AgreementsAdvancePaymentsRoute}/calculateLoanInterest?id=${data.id}&toDate=${data.paymentDate}`,
    {
      ...data,
    },
  )
}

function getListMARTransaction(data) {
  return ApiRequest.get(`${AgreementsAdvanceRoute}/getListTransaction`, {
    params: data,
  })
}

function PrintAdvanceRequest(id) {
  return ApiRequest.get(`${AgreementsAdvanceRoute}/${id}/print`)
}

function PrintAdvancePayment(id) {
  return ApiRequest.get(`${AgreementsAdvancePaymentsRoute}/${id}/print`)
}

function getBorrowerInfo(fundingProviderId) {
  return ApiRequest.get(`${BaseRoute}/getBorrower?fundingProviderId=${fundingProviderId}`)
}
const API = {
  ...baseFunctions,
  getFundingAgreementInfo,
  agreementAdvance: { ...agreementAdvanceBaseFunctions },
  agreementAdvancePayment: { ...agreementAdvancePaymentBaseFunctions },
  calculateDrawAmountAdvanceRequest,
  calculateAdvancePaymentAmount,
  calculateAdvancePaymentLoanInterest,
  getFundingAgreementInterestRatesInfo,
  createFundingAgreementInterestRatesInfo,
  updateFundingAgreementInterestRatesInfo,
  deleteFundingAgreementInterestRatesInfo,
  getFundingAgreementRepaymentnfo,
  createFundingAgreementRepaymentInfo,
  updateFundingAgreementRepaymentInfo,
  deleteFundingAgreementRepaymentInfo,
  getListMARTransaction,
  calculateAccruedPrincipalInterest,
  getAccruedPrincipalInterest,
  PrintAdvanceRequest,
  PrintAdvancePayment,
  getBorrowerInfo,
}

export default API
