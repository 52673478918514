import { ApiRequest } from '../baseService'

const BaseRoute = 'app/account'

function getProfile() {
  return ApiRequest.get(`${BaseRoute}/my-profile`)
}

function updateProfile(data) {
  return ApiRequest.put(`${BaseRoute}/my-profile`, data)
}

function changePassword(data) {
  return ApiRequest.put(`${BaseRoute}/change-password`, data)
}

const API = {
  getProfile,
  updateProfile,
  changePassword,
}

export default API
