
/**
 * Timezones: refercence https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * https://mm.icann.org/pipermail/tz/2012-October/018369.html
 */
export const AustraliaTimeZones = [
  {
    name: 'Australia/ACT',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/Adelaide',
    standardTime: 'ACST',
    daylightTime: 'ACDT',
  },
  {
    name: 'Australia/Brisbane',
    standardTime: 'AEST',
    daylightTime: 'AEST',
  },
  {
    name: 'Australia/Broken_Hill',
    standardTime: 'ACST',
    daylightTime: 'ACDT',
  },
  {
    name: 'Australia/Canberra',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/Currie',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/Darwin',
    standardTime: 'ACST',
    daylightTime: 'ACST',
  },
  {
    name: 'Australia/Eucla',
    standardTime: 'ACWST',
    daylightTime: 'ACWST',
  },
  {
    name: 'Australia/Hobart',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/LHI',
    standardTime: 'LHST',
    daylightTime: 'LHDT',
  },
  {
    name: 'Australia/Lindeman',
    standardTime: 'AEST',
    daylightTime: 'AEST',
  },
  {
    name: 'Australia/Lord_Howe',
    standardTime: 'LHST',
    daylightTime: 'LHDT',
  },
  {
    name: 'Australia/Melbourne',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/North',
    standardTime: 'ACST',
    daylightTime: 'ACST',
  },
  {
    name: 'Australia/NSW',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/Perth',
    standardTime: 'AWST',
    daylightTime: 'AWST',
  },
  {
    name: 'Australia/Queensland',
    standardTime: 'AEST',
    daylightTime: 'AEST',
  },
  {
    name: 'Australia/South',
    standardTime: 'ACST',
    daylightTime: 'ACDT',
  },
  {
    name: 'Australia/Sydney',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/Tasmania',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/Victoria',
    standardTime: 'AEST',
    daylightTime: 'AEDT',
  },
  {
    name: 'Australia/West',
    standardTime: 'AWST',
    daylightTime: 'AWST',
  },
  {
    name: 'Australia/Yancowinna',
    standardTime: 'ACST',
    daylightTime: 'ACDT',
  },
]
