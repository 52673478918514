import { ApiRequest, GetBaseServiceFunctions, QueryOptions } from '../baseService'
import { queryClient } from './../baseService'

const BaseRoute = 'app/disbursements/disbursement-funding'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)
function GenerateRepaymentInvoice(id) {
  return ApiRequest.get(`${BaseRoute}/GenerateRepaymentInvoice/${id}`)
}
function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function ExportStatementInvoice(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/exportStatementInvoice`, payload, { responseType: 'arraybuffer' })
}
function ExportOutstandingStatement(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/exportOutstandingStatement`, payload, { responseType: 'arraybuffer' })
}

function DaySheetPrint(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/invoice-print`)
}
function Duplicate(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/duplicate`)
}
function CheckCaseNo(payload) {
  if (!payload.caseNo) return null
  payload.caseNo = payload.caseNo?.trim()
  return ApiRequest.post(`${BaseRoute}/checkCaseNo`, payload)
}

function ValidateInvoiceNo(params: any = {}) {
  if (params && !params.maxResultCount) {
    params.maxResultCount = window.APP_CONFIG.maxResultCount
  }
  return ApiRequest.get(
    `${BaseRoute}/validateInvoiceNo?id=${params.id}&fundingInvoiceNo=${
      params.fundingInvoiceNo ?? ''
    }&borrowerReference=${params.borrowerReference ?? ''}`,
  )
}

function OutStandingStatementPrint(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/outstanding-statement-print`, payload)
}

function GetFundingStatementData(params: any = {}) {
  if (params && !params.maxResultCount) {
    params.maxResultCount = window.APP_CONFIG.maxResultCount
  }
  return ApiRequest.get(`${BaseRoute}/OutstandingStatementData`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}
function GetStatementInvoiceData(params: any = {}) {
  if (params && !params.maxResultCount) {
    params.maxResultCount = window.APP_CONFIG.maxResultCount
  }
  return ApiRequest.get(`${BaseRoute}/StatementInvoiceData`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}

function SendSelectedInvoices(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/SendSelectedInvoices`, payload)
}
function SendFundingStatement(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/SendFundingStatement`, payload)
}
function SendRemittanceAdvice(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/SendRemittanceAdvice`, payload)
}
const SendEmail = (id, data, isSMS = false) => {
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${data}&isSMS=${isSMS}`)
}
const UpdateCaseDetail = data => {
  queryClient.invalidateQueries([BaseRoute, data.id])
  return ApiRequest.put(`${BaseRoute}/${data.id}/CaseDetail`, data)
}
const UpdateFundingType = data => {
  queryClient.invalidateQueries([BaseRoute, data.id])
  return ApiRequest.put(`${BaseRoute}/${data.id}/FundingType`, data)
}

// const UpdateRePayment = data => {
//   queryClient.invalidateQueries([BaseRoute, data.id])
//   return ApiRequest.put(`${BaseRoute}/${data.id}/RePayment`, data)
// }

const Active = id => {
  return ApiRequest.put(`${BaseRoute}/${id}/Active`)
}

const Cancelled = id => {
  return ApiRequest.put(`${BaseRoute}/${id}/Cancelled`)
}

function DownloadTemplateUpload() {
  return ApiRequest.post(`${BaseRoute}/template-import`, {}, { responseType: 'arraybuffer' })
}
function UploadFiles(file) {
  return ApiRequest.post(`${BaseRoute}/import`, file)
}

const API = {
  ...baseFunctions,
  ExportSearchResult,
  ExportStatementInvoice,
  ExportOutstandingStatement,
  CheckCaseNo,
  DaySheetPrint,
  Duplicate,
  OutStandingStatementPrint,
  GetFundingStatementData,
  SendSelectedInvoices,
  SendFundingStatement,
  SendRemittanceAdvice,
  GetStatementInvoiceData,
  SendEmail,
  UpdateCaseDetail,
  UpdateFundingType,
  Active,
  Cancelled,
  ValidateInvoiceNo,
  GenerateRepaymentInvoice,
  DownloadTemplateUpload,
  UploadFiles
}

export default API
