import './Header.scss'
import cx from 'classnames'
import { observer } from 'mobx-react'
import dashboardStore from '@stores/dashboard'
import ToggleAutoHideIcon from '../ToggleAutoHideIcon'

const SidebarHeader = () => {
  const { collapsed } = dashboardStore.sidebar
  return (
    <div className={cx('SidebarHeader overflow-hidden', { collapsed })}>
      <ToggleAutoHideIcon />
    </div>
  )
}

export default observer(SidebarHeader)
