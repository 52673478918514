import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client'
import { clearAuthHeader, setAuthHeader } from '@services/baseService'
import localAccountManager from './localAccountManager'
import LocalStorageManager from './storage/LocalStorageManager'
import { ImpersonateService } from './impersonate'

const oauth2Config: UserManagerSettings = {
  authority: process.env.VITE_API_URL,
  client_id: 'ICS_App',
  // automaticSilentRenew: true,
  redirect_uri: (window as any) ? window.location?.origin + '/signin-oidc' : '',
  response_type: 'code',
  scope: 'offline_access openid profile roles email phone ICS',
  post_logout_redirect_uri: window.location.origin + '/signout-oidc',
  // filterProtocolClaims: true,
  // loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }), // Enable this when multiple login account ready,
}

export const userManager = new UserManager(oauth2Config)

export async function loadUserFromStorage() {
  try {
    ImpersonateService.handleImpersonate()

    let user = await userManager.getUser()
    if (user) {
      setAuthHeader(user.access_token)
      localAccountManager.saveCurrentAccount()
    }
    return user
  } catch (e) {
    console.error(`User not found: ${e}`)
  }
}

export function signinRedirect(options?) {
  if (options && options.redirectUri) {
    sessionStorage.setItem('redirectUri', options.redirectUri)
  }
  return userManager.signinRedirect()
}

export async function logout() {
  await userManager.clearStaleState()
  await userManager.removeUser()
  clearAuthHeader()

  LocalStorageManager.clearSessionSetting()

  const logoutUrl = `${process.env.VITE_API_URL}/account/logout?returnUrl=${window.location.protocol}//${window.location.host}`
  window.location.href = logoutUrl
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signoutRedirect() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager
