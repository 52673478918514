import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/email-templates'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function resetTemplateToDefault(templateKey) {
  return ApiRequest.post(`${BaseRoute}/resetToDefault?templateKey=${templateKey}`)
}

function resetContentToDefault(templateKey) {
  return ApiRequest.post(`${BaseRoute}/removeTenantTextTemplate?templateKey=${templateKey}`)
}

function setActive(templateKey, isActive) {
  return ApiRequest.put(`${BaseRoute}/${templateKey}/active?isActive=${isActive}`)
}

function resetHostEmailTemplates() {
  return ApiRequest.post(`${BaseRoute}/resetHostEmailTemplates`)
}

function getTenantEditedTemplate(templateKey) {
  return ApiRequest.get(`${BaseRoute}/getEditedTenantTemplates?templateKey=${templateKey}`)
}

function validateTemplate(templateKey, data = {}) {
  return ApiRequest.post(`${BaseRoute}/${templateKey}/validate`, data)
}

const API = {
  ...baseFunctions,
  setActive,
  resetTemplateToDefault,
  resetContentToDefault,
  resetHostEmailTemplates,
  getTenantEditedTemplate,
  validateTemplate,
}

export default API
