import _ from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'

export interface LocalAccount {
  tenantId: string
  email: string
  userName: string
  displayName: string
  tenantName: string
  oidcUser: string
  expireAt: number
}

class LocalAccountManager {
  get oidcUserStorageKey() {
    return 'oidc.user:' + process.env.VITE_API_URL + ':ICS_App'
  }

  constructor() {
    this.accounts = JSON.parse(localStorage.getItem('accounts') ?? '[]')
    this.currentAccount = JSON.parse(localStorage.getItem('currentAccount') ?? '{}') ?? {}
    makeAutoObservable(this)
  }

  get refreshToken() {
    if (!this.currentAccount) return null
    const oidcUser = JSON.parse(this.currentAccount.oidcUser)
    return oidcUser?.refresh_token
  }

  accounts: LocalAccount[]

  currentAccount: LocalAccount

  saveAccount(account: LocalAccount) {
    let tenantId = account.tenantId ?? undefined
    const existAccountIndex = this.accounts.findIndex(i => i.tenantId == tenantId && i.userName == account.userName)

    runInAction(() => {
      if (existAccountIndex >= 0) {
        this.accounts[existAccountIndex] = account
      } else {
        this.accounts = [...this.accounts, account]
      }
    })
    this.saveDataToLocalStorage()
  }

  saveCurrentAccount() {
    const oidcUser = JSON.parse(localStorage.getItem(this.oidcUserStorageKey) ?? 'null')
    if (oidcUser) {
      const userProfile = oidcUser.profile

      const currentAccount: LocalAccount = {
        tenantId: userProfile.tenantid,
        email: userProfile.email,
        userName: userProfile.preferred_username,
        displayName: userProfile.name,
        tenantName: '',
        oidcUser: JSON.stringify(oidcUser),
        expireAt: oidcUser.expires_at,
      }

      this.currentAccount = currentAccount
      this.saveAccount(currentAccount)
    } else {
      console.error('not found oidc.user data')
    }
  }

  updateOidcUser(data: any) {
    const currentOidcUser = Object.assign(JSON.parse(this.currentAccount.oidcUser), data)

    this.currentAccount.oidcUser = JSON.stringify(currentOidcUser)
    localStorage.setItem(this.oidcUserStorageKey, this.currentAccount.oidcUser)
  }

  // Function to switch to a different account
  switchAccount(account: LocalAccount) {
    // Perform the necessary actions to switch to the selected account
    if (account) {
      this.currentAccount = account
      // Save the current account details in local storage
      this.saveDataToLocalStorage()

      // Change oidc user
      localStorage.setItem(this.oidcUserStorageKey, account.oidcUser)
    }
  }

  removeAccount(tenantId: string, userName: string) {
    const removeIndex = this.accounts.findIndex(i => i.tenantId == tenantId && i.userName === userName)
    if (removeIndex >= 0) {
      runInAction(() => (this.accounts = this.accounts.filter((_, idx) => idx !== removeIndex)))
    }

    this.saveDataToLocalStorage()
  }

  removeCurrentAccount() {
    const removeIndex = this.accounts.findIndex(
      i => i.tenantId == this.currentAccount.tenantId && i.userName === this.currentAccount.userName,
    )

    if (removeIndex >= 0) {
      runInAction(() => (this.accounts = this.accounts.filter((_, idx) => idx !== removeIndex)))
      this.currentAccount = null
    }

    this.saveDataToLocalStorage()
  }

  private saveDataToLocalStorage() {
    // Save the updated accounts array to local storage
    localStorage.setItem('accounts', JSON.stringify(this.accounts))
    localStorage.setItem('currentAccount', JSON.stringify(this.currentAccount))
  }
}
export default new LocalAccountManager()
