import { handleDownloadFile } from '@services/formService'
import { ApiRequest, GetAccessToken } from '../baseService'

const baseRoute = 'file-management/file-descriptor'

function PreUploadInfo(data) {
  return ApiRequest.post(`${baseRoute}/pre-upload-info`, data)
}

function Upload(data) {
  return ApiRequest.post(`app/files/upload/`, data)
}

function UploadAnonymousFile(data, tenantId) {
  return ApiRequest.post(`app/files/uploadAnonymousFile?tenantId=` + tenantId, data)
}

function GetToken(id) {
  return ApiRequest.get(`${baseRoute}/download/${id}/token`)
}

function GenerateDownloadToken(fileIds) {
  return ApiRequest.get(`app/files/token?fileIds=${fileIds}`)
}

async function Download(file) {
  const res = await GenerateDownloadToken(file.fileId)
  const accessToken = res.data

  const downloadUrl = process.env.VITE_API_URL + `/api/app/files/download?accessToken=${accessToken}`

  handleDownloadFile(downloadUrl, file.name)
}

function GetFile(fileId) {
  return ApiRequest.get(`app/files/${fileId}`, { responseType: 'blob' })
}

function GetOnetimeAccessFile(fileId, tenantId) {
  return ApiRequest.get(`app/files/OnetimeAccessFile/${fileId}?tenantId=${tenantId}`, { responseType: 'blob' })
}

async function DownloadAll(fileList) {
  if (!fileList.length) return

  let fileIds = fileList.map(file => file.fileId || file.id)
  fileIds = fileIds.join(',')

  const res = await GenerateDownloadToken(fileIds)
  const accessToken = res.data

  const downloadUrl = process.env.VITE_API_URL + `/api/app/files/download?accessToken=${accessToken}`
  handleDownloadFile(downloadUrl, fileList.length && fileList.length <= 1 ? fileList[0].fileId : 'download.zip')
}

const API = {
  PreUploadInfo,
  Upload,
  UploadAnonymousFile,
  GetToken,
  Download,
  DownloadAll,
  GetFile,
  GetOnetimeAccessFile,
}

export default API
