import { FROM_MODULE_TYPE } from '@utils/constants'
import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/contractors'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Enable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

function CheckDuplicate(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByLastName`, { params })
}
function CheckDuplicateEmail(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByEmail`, { params })
}

function GetBookingHistory(params) {
  return ApiRequest.get(`${BaseRoute}/bookingHistory`, {
    params: { ...params, FromModuleType: FROM_MODULE_TYPE.CONTRACTOR },
  })
}

async function UpdateEmailUsername(id, data) {
  await queryClient.invalidateQueries([BaseRoute, id])
  await queryClient.invalidateQueries([BaseRoute])
  const resp = await ApiRequest.put(`${BaseRoute}/UpdateEmailUsername/${id}`, data)
  return resp
}

const API = {
  ...baseFunctions,
  Enable,
  Disable,
  CheckDuplicate,
  CheckDuplicateEmail,
  GetBookingHistory,
  UpdateEmailUsername,
}

export default API
