import { Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import cx from 'classnames'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import './style.scss'
type Props = {
  className?: string
  size?: string
  name?: string
  mode?: 'multiple' | 'tags'
  rounded?: boolean
  prefixLabel?: string
  options: any[]
  textKey?: string
  valueKey?: string
  value?: any
  disabled?: boolean
  hint?: string
  onChange?: (name, value, option?) => void
  onSearch?: (event) => void | Promise<any>
  [x: string]: any
  optionDefault?: any
  customItemDropdown?: any
}
export const AppSelect = observer(
  ({
    className = null,
    size = 'middle',
    name = null,
    mode = null,
    rounded = false,
    prefixLabel = '',
    options = [],
    optionDefault = null,
    textKey = 'displayName',
    valueKey = 'id',
    value = [],
    onChange = (name, value, option?) => {},
    onSearch = () => {},
    customItemDropdown = null,
    ...props
  }: Props) => {
    const handleOnChange = useCallback(
      (value, option) => {
        if (name) {
          onChange(name, value, option)
        } else {
          onChange(value, option)
        }
      },
      [name, onChange],
    )
    if (optionDefault) {
      if (Array.isArray(optionDefault)) {
        optionDefault.forEach(item => {
          options = _.uniqBy<any>([...(options || []), item || {}], 'id').filter(i => i?.id)
        })
      } else {
        options = _.uniqBy<any>([...(options || []), optionDefault || {}], 'id').filter(i => i?.id)
      }
    }

    const getItemDropdown = opt => {
      if (customItemDropdown && typeof customItemDropdown === 'function') return customItemDropdown(opt)

      return (
        <>
          {opt[textKey]} {opt.isActive === false ? ' (InActive)' : ''}
        </>
      )
    }
    const handleFilterSearch = (searchTerm, option) => {
      const originOption = options.find(i => i[valueKey] == option?.value)
      if (!originOption) {
        return false
      }
      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(escapedSearchTerm, 'i')
      return regex.test(originOption[textKey])
    }
    return (
      <Select
        className={cx([className, rounded ? 'rounded-full' : ''])}
        size={size as SizeType}
        mode={mode}
        value={value}
        onChange={handleOnChange}
        onSearch={onSearch}
        optionLabelProp="label"
        optionFilterProp="children"
        showSearch
        filterOption={handleFilterSearch}
        {...props}
      >
        {(options || []).map((opt, i) => {
          const label =
            typeof opt[textKey] === 'string'
              ? `${prefixLabel + opt[textKey]}${opt.isActive === false ? ` (InActive)` : ''}`
              : opt[textKey] ?? ''
          return (
            <Select.Option key={opt[valueKey]} value={opt[valueKey]} label={label} data={opt}>
              {getItemDropdown(opt)}
            </Select.Option>
          )
        })}
      </Select>
    )
  },
)
