import { LoadingOutlined } from '@ant-design/icons'
import router from '@stores/router'
import { getQueryParams } from '@utils/functions'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import store from './store'

const PrintLetterOfConfirmation = () => {
  const { id } = getQueryParams(router.location.search, router.location.state)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    document.title = 'MR View'

    if (id) {
      setLoading(true)
      async function fetchData() {
        try {
          const data = await store.getConfirmationLetter(id)
          setData(data)
          if (!data) throw new Error('Cannot found')
        } catch (error) {
          setError(error)
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    } else {
      setError(new Error('Missing parameter id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {}, [data, loading])

  if (loading) {
    return (
      <div className="container text-center">
        <LoadingOutlined style={{ fontSize: '50px', color: 'var(--blue-400)' }} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="container text-lg">
        <h1>Something Error</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  if (!data) return null

  return <div dangerouslySetInnerHTML={{ __html: data }}></div>
}

export default observer(PrintLetterOfConfirmation)
