import './index.scss'
import { FC } from 'react'

import Table, { TableProps } from './AppTable'
import TableWrapper from './TableWrapper'
import TableFilterContainer from './TableFilterContainer'
import TableFilterHeader from './TableFilterHeader'
import FilterSection from './FilterSection'
import FilterButton from './FilterButton'
import TableDefaultButton from './TableDefaultButton'

interface TableComponent extends FC<TableProps> {
  FilterContainer: typeof TableFilterContainer
  FilterButton: typeof FilterButton
  FilterHeader: typeof TableFilterHeader
  DefaultButton: typeof TableDefaultButton
  FilterSection: typeof FilterSection
}

const AppTable = Table as TableComponent
AppTable.FilterContainer = TableFilterContainer
AppTable.FilterHeader = TableFilterHeader
AppTable.FilterSection = FilterSection
AppTable.FilterButton = FilterButton
AppTable.DefaultButton = TableDefaultButton

export { AppTable, TableWrapper }
