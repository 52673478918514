import { RootRequest } from '../baseService'

const baseRoute = 'files'

function Upload(data, replaceFile = '') {
  let url = baseRoute

  if (replaceFile) url += `?replaceFile=${replaceFile}`

  return RootRequest.post(url, data)
}

function GetFile(filename) {
  return RootRequest.get(`${baseRoute}/${filename}`)
}

function Download(filename) {
  const downloadUrl = `${baseRoute}/${filename}`
  createTagA(downloadUrl, filename)
}

const createTagA = (downloadUrl, fileName) => {
  const link: any = document.createElement('a')
  link.style = 'display: none'
  link.href = downloadUrl
  link.target = '_blank'
  link.download = fileName
  document.body.appendChild(link)
  link.click()

  setTimeout(() => {
    document.body.removeChild(link)
  }, 1000)
}

const API = {
  Upload,
  GetFile,
  Download,
}

export default API
