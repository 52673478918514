import { queryClient } from './../baseService'
import { ApiRequest, CreateParams, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/assessments'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const UpdateBookingDetails = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/booking-detail`, data)
}

const UpdateAppointment = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/appointment`, data)
}

const UpdatePaperwork = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/paperwork`, data)
}

const UpdateAttendance = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/attendance`, data)
}

const UpdateCancellation = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/cancellation`, data)
}

const UpdateDictation = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/dictation-report`, data)
}

const UpdateVideoInfo = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.post(`${BaseRoute}/${data.id}/videoInfo`, data)
}

const GetVideoInfo = id => {
  return ApiRequest.get(`${BaseRoute}/${id}/videoInfo`)
}

const GetOTProcess = id => {
  return ApiRequest.get(`${BaseRoute}/${id}/otProcess`)
}

const SendEmail = (id, data, isSMS = false, isResend = true) => {
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${data}&isSMS=${isSMS}&isResend=${isResend}`)
}

const SendOTQuotationEmail = (id, data, isSMS = false) => {
  return ApiRequest.post(`${BaseRoute}/${id}/otQuotation/emails?emailType=${data}&isSMS=${isSMS}`)
}

function Rebook(id, notifyCaseDNA = false) {
  queryClient.invalidateQueries([BaseRoute]) // invalid assessment detail
  return ApiRequest.post(`${BaseRoute}/${id}/rebook?notifyCaseDNA=` + notifyCaseDNA)
}

function SendReportStatus(id, payload = {}) {
  let qs = CreateParams(payload)
  return ApiRequest.post(`${BaseRoute}/${id}/sendReportStatus?${qs.join('&')}`)
}

function ViewConsentForm(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/ConsentForm`)
}
function ViewCheckList(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/AssessmentCheckList`)
}

function GenerateVideoLink(id) {
  queryClient.invalidateQueries([BaseRoute, id]) // invalid assessment detail
  return ApiRequest.post(`${BaseRoute}/${id}/generateVideoLink`, { id })
}

function GetClientHistory({ assessmentId = null, clientId = null, specialistId = null }) {
  return ApiRequest.get(`${BaseRoute}/specialistClientHistory`, { params: { assessmentId, clientId, specialistId } })
}

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

function ViewAppointmentConfirmationCM(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/AppointmentConfirmationCM`)
}

function ViewAppointmentConfirmationSpecialist(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/AppointmentConfirmationSpecialist`)
}

function RescheduleAppointment(data) {
  queryClient.invalidateQueries([BaseRoute, data.id])
  return ApiRequest.put(`${BaseRoute}/${data.id}/reschedule-appointment`, data)
}

function GetQuotation(caseId: string) {
  return ApiRequest.get(`${BaseRoute}/${caseId}/otQuotation`)
}

function SaveQuotation(caseId: string, data: any) {
  return ApiRequest.put(`${BaseRoute}/${caseId}/otQuotation`, data)
}
function SaveQuotationTemplate(caseId: string, data: any) {
  return ApiRequest.put(`${BaseRoute}/${caseId}/otQuotation/template`, data)
}
function GetQuotationItemsCost(caseId: string, quotationId: string) {
  return ApiRequest.get(`${BaseRoute}/${caseId}/otQuotation/${quotationId}/itemCosts`)
}
function CreateQuotationItemsCost(caseId: string, quotationId: string, data: any) {
  return ApiRequest.put(`${BaseRoute}/${caseId}/otQuotation/${quotationId}/itemCosts`, data)
}

function DeleteQuotationItemsCost(caseId: string, quotationId: string, itemCostId: any) {
  return ApiRequest.delete(`${BaseRoute}/${caseId}/otQuotation/${quotationId}/itemCosts/${itemCostId}`)
}

function InitCaseOTFromQuotation(caseId: string) {
  return ApiRequest.post(`${BaseRoute}/${caseId}/otQuotation/initCase`)
}

function GenerateDocuSignConsentForm(caseId: string) {
  return ApiRequest.post(`${BaseRoute}/${caseId}/generateDocuSignConsentForm`)
}
function SyncDocuSignEnvelopStatus(caseId: string) {
  return ApiRequest.post(`${BaseRoute}/${caseId}/syncConsentFormDocuSignStatus`)
}

function GetAppointmentHistory(assessmentId: string) {
  return ApiRequest.get(`${BaseRoute}/${assessmentId}/appointmentHistory`)
}

function ResendRescheduleAppointmentUpdate(assessmentId: string) {
  return ApiRequest.post(`${BaseRoute}/${assessmentId}/resendRescheduleAppointmentUpdate`)
}

function ValidateUhg(claimantNo: string) {
  return ApiRequest.post(`${BaseRoute}/validateUhg?claimantNo=${claimantNo}`)
}

function ConnectToUHG(claimantNo: string) {
  return ApiRequest.post(`${BaseRoute}/connectCaseToUHG?claimantNo=${claimantNo}`)
}

function CreateUHGCase(claimantNo: string) {
  return ApiRequest.post(`${BaseRoute}/uhgCase?claimantNo=${claimantNo}`)
}

function SyncUHGAppointment(caseId: string) {
  return ApiRequest.post(`${BaseRoute}/${caseId}/syncUHGAppointment`)
}

function SyncUHGPaperwork(caseId: string) {
  return ApiRequest.post(`${BaseRoute}/${caseId}/uhgPaperwork`)
}

function CancelAppointment(formData) {
  return ApiRequest.post(`${BaseRoute}/${formData.assessmentId}/cancelAppointment`, formData)
}

const API = {
  ...baseFunctions,
  UpdateBookingDetails,
  UpdateAppointment,
  UpdatePaperwork,
  UpdateAttendance,
  UpdateCancellation,
  UpdateDictation,
  UpdateVideoInfo,
  GetVideoInfo,
  GetOTProcess,
  SendEmail,
  SendOTQuotationEmail,
  Rebook,
  SendReportStatus,
  ViewConsentForm,
  ViewCheckList,
  GenerateVideoLink,
  GetClientHistory,
  ExportSearchResult,
  ViewAppointmentConfirmationCM,
  ViewAppointmentConfirmationSpecialist,
  RescheduleAppointment,
  GetQuotation,
  SaveQuotation,
  SaveQuotationTemplate,
  GetQuotationItemsCost,
  CreateQuotationItemsCost,
  DeleteQuotationItemsCost,
  InitCaseOTFromQuotation,
  GenerateDocuSignConsentForm,
  SyncDocuSignEnvelopStatus,
  GetAppointmentHistory,
  ResendRescheduleAppointmentUpdate,
  CreateUHGCase,
  ValidateUhg,
  SyncUHGAppointment,
  SyncUHGPaperwork,
  ConnectToUHG,
  CancelAppointment,
}
export default API
