import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/disbursements/insuranceClaims'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function SendRequest(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.get(`${BaseRoute}/${id}/sent`)
}
function SendEmail(id, data, isSMS = false) {
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${data}&isSMS=${isSMS}`)
}

function AddOrUpdate(data) {
  return ApiRequest.put(`${BaseRoute}`, data)
}
function GetByObjectId(objectid, fromModule) {
  return ApiRequest.get(`${BaseRoute}/getbyObjectId?objectId=${objectid}&fromModule=${fromModule}`)
}

const API = {
  ...baseFunctions,
  SendRequest,
  SendEmail,
  AddOrUpdate,
  GetByObjectId,
}

export default API
