import cx from 'classnames'
import { Modal as AntdModal } from 'antd'
import './Modal.scss'
import { Flex } from '@components/Layout'
import { AppButton } from '@components/AppComponents'

interface Props {
  open?: boolean
  maskClosable?: boolean
  width?: number | string
  onCancel?: () => void
  title?: React.ReactNode
  footer?: React.ReactNode
  className?: string
  noFooter?: boolean
  children?: React.ReactNode
  bodyStyle?: any
}
function AppModal(props: Props) {
  const renderModalFooter = () => {
    const { footer, onCancel } = props

    if (!footer) {
      return (
        <AppButton onClick={onCancel} rounded color="red">
          Close
        </AppButton>
      )
    }

    return (
      <div onClick={e => e.stopPropagation()}>
        <Flex className="justify-center">{footer}</Flex>
      </div>
    )
  }

  return (
    <AntdModal
      {...props}
      open={props.open}
      closable={false}
      centered
      className={cx('custom-modal', props.className)}
      destroyOnClose
      footer={props.noFooter ? null : renderModalFooter()}
    >
      <div onClick={e => e.stopPropagation()}>{props.children}</div>
    </AntdModal>
  )
}

export default AppModal
