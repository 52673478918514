import TooltipInfo from '@components/TooltipInfo'
import OptionApi from '@services/OptionApi'
import appStore from '@stores/appStore'
import { FROM_MODULE_TYPE } from '@utils/constants'
import { isEmpty } from '@utils/functions'
import { Form, Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { FormLabelAlign } from 'antd/lib/form/interface'
import cx from 'classnames'
import _ from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormContext } from '../AppForm'
import InputHint from '../InputHint'
import './style.scss'

type QueryProvider = {
  provider: (typeof OptionApi)[keyof typeof OptionApi]
  params: any
}

type SourceType =
  | 'USER'
  | 'STAFF'
  | 'CENTRE'
  | 'CASE_MANAGER'
  | 'SPECIALIST'
  | 'COMPANY'
  | 'CLIENT'
  | 'FUNDING_PROVIDER'
  | 'LOOKUP_CONFIG'
  | 'CONTRACTOR'

const fetchData = async (source: SourceType, searchText: string, sourceParams: any) => {
  let requestData = { ...(sourceParams ?? {}), filterText: searchText }
  switch (source) {
    case 'STAFF':
      return await OptionApi.Staff(requestData)
    case 'USER':
      return await OptionApi.User(requestData)
    case 'SPECIALIST':
      return await OptionApi.Specialist(requestData)
    case 'COMPANY':
      return await OptionApi.Company(requestData)
    case 'CASE_MANAGER':
      return await OptionApi.CaseManager(requestData)
    case 'CLIENT':
      return await OptionApi.Client(requestData)
    case 'FUNDING_PROVIDER':
      return await OptionApi.FundingProvider(requestData)
    case 'LOOKUP_CONFIG':
      return await OptionApi.LookupConfig(requestData)
    case 'CENTRE':
      return await OptionApi.Centre(requestData)
    case 'CONTRACTOR':
      return await OptionApi.Contractor(requestData)
    default:
      return null
  }
}

type SelectDataSourceProps = {
  source?: SourceType
  searchText?: string
  sourceParams?: any
}

type Props = {
  className?: string
  formItemClassName?: string
  labelClassName?: string
  name?: string
  label?: JSX.Element | string
  suffixLabel?: React.ReactElement
  rules?: any[]
  required?: boolean
  labelAlign?: string
  labelCol?: any
  wrapperCol?: any
  size?: string
  disabled?: boolean
  options?: any[]
  optionDefault?: any
  value?: any
  hint?: string
  entityHint?: FROM_MODULE_TYPE
  detailTooltip?: any
  onChange?: (event, value?, option?) => void
  onPressEnter?: (e) => void
  onSearch?: (e) => void
  [x: string]: any
  textKey?: string
  valueKey?: string
  queryProvider?: QueryProvider
  optionHover?: boolean
  validateStatus?: any
  customItemDropdown?: any
  groupBy?: string
  groupLabel?: string
  displayOption?: (option: any) => any
} & SelectDataSourceProps

export const AppSelectInput = observer(
  ({
    className = '',
    formItemClassName = '',
    size = 'middle',
    labelAlign = 'left',
    labelClassName = '',
    labelCol, // read ant's document, please
    wrapperCol, // read ant's document, please
    label,
    suffixLabel,
    name,
    mode,
    rounded = false,
    prefixLabel = '',
    options = [],
    optionDefault = null,
    textKey = 'displayName',
    valueKey = 'id',
    value = [],
    required = false,
    allowClear = true,
    showArrow = true, // read ant's document, please
    optionFilterProp = 'children', // read ant's document, please
    hint,
    entityHint,
    onChange,
    onSearch,
    validateStatus,
    help,
    detailTooltip,
    tooltip,
    disabled = false,
    optionHover = false,
    customItemDropdown = null,
    groupBy = null,
    groupLabel = null,
    itemClassName = '',
    fieldName,
    source,
    sourceParams,
    displayOption,
    ...props
  }: Props) => {
    const formContext = useContext(FormContext)
    const [formWidth, setFormWidth] = useState(0)

    const inputId = name ? `input-${name}` : `input-${_.uniqueId()}`

    const [sourceData, setSourceData] = useState<any[]>([])

    const [searchText, setSearchText] = useState('')

    const [selectedItems, setSelectedItems] = useState<any>(null)

    const [dropdownVisible, setDropdownVisible] = useState(false)

    const handleFetchData = async (source, searchText, sourceParams) => {
      const res = await fetchData(source, searchText, sourceParams)
      setSourceData(res?.data?.items ?? [])
    }

    const debouncedFetchData = useCallback(
      _.debounce(async (source, searchText, sourceParams) => {
        await handleFetchData(source, searchText, sourceParams)
      }, 500),
      [],
    )

    useEffect(() => {
      if (source) {
        if (dropdownVisible) {
          const callApi = async () => {
            await debouncedFetchData(source, searchText, sourceParams)
          }
          callApi()
        }
      }
    }, [searchText])

    useEffect(() => {
      const inputElement = document.getElementById(inputId)
      setFormWidth(inputElement?.offsetWidth ?? 0)
      // handleFetchData(source, searchText, sourceParams)
    }, [])

    const handleOnSearch = (filterText: string) => {
      if (source) {
        setSearchText(filterText)
      }
      onSearch && onSearch(filterText)
    }

    const inputOptions = useMemo(() => {
      let items = (source ? sourceData : options) ?? []

      let additionalOptions = []
      if (optionDefault) {
        if (Array.isArray(optionDefault)) {
          additionalOptions = [...additionalOptions, ...optionDefault]
        } else {
          additionalOptions.push(optionDefault)
        }
      }

      if (selectedItems) {
        if (Array.isArray(selectedItems)) {
          additionalOptions = [...additionalOptions, ...selectedItems]
        } else {
          additionalOptions.push(selectedItems)
        }
      }

      let uniqItems = _.uniqBy<any>([...additionalOptions, ...(items || [])], 'id')
      console.log(toJS(uniqItems))
      items = uniqItems.filter(i => i?.id !== null && i?.id !== '' && i?.id !== undefined)

      if (displayOption) {
        items = items.map(i => displayOption(i))
      }
      return items
    }, [sourceData, options, optionDefault, selectedItems])

    const handleOnChange = useCallback(
      (value, option) => {
        let optionData: any = null
        if (Array.isArray(option)) {
          optionData = option.map(selectOption => {
            const item = inputOptions.find(i => i && i[valueKey] == selectOption.value)
            return item ? toJS(item) : null
          })
        } else {
          const item = inputOptions.find(i => i && i[valueKey] == option?.value)
          optionData = item ? toJS(item) : null
        }
        if (onChange) {
          if (name) {
            onChange(name, value, optionData)
          } else {
            onChange(value, optionData)
          }
        } else {
          if (name) {
            formContext.onFieldChange(name, value)
          }
        }
        setSelectedItems(optionData)
      },
      [formContext, name, onChange, inputOptions, valueKey],
    )

    const [isShowingTooltip, setIsShowingTooltip] = useState(false)

    const inputRules = useMemo(() => {
      if (!required) {
        return []
      }
      return [{ required: true, message: 'This field is required' }]
    }, [required])

    const handleFilterSearch = (searchTerm, option) => {
      const originOption = inputOptions.find(i => i[valueKey] == option?.value)
      if (!originOption) {
        return false
      }

      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(escapedSearchTerm, 'i') // 'i' for case-insensitive search

      const optionLabel = getOptionLabel(originOption)
      const isMatch = regex.test(optionLabel) //1613. dev - MLP - Value List

      return isMatch
    }

    const enableDetailTooltip = appStore.isAdminStaff || appStore.isStaff

    const getItemDropdown = opt => {
      if (customItemDropdown && typeof customItemDropdown === 'function') {
        return customItemDropdown(opt)
      }
      return (
        <>
          <span title={opt?.title}>
            {!isEmpty(opt['itemIndex']) ? `ID: ${opt['itemIndex']} - ` : ''} {opt[textKey]}{' '}
            {opt.isActive === false ? ` (InActive)` : ''}
          </span>
          {opt['descriptionOption'] ? (
            <span className="flex items-center ml-1">
              <TooltipInfo text={opt['descriptionOption']} />
            </span>
          ) : (
            <></>
          )}
        </>
      )
    }

    const shouldGroup = groupBy != null && groupLabel != null

    const groupedOptions: Record<string, { label: string; options: Array<any> }> | null = useMemo(() => {
      return shouldGroup
        ? inputOptions.reduce((groups, opt) => {
            const groupKey = opt[groupBy]
            if (!groups[groupKey]) {
              groups[groupKey] = {
                label: opt[groupLabel],
                options: [],
              }
            }
            groups[groupKey].options.push(opt)
            return groups
          }, {})
        : []
    }, [inputOptions])

    const getOptionLabel = opt => {
      let label =
        typeof opt[textKey] === 'string'
          ? `${!isEmpty(opt['itemIndex']) ? `ID: ${opt['itemIndex']} - ` : ''} ${prefixLabel + opt[textKey]}${
              opt.isActive === false ? ` (InActive)` : ''
            }`
          : opt[textKey]

      return label
    }

    const optionItem = opt => {
      let label = getOptionLabel(opt)
      return (
        <Select.Option
          value={opt[valueKey]}
          disabled={opt.disabled}
          label={
            <div className={cx(['flex', itemClassName, opt.itemClassName])}>
              {label?.length * 8 + 20 > formWidth && optionHover ? (
                <div className={cx([mode !== 'multiple' && 'max-w-[90%]'])}>
                  <TooltipInfo
                    text={label}
                    tooltipIcon={<span style={{ overflow: 'hidden', display: 'block', width: '100%' }}>{label}</span>}
                    placement="topLeft"
                  />
                </div>
              ) : (
                <div className={cx([mode !== 'multiple' && 'max-w-[90%]'])}>
                  <span style={{ overflow: 'hidden', display: 'block', width: '100%' }}>{label}</span>
                </div>
              )}
              {tooltip}
              {enableDetailTooltip && (detailTooltip || opt['descriptionOption']) && (
                <span className="flex items-center ml-1">
                  <TooltipInfo
                    text={
                      typeof detailTooltip === 'function'
                        ? detailTooltip(opt)
                        : detailTooltip || opt['descriptionOption']
                    }
                    onOpenChange={visible => {
                      setIsShowingTooltip(visible)
                    }}
                  />
                </span>
              )}
            </div>
          }
          className={opt.itemClassName}
        >
          {getItemDropdown(opt)}
        </Select.Option>
      )
    }

    const renderedOptions = shouldGroup
      ? Object.entries(groupedOptions)?.map(([groupKey, group]) => {
          return (
            <Select.OptGroup
              key={groupKey}
              label={<div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>{group.label}</div>}
            >
              {group.options.map((opt, i) => optionItem(opt))}
            </Select.OptGroup>
          )
        })
      : inputOptions.map((opt, i) => optionItem(opt))

    // Trigger data load when dropdown is visible
    const handleDropdownVisibleChange = visible => {
      if (visible && !dropdownVisible) {
        console.log('fetching data...')
        handleFetchData(source, searchText, sourceParams) // Load data only when dropdown is shown and options are empty

        setDropdownVisible(visible)
      }
    }

    return (
      <Form.Item
        name={name ?? fieldName}
        required={required}
        className={cx([formItemClassName])}
        label={
          label ? (
            <span className="w-full flex items-center gap-2">
              <span onClick={e => e.preventDefault()} className={cx([labelClassName, 'form-item-label shrink-0'])}>
                {label}
              </span>
              <span className="flex items-center">
                {suffixLabel} <InputHint text={hint} />
              </span>
            </span>
          ) : (
            ''
          )
        }
        labelAlign={labelAlign as FormLabelAlign}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        rules={inputRules}
        validateStatus={validateStatus}
        help={help}
      >
        <Select
          className={cx([className, rounded ? 'rounded-full' : '', isShowingTooltip ? 'show-tooltip' : ''])}
          size={size as SizeType}
          mode={mode}
          allowClear={allowClear}
          showArrow={showArrow}
          value={value}
          onChange={handleOnChange}
          onSearch={handleOnSearch}
          optionLabelProp="label"
          optionFilterProp={optionFilterProp}
          showSearch
          filterOption={handleFilterSearch}
          disabled={disabled || (mode === 'multiple' && typeof detailTooltip === 'function' ? false : isShowingTooltip)}
          id={inputId}
          placeholder={props.placeholder ?? (mode === 'multiple' ? 'Select multiple values' : '')}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          {...props}
        >
          {renderedOptions}
        </Select>
        {/* {disabled && (
        <ReadonlyInputWrapper
          className={cx([className, rounded ? 'rounded-full' : ''])}
          id={inputId}
          size={size as SizeType}
          type="text"
          value={selectedOptionText}
          suffix={detailTooltip ? <TooltipInfo text={detailTooltip} /> : null}
          readOnly
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          }}
        />
      )} */}
      </Form.Item>
    )
  },
)
