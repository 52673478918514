import {
  defaultColumns as defaultColumns,
  columnsWithReferenceTable as columnsWithReferenceTable,
} from '@components/ModalRecentChange'
import { makeAutoObservable } from 'mobx'

class ModalManagerStore {
  constructor() {
    makeAutoObservable(this)
  }

  recentChange = {
    open: false,
    propertyName: null,
    propertyReferenceTable: null,
    optionsValue: null,
    entityId: null,
    columns: [],
    modalWidth: null,
  }

  openRecentChange = ({
    propertyName,
    propertyReferenceTable = null,
    optionsValue = null,
    entityId,
    customColumns = null,
    modalWidth = null,
  }) => {
    this.recentChange.open = true
    this.recentChange.propertyName = propertyName
    this.recentChange.propertyReferenceTable = propertyReferenceTable
    this.recentChange.entityId = entityId
    this.recentChange.modalWidth = modalWidth
    this.recentChange.optionsValue = optionsValue

    if (propertyReferenceTable) {
      this.recentChange.columns = columnsWithReferenceTable
    } else {
      this.recentChange.columns = defaultColumns
    }
    if (customColumns) {
      this.recentChange.columns = customColumns
    }
  }

  closeRecentChange = () => {
    this.recentChange.open = false
    this.recentChange.propertyName = null
    this.recentChange.propertyReferenceTable = null
    this.recentChange.optionsValue = null
    this.recentChange.entityId = null
    // this.recentChange.columns = []
  }
}

export const ModalManager = new ModalManagerStore()
