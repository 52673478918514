import Validator from 'validatorjs'
import en from 'validatorjs/src/lang/en'

Validator.setMessages('en', en)

// const Validator = {}
// Validator.register(
//   'phone',
//   (value, requirement, attribute) => {
//     return true
//     // TODO: validation rule is different for country. Check it later. Remove it for now
//     // return !value || /^[0-9]{2}-{0,1}[0-9]{4}-{0,1}[0-9]{4}$/.test(value)
//   },
//   'The :attribute phone number is not in the format XX-XXXX-XXXX.',
// )

export default Validator
