import { ApiRequest } from '../baseService'

const BaseRoute = 'app/pms'

const GetMatters = (params = {}) => {
  return ApiRequest.get(`${BaseRoute}/matters`, { params: params })
}

const SyncStatues = referenceIds => {
  return ApiRequest.post(`${BaseRoute}/sync-statuses`, {
    referenceIds,
  })
}

const GetMatterDetail = referenceId => {
  return ApiRequest.get(`${BaseRoute}/matters/${referenceId}`)
}

const API = {
  GetMatters,
  SyncStatues,
  GetMatterDetail,
}
export default API
