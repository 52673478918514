import { ApiRequest } from '../baseService'

const UpdateFromIME = (params, id) => {
  return ApiRequest.put(`/app/assessments/${id}/paperwork`, params)
}
const UpdateFromSupp = (params, id) => {
  return ApiRequest.put(`/app/supplementary/${id}/paperwork`, params)
}

const UpdateFromMR = (params, id) => {
  return ApiRequest.put(`/app/clinical-records/${id}/paperwork`, params)
}

const API = {
  UpdateFromIME,
  UpdateFromSupp,
  UpdateFromMR,
}
export default API
