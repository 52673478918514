import './styles.scss'
import cx from 'classnames'
import { observer } from 'mobx-react'
import dashboardUI from '@stores/dashboard'
import ContentItem from '../ContentItem/ContentItem'
import * as Sentry from '@sentry/react'
import { AppButton } from '@components/AppComponents'
import dashboardStore from '@stores/dashboard'
import { DashboardRoutes } from 'routes-setting/dashboardRoute'

function ErrorFallback({ error, componentStack, resetError }) {
  const isNewVersionDeployed = componentStack?.startsWith?.('\n    at InnerLoadable2')

  if (isNewVersionDeployed) {
    return (
      <div>
        <div className="text-center mt-20">
          <h1 className="error-title">
            Woops! <br />
            Something went wrong :(
          </h1>
          <h2 className="text-xl">
            A new software update has just been deployed. Please reload the page to continue using the software.
            Apologies for the inconvenience.
          </h2>

          <AppButton
            color="blue"
            className="mt-4"
            onClick={() => {
              window.location.reload()
            }}
          >
            Refresh App
          </AppButton>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="text-center mt-20">
        <h1 className="error-title">
          Woops! <br />
          Something went wrong :(
        </h1>
        <h2 className="text-base">
          Please try refreshing the page to see if it works. Apologies for the inconvenience.
        </h2>
        <h2 className="text-base">We will be fixing it soon!</h2>
        <br />
        <div>
          <div>Please help to notify us by email: help@kawaconn.com</div>
          <AppButton color="red" className="mt-4">
            <a className="block btn" href="mailto:help@kawaconn.com" target="_blank" rel="noreferrer">
              Notify Us Now
            </a>
          </AppButton>
        </div>
      </div>
    </div>
  )
}

const DashboardContent = () => {
  const {
    tabs,
    sidebar: { collapsed: sidebarAutoHide },
  } = dashboardUI

  const onErrorHandler = (error: Error, componentStack: any, resetError: any) => {
    // const isNewVersionDeployed = componentStack?.startsWith?.('\n    at InnerLoadable2')
  }
  return (
    <div id="dashboard-content" className={cx('DashboardContent', { sidebarAutoHide })}>
      {tabs.map(({ path, route, visited, id }) => {
        const routeSetting = DashboardRoutes.find(i => i.path == route)
        if (routeSetting && dashboardStore.isValidRoute(routeSetting) === false) {
          return null
        }

        return (
          <div key={id} className={cx('ContentItem', id !== dashboardUI.currentTabId ? 'inActive' : 'active')}>
            {visited && (
              <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog onError={onErrorHandler}>
                <ContentItem tabId={id} path={path} route={route} />
              </Sentry.ErrorBoundary>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default observer(DashboardContent)
