import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/user-group'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Disable(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

const API = { ...baseFunctions, Disable }
export default API
