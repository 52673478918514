import { action, makeObservable, observable } from 'mobx'
import * as util from '@utils/functions'
export class BaseStore {
  #defaultProps = {}

  formRef: any = {}
  initStore = (defaultProps = {}, actions = {}) => {
    this.#defaultProps = defaultProps
    const store = this
    const observableConfig = {}

    this.#setState(defaultProps)

    Object.keys(defaultProps).forEach(key => {
      observableConfig[key] = observable
    })

    Object.assign(observableConfig, actions)

    makeObservable(store, observableConfig)
  }

  #setState = props => {
    const store = this
    const cloneProps = JSON.parse(JSON.stringify(props))
    Object.keys(props).forEach(key => {
      store[key] = cloneProps[key]
    })
  }

  reset = () => {
    if (this.#defaultProps) {
      this.#setState(this.#defaultProps)
    }
  }

  setFieldsValue = (data = {}) => {
    Object.keys(data).forEach(
      action(key => {
        this[key] = data[key]
      }),
    )
  }

  updateURLParams = (params = {}) => {
    util.updateURLParams(params)
  }

  getURLParams = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(queryParams)
    return params
  }
}
