import TooltipInfo from '@components/TooltipInfo'
import { Checkbox, Radio } from 'antd'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { useCallback } from 'react'

export const AppInputGroup = observer(
  ({
    className = null,
    classNameInput = null,
    value = null,
    onChange = (name, value?) => {},
    options = [],
    textKey = 'displayName',
    valueKey = 'id',
    type = 'radio',
    disabled = false,
    name = null,
    onClick = item => {},
    classNameTextInput = 'whitespace-nowrap',
  }) => {
    const handleChange = useCallback(
      event => {
        const value = event.target ? event.target.value : event
        if (name) {
          onChange(name, value)
        } else {
          onChange(value)
        }
      },
      [name, onChange],
    )
    return (
      <>
        {type === 'radio' ? (
          <Radio.Group className={className} value={value} disabled={disabled} onChange={handleChange}>
            {options
              .filter(x => x && !x.hideItem)
              .map((i, index) => (
                <Radio
                  className={cx([classNameInput, i.optionIsActive ? 'text-green-300' : ''])}
                  key={index}
                  value={i[valueKey]}
                  disabled={disabled || i.disableItem}
                  onClick={e => onClick(i)}
                >
                  <span className={cx([i.itemClassName, i.optionIsActive ? 'text-green-300' : '', classNameTextInput])}>
                    {i[textKey]}
                    {i['descriptionOption'] ? (
                      <div style={{ paddingLeft: '5px', paddingTop: '2px' }}>
                        <TooltipInfo text={i['descriptionOption']} />
                      </div>
                    ) : null}
                  </span>
                </Radio>
              ))}
          </Radio.Group>
        ) : (
          <Checkbox.Group className={className} disabled={disabled} value={value} onChange={handleChange}>
            {options
              .filter(x => x && !x.hideItem)
              .map((i, index) => (
                <Checkbox
                  className={cx([classNameInput, i.optionIsActive ? 'text-green-300' : ''])}
                  key={index}
                  onChange={e => onClick(e.target.value)}
                  disabled={i.disableItem}
                  value={i[valueKey]}
                  onClick={e => onClick(i)}
                >
                  <span className={cx([i.itemClassName, i.optionIsActive ? 'text-green-300' : '', classNameTextInput])}>
                    {i[textKey]}
                  </span>
                </Checkbox>
              ))}
          </Checkbox.Group>
        )}
      </>
    )
  },
)
