import TooltipInfo from '@components/TooltipInfo'
import appStore from '@stores/appStore'
import { observer } from 'mobx-react'

const ENABLE_HINT = process.env.VITE_ENABLE_HINT || false
function InputHint({ text }) {
  const showHint = ENABLE_HINT && appStore.enableHint && text
  if (!showHint) return null
  return <TooltipInfo text={'Hint: ' + text} iconColor="text-yellow-400" />
}

export default observer(InputHint)
