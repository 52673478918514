import { ApiService } from '@services/ApiService'
import { action, makeAutoObservable, makeObservable, observable } from 'mobx'

import { showNotification } from '@utils/errors'
import _ from 'lodash'
import { updateURLParams } from '@utils/functions'

class VideoSessionStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = true
  data = {
    appointmentTimeZone: null,
    appointmentDateFrom: null,
    appointmentDateTo: null,
    title: null,
    caseStatus: null,
    serviceType: null,
    objectId: null,
    videoAppType: null,
    specialistInfo: null,
    clientInfo: null,
    interpreterInfo: null,
    caseManagerInfo: null,
    staffInfo: null,

    videoLink: null,
    alternativeVideoLink: null,
    alternativeMeetingId: null,
    alternativeMeetingPasscode: null,
  }

  dataSession = null
  open = false
  modalParams = {}

  fetchVideoInfo = (assessmentId = 0) => {
    this.loading = true

    if (!assessmentId) return

    ApiService.VideoSession.GetVideoInfo(assessmentId)
      .then(res => {
        this.loading = false
        this.data = res.data
      })
      .catch(() => {
        this.loading = false
      })
  }

  getVideoSession = async (token, redirectFromMAG) => {
    this.loading = true

    if (!token) return

    try {
      const res = await ApiService.VideoSession.ViewVideo({ token, redirectFromMAG })
      return res.data
    } finally {
      this.loading = false
    }
  }

  sendMail = (id, type, isSMS = false) => {
    ApiService.Assessment.SendEmail(id, type, isSMS)
      .then(() =>
        showNotification(
          'Success',
          isSMS ? 'Video link SMS successfully' : 'Video link emailed successfully',
          'success',
        ),
      )
      .catch(() => {})
  }

  toggleModal = (isOpen, params = {}) => {
    action(() => {
      this.open = isOpen
      this.modalParams = params
    })
  }
  resetStore = () => {
    this.data = null
    this.loading = true
  }
}

export default new VideoSessionStore()
