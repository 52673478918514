import * as Sentry from '@sentry/react'
import appStore from '@stores/appStore'
import dashboardStore from '@stores/dashboard'
import { notification } from 'antd'
import EmptyLayout from 'layout/EmptyLayout'
import Dashboard from 'layout/MainLayout'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EmptyRoutes } from 'routes-setting/emptyRoute'
import { isMatchPathname } from 'routes-setting/functions'
import Anonymous from './pages/anonymous/Anonymous'
import { AnonymousRoutes } from './pages/anonymous/routes'
import { DashboardRoutes } from './routes-setting/dashboardRoute'
import router from './stores/router'

const App = () => {
  const [prevPath, setPrevPath] = useState(null)
  const history = useHistory()

  const { pathname } = router.location

  useEffect(() => {
    setPrevPath(location.pathname)
    appStore.initTheme()
    return history.listen(location => {
      if (history.action === 'POP') {
        if (prevPath !== router.location.pathname) {
          setPrevPath(router.location.pathname)
          const location = router.location
          dashboardStore.open(location.pathname + location.search)
          notification.destroy()
          window.scrollTo(0, 0)
        }
      }
    })
  }, [])

  if (isMatchPathname(pathname, EmptyRoutes)) {
    return <EmptyLayout />
  } else if (isMatchPathname(pathname, AnonymousRoutes)) {
    return <Anonymous />
  } else {
    if (isMatchPathname(pathname, DashboardRoutes)) {
      return <Dashboard />
    } else {
      ;(window as any).location = '/not-found'
      return null
    }
  }
}

export default Sentry.withProfiler(observer(App))
