import { AppTable } from '@components/table'
import appStore from '@stores/appStore'
import { observer } from 'mobx-react'
import store from './store'
import AppDateInfo from '@components/AppComponents/AppDateInfo'

// const initContextMenu = []

const ClientActiveCountTable = () => {
  const columns = [
    {
      title: 'Tenant',
      accessor: 'tenant',
      Cell: (_, record) => record.tenant?.name,
      disabled: !appStore.isHostTenant,
    },
    {
      title: 'Month',
      Cell: (_, record) => <AppDateInfo date={record.applyForMonth} format="MM/YYYY" />,
    },
    {
      title: 'Number Active Clients',
      Cell: (_, record) => {
        return record.numberOfClient
      },
    },
  ]
  // const [contextMenu] = useState(initContextMenu)
  const { data } = store

  return (
    <AppTable
      tableKey="client-active-count"
      hasPaginate={false}
      hasColumnsSelection
      columns={columns}
      // contextMenu={contextMenu}
      data={data}
      totalCount={data.length}
      onFilterChange={store.updateFilter}
      loading={store.loading}
    />
  )
}

export default observer(ClientActiveCountTable)
