import AppIcon from '@components/AppIcon'
import PaginateTable from '@components/PaginationTable'
import LocalStorageManager from '@services/storage/LocalStorageManager'
import { Dropdown, Menu, Spin } from 'antd'
import { ReactComponent as MoreVerticalIcon } from 'assets/svg/more-vertical.svg'
import cx from 'classnames'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ContentLoader from 'react-content-loader'
import TableSetting from './TableSetting'
import { number } from 'prop-types'

const COL_WIDTH_DEFAULT = 200
const COL_PADDING_RIGHT_DEFAULT = 15
const COL_CONTEXT_WIDTH_DEFAULT = 25

function saveColumnsSetting(tableKey, columns: TableColumnProps<any>[]) {
  LocalStorageManager.saveColumnSettings(
    tableKey,
    columns.map(i => {
      return {
        name: i.name,
        hidden: i.hidden,
      }
    }),
  )
}

function savePageSize(tableKey, pageSize) {
  const pageSizeSettings = LocalStorageManager.localSetting.pageSizes
  pageSizeSettings[tableKey] = pageSize
  LocalStorageManager.saveLocalSetting()
}

function getFilterData({ pageSize, skipCount }) {
  return {
    maxResultCount: pageSize,
    skipCount: skipCount,
  }
}

// export type ColumnAlignment = 'left' | 'right' | 'center'

export interface TableColumnProps<T> {
  name?: string
  title?: string | React.ReactNode
  accessor?: string
  hidden?: boolean
  enableSort?: boolean
  width?: number
  align?: string
  disabled?: boolean
  sortField?: string
  Cell?: (valueObject: { value: any }, recordItem?: T, rowIndex?: number) => void
  sortOrder?: 'asc' | 'desc'
  headerClassName?: string
  render?: (record?: any) => any
  // [key: string]: any
  maxWidth?: number
  bodyClassName?: string
  alignCenter?: boolean
  prefix?: string
  prefixAfter?: string
}

interface ContextMenuItemProps {
  title?: string
  render?: (title?: string, record?: any) => any
  visible?: boolean | ((record: any) => boolean)
  action: (record: any, index: any) => void
  isDivider?: boolean
  rightContent?: (record: any) => any
}

export interface TableProps<T = any> {
  columns: TableColumnProps<T>[]
  enableCache?: boolean

  // extra classes for Row when rendering records
  rowClass?: Function | string
  data: T[]

  hasColumnsSelection?: boolean
  contextMenu?: ContextMenuItemProps[]
  contextMenuComponent?: React.ReactNode
  skipCount?: number
  pageSize?: number
  defaultPageSize?: number
  totalCount: number
  className?: string
  tableKey: string
  onFilterChange?: (filterDataChange: any) => void
  hasPaginate?: boolean
  loading?: boolean
  hasEmptyTitle?: boolean
  borderTable?: boolean
  contentPlaceholderLoading?: boolean
  modalMode?: boolean
  hideCol?: string[]
  sorting?: string
}

function AppTable<T = any>({
  data = [] as any[],
  rowClass = () => {},
  hasColumnsSelection,
  contextMenu = [],
  contextMenuComponent,
  skipCount = 0,
  pageSize = window.APP_CONFIG.maxResultCount,
  defaultPageSize = 20,
  totalCount,
  className,
  tableKey,
  onFilterChange = filterDataChange => {},
  hasPaginate = true,
  loading = false,
  hasEmptyTitle = false,
  borderTable = false,
  contentPlaceholderLoading = true,
  modalMode = false,
  hideCol = [],
  ...props
}: Readonly<TableProps<T>>) {
  const [paging, setPaging] = useState({ pageSize, skipCount })

  useEffect(() => {
    if (!tableKey) return
    if (!props.enableCache) return

    LocalStorageManager.getTableColumns(tableKey).then(savedColumns => {
      if (!savedColumns) return

      // Filter out columns that are not in enabledColumns
      const validSavedColumns = savedColumns.filter(savedCol =>
        enabledColumns.some(enabledCol => enabledCol.name === savedCol.name),
      )

      if (validSavedColumns.length === 0) return

      // Add new columns from enabledColumns
      const newColumns = props.columns.filter(
        enabledCol => !validSavedColumns.some(savedCol => savedCol.name === enabledCol.name),
      )

      // Combine saved columns with new columns, maintaining order
      const updatedColumns = [
        ...validSavedColumns.map(savedCol => {
          const column = props.columns.find(col => col.name === savedCol.name)
          return { ...column, hidden: savedCol.hidden }
        }),
        ...newColumns,
      ]

      setColumns(updatedColumns)
    })
  }, [props.columns])

  const [columns, setColumns] = useState(_.cloneDeep(props.columns))

  const [tableReady, setTableReady] = useState(false)

  const columnsInitial = useRef(props.columns)

  const enabledColumns = useMemo(() => {
    return columns.filter(item => item && !item.disabled)
  }, [columns, hideCol])

  const visibleColumns = useMemo(() => {
    return enabledColumns
      .filter(col => {
        if (typeof col.title === 'string') {
          return !hideCol.includes(col.title)
        }
        return true
      })
      .filter(item => item && !item.hidden && !item.disabled)
  }, [enabledColumns, hideCol])

  useEffect(() => {
    if (loading === false) {
      setTableReady(true)
    }
  }, [loading])

  useEffect(() => {
    if (skipCount !== paging.skipCount) {
      setPaging(e => ({ ...e, skipCount }))
    }
  }, [skipCount])

  const changePageSize = useCallback(
    newPageSize => {
      const newPaging = {
        skipCount: 0,
        pageSize: newPageSize,
      }
      setPaging(newPaging)

      if (tableKey) {
        savePageSize(tableKey, newPageSize)
      }

      onFilterChange(getFilterData(newPaging))
    },
    // eslint-disable-next-line
    [onFilterChange, tableKey],
  )

  const changePage = useCallback(
    newPage => {
      const newPaging = {
        ...paging,
        skipCount: (newPage - 1) * paging.pageSize,
      }
      setPaging(newPaging)
      onFilterChange(getFilterData(newPaging))
    },
    // eslint-disable-next-line
    [paging, onFilterChange],
  )

  const [sorting, setSorting] = useState(props.sorting)

  const changeSort = (sortCol: TableColumnProps<any>) => {
    const sortField = sortCol.sortField || sortCol.accessor
    let newSortOrder = 'asc'
    if (sorting) {
      const [currentSortField, currentSortOrder] = sorting?.toLowerCase()?.split(' ')
      if (currentSortField === sortField?.toLocaleLowerCase()) {
        if (currentSortOrder === 'asc') {
          newSortOrder = 'desc'
        }
      }
    }

    const newSorting = `${sortField} ${newSortOrder}`
    setSorting(newSorting)

    onFilterChange({ sorting: newSorting })
  }

  const changeColumn = (updateColumns: TableColumnProps<T>[]) => {
    setColumns(_.cloneDeep(updateColumns))

    if (tableKey) {
      saveColumnsSetting(tableKey, updateColumns)
    }
  }

  const setDefaultColumns = () => {
    const defaultColumn = columnsInitial.current

    setColumns(_.cloneDeep(defaultColumn))

    if (tableKey && props.enableCache) {
      saveColumnsSetting(tableKey, defaultColumn)
    }
  }

  const [isOpenColumnSetting, setIsOpenColumnSetting] = useState(false)
  const close = () => {
    setIsOpenColumnSetting(false)
  }
  const isSpinning = (tableReady && loading) || (!contentPlaceholderLoading && loading)

  const showContextMenu = (contextMenu && contextMenu.length) || hasColumnsSelection

  return (
    <Spin spinning={isSpinning} wrapperClassName="table-container">
      <div className={cx(['max-w-full mb-2', modalMode ? '' : 'overflow-auto', className])}>
        <table className={cx(['w-full relative'])} data-name={tableKey}>
          <thead className={cx([borderTable && 'border-y border-r border-grey-200'])}>
            <tr>
              {showContextMenu && (
                <th
                  className={cx([
                    'top-0 sticky bg-white align-bottom left-0 z-20',
                    borderTable && 'border-l border-y border-grey-200',
                  ])}
                  style={{ width: 30, position: 'sticky', left: 0 }}
                >
                  {hasColumnsSelection && (
                    <div className="text-left relative bg-white">
                      <Dropdown
                        overlayClassName="rounded-md border border-grey-200"
                        trigger={['click']}
                        open={isOpenColumnSetting}
                        onOpenChange={(visible, info) => {
                          if (info.source === 'trigger') {
                            setIsOpenColumnSetting(visible)
                          }
                        }}
                        menu={{
                          items: [
                            {
                              key: 'columns-selection',
                              label: (
                                <TableSetting
                                  key={'table-setting'}
                                  columns={columns.filter(i => !i.disabled)}
                                  onChange={changeColumn}
                                  setDefaultColumns={setDefaultColumns}
                                  onClose={() => setIsOpenColumnSetting(false)}
                                />
                              ),
                            },
                          ],
                        }}
                      >
                        <button
                          type="button"
                          className="pb-3 rounded-full"
                          title="Columns Selection"
                          onClick={() => {
                            setIsOpenColumnSetting(!isOpenColumnSetting)
                          }}
                        >
                          <MoreVerticalIcon stroke="#111" height="22" />
                        </button>
                      </Dropdown>
                    </div>
                  )}
                </th>
              )}

              {visibleColumns.map((col, colIndex) => {
                const sortField = col.sortField || col.accessor
                let sortOrder = undefined
                if (sorting) {
                  const [currentSortField, currentSortOrder] = sorting?.toLowerCase()?.split(' ')
                  if (currentSortField === sortField?.toLowerCase()) {
                    sortOrder = currentSortOrder
                  }
                }
                return (
                  <th
                    className={cx([
                      'top-0 sticky z-10 bg-white align-bottom',
                      borderTable && 'border-l  border-grey-200',
                    ])}
                    key={`header-${colIndex}`}
                    style={{ width: col.width ?? 'auto' }}
                  >
                    <div
                      className={cx([
                        'py-3 px-6 text-left whitespace-nowrap',
                        col.headerClassName,
                        col.enableSort && 'hover:bg-grey-100 cursor-pointer',
                        'text-' + (col.align ? col.align : 'left'),
                      ])}
                      style={{ width: col.width ?? 'auto' }}
                    >
                      {col.enableSort ? (
                        <ThSortWrap
                          width={col.width}
                          align={col.align}
                          title={col.title}
                          sortOrder={sortOrder}
                          onChangeSort={() => changeSort(col)}
                        />
                      ) : col.render ? (
                        col.render(col)
                      ) : (
                        col.title
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className={cx([borderTable && 'border-r  border-grey-200'])}>
            {loading && contentPlaceholderLoading && !tableReady ? (
              <tr>
                <td colSpan={visibleColumns.length}>
                  <ContentPlaceholder
                    pageSize={pageSize}
                    columns={visibleColumns}
                    hasContextMenu={contextMenu && contextMenu.length > 0}
                  />
                </td>
              </tr>
            ) : (
              data?.map((row, i) => (
                <tr
                  key={`body-${i}`}
                  className={
                    rowClass !== null ? (typeof rowClass === 'function' ? cx(rowClass(row, i)) : rowClass) : null
                  }
                >
                  {showContextMenu && (
                    <td
                      className={cx([
                        'border-b border-grey-200 left-0 z-10 bg-white',
                        borderTable && 'border-l',
                        'sticky left-0', // Added sticky positioning
                      ])}
                    >
                      {!(row as any)?.hideContextMenu && contextMenu && contextMenu.length > 0 && (
                        <Dropdown
                          trigger={['click']}
                          overlay={contextMenuComponent || <ContextMenu data={contextMenu} record={row} rowIndex={i} />}
                        >
                          <button className="rounded-full">
                            <MoreVerticalIcon stroke="#111" height="20" />
                          </button>
                        </Dropdown>
                      )}
                    </td>
                  )}
                  {visibleColumns.map((col, i2) => {
                    return (
                      <td
                        style={{ width: col?.width }}
                        className={cx([
                          'py-3 px-6 border-b border-grey-200',
                          col.bodyClassName,
                          col.alignCenter && 'text-center',
                          // col.width && 'truncate',
                          'text-' + (col.align ? col.align : 'left'),
                          borderTable && 'border-l',
                          col.width ? 'whitespace-break-spaces' : 'whitespace-nowrap',
                        ])}
                        key={`column-${i2}`}
                      >
                        {col.prefix ? `${col.prefix} ` : ''}
                        {col.Cell
                          ? col.Cell({ value: col.accessor ? row[col.accessor] : '' }, row, i)
                          : col.accessor
                          ? row[col.accessor]
                          : ''}
                        {col.prefixAfter ? `${col.prefixAfter} ` : ''}
                      </td>
                    )
                  })}
                  {hasColumnsSelection && <td className="border-b border-grey-200"></td>}
                </tr>
              ))
            )}
          </tbody>
        </table>
        {hasEmptyTitle && data.length === 0 && (
          <div className="flex justify-center">
            <span className="p-14 text-center text-[#ccc] text-xl uppercase">No data found</span>
          </div>
        )}
      </div>

      {hasPaginate && (
        <PaginateTable
          onChangePage={changePage}
          skipCount={paging.skipCount}
          pageSize={paging.pageSize}
          defaultPageSize={defaultPageSize}
          totalCount={totalCount}
          onChangePageSize={changePageSize}
        />
      )}
    </Spin>
  )
}

function ContentPlaceholder({ pageSize, hasContextMenu, columns }) {
  const COL_PADDING_TOP_DEFAULT = (window.innerHeight * 0.8) / pageSize //0.8 cause ContentLoader has height is 80vh
  const COL_HIGHT_DEFAULT = COL_PADDING_TOP_DEFAULT - 15 //-15 is padding top from prev of row

  const loadingColumnsPlaceholder = useMemo(() => {
    let sumWidth = 0
    let loadingColumns = columns
      .filter(t => !t.hidden && !t.disabled)
      .map(item => {
        const itemWidth = item.width ?? COL_WIDTH_DEFAULT
        sumWidth += itemWidth
        return { ...item, width: itemWidth }
      })

    if (sumWidth < window.innerWidth) {
      const lackOfWidthPerItem = (window.innerWidth - sumWidth) / loadingColumns.length
      loadingColumns = loadingColumns.map(item => ({
        ...item,
        width: item.width + lackOfWidthPerItem,
      }))
    }

    return loadingColumns
  }, [columns])

  return (
    <ContentLoader
      width={'100vw'}
      height={'80vh'}
      viewBox="0 0 100vw 80vh"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <>
        {Array.from({ length: pageSize }, (_, rowIndex) => {
          let itemPositionFromLeft = hasContextMenu ? COL_CONTEXT_WIDTH_DEFAULT + COL_PADDING_RIGHT_DEFAULT : 0
          let itemPositionFromTop = (rowIndex + 0.2) * COL_PADDING_TOP_DEFAULT //0.2 is padding from top of table
          return (
            <>
              {/* make context item loading */}
              {hasContextMenu && (
                <rect
                  x={0}
                  y={itemPositionFromTop}
                  rx="4"
                  ry="4"
                  width={COL_CONTEXT_WIDTH_DEFAULT}
                  height={COL_HIGHT_DEFAULT}
                />
              )}

              {/* make columns by available columns props */}
              {loadingColumnsPlaceholder.map((col, index) => {
                let colWidth = (col.width ?? COL_WIDTH_DEFAULT) - COL_PADDING_RIGHT_DEFAULT //-15 to make space between there are td
                if (index > 0) {
                  itemPositionFromLeft +=
                    loadingColumnsPlaceholder[index - 1].width ?? //-1 to get width of prev column, to make position of next item
                    COL_WIDTH_DEFAULT
                }

                return (
                  <rect
                    x={itemPositionFromLeft}
                    y={itemPositionFromTop}
                    rx="4"
                    ry="4"
                    width={colWidth}
                    height={COL_HIGHT_DEFAULT}
                  />
                )
              })}
            </>
          )
        })}
      </>
    </ContentLoader>
  )
}

export function ThSortWrap({ width, align, title, sortOrder, onChangeSort = () => {} }) {
  return (
    <div
      style={{ width: width ?? 'auto' }}
      onClick={onChangeSort}
      className={cx('cursor-pointer w-full flex items-center', align == 'right' ? 'justify-end' : 'justify-start')}
    >
      <span>{title}</span>
      <ThSort sortOrder={sortOrder} />
    </div>
  )
}

export function ThSort({ sortOrder }) {
  return (
    <>
      {sortOrder ? (
        <AppIcon
          className={cx(['ml-1 w-3', sortOrder === 'desc' && 'transform rotate-180'])}
          name={'sorting'}
          title={'Sorting'}
          materialIcon
        />
      ) : (
        <AppIcon className={cx(['ml-1 w-4'])} name={'sort'} title={'Sort'} materialIcon />
      )}
    </>
  )
}

// export function ThFilter({ className, children }) {
//   const renderFilter = () => {
//     return (
//       <Menu>
//         <Menu.Item>{children}</Menu.Item>
//       </Menu>
//     )
//   }
//   return (
//     <span className={cx(['cursor-pointer p-1 flex', className])}>
//       {children && (
//         <Dropdown trigger={['click']} overlay={renderFilter()} placement="bottomRight">
//           <FilterIcon className={cx([''])} height="14" stroke="#ccc" />
//         </Dropdown>
//       )}
//     </span>
//   )
// }

export const ContextMenu = ({ data = [], record, rowIndex }): any => {
  if (!data.length) return null

  return (
    <Menu className="rounded-md">
      {data?.map(({ visible = true, isDivider, title, render, action = () => {}, rightContent }, index) => {
        let isVisible = typeof visible === 'function' ? visible(record) : visible

        if (!isVisible) return null

        if (isDivider) {
          return <li className="border-b border-grey-200"></li>
        }

        return (
          <Menu.Item onClick={() => action(record, rowIndex)}>
            <div className="flex items-center">
              <div className="w-full">{render ? render(title, record) : title}</div>
              {rightContent && rightContent(record)}
            </div>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default observer(AppTable)
