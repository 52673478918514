import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/case-note'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)
function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
const API = {
  ...baseFunctions,
  ExportSearchResult,
}
export default API
