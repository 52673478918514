import { ApiService } from '@services/ApiService'
import { AppRequest } from '@services/baseService'
import { updateURLParams } from '@utils/functions'
import dayjs from 'dayjs'
import _ from 'lodash'
import { action, makeAutoObservable } from 'mobx'

const filterDefault = {
  filterText: '',
  sorting: '',
  tenantId: null,
  fromMonth: dayjs().startOf('month').format('YYYY/MM/DD'),
  toMonth: dayjs().add(1, 'month').endOf('month').format('YYYY/MM/DD'),
}

class Store {
  static storeName = 'ClientActiveCountStore'
  constructor() {
    makeAutoObservable(this)
  }

  loading = true

  data = []
  tenantsData = []

  filter = _.cloneDeep(filterDefault)

  updateFilter = (filterChange, reload = true) => {
    Object.keys(filterChange).forEach(key => {
      if (this.filter.hasOwnProperty(key)) {
        this.filter = {
          ...this.filter,
          [key]: filterChange[key] || null,
        }
      }
    })
    if (!reload) return
    this.fetchData()
  }

  fetchTenantsData = async () => {
    await ApiService.Tenant.GetAll().then(action(res => (this.tenantsData = res.data?.items)))
  }

  fetchData = async () => {
    this.loading = true

    const res = await AppRequest.get(`statistic/clientActiveCount`, { params: this.filter })
    this.data = res.data

    updateURLParams(this.filter)
    this.loading = false
  }

  refetch = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
    this.fetchData()
  }

  resetStore = () => {
    this.filter = { ...filterDefault }
    this.fetchData()
  }
}

export default new Store()
