import dashboardStore from '@stores/dashboard'
import { useMemo } from 'react'
import DashboardRoutes from '../../routes-setting/dashboardRoute'
import { TAB_TITLES } from './consts'

export const getTabTitle = tabId => {
  const currentTab = dashboardStore.tabs.find(i => i.id == tabId)
  let title = currentTab?.title ?? ''
  let postFix = currentTab.postFix ? currentTab.postFix : ''
  if (!title) {
    title = DashboardRoutes[currentTab.route]?.title ?? ''
  }
  const foundTab = TAB_TITLES.find(t => t.title === title)
  if (!title && foundTab) {
    const titleFromLS = localStorage.getItem(foundTab.key)
    if (!!titleFromLS) {
      title = titleFromLS
    } else {
      title = foundTab.tabName
    }
  }

  if (currentTab.title !== title) {
    currentTab.title = title
  }

  return title + postFix
}

const useTab = (tabId, refreshKey) => {
  let title = useMemo(() => {
    return getTabTitle(tabId)
  }, [tabId, refreshKey])

  return {
    title,
  }
}

export default useTab
