import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/specialists'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Enable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id, data?) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/disable`, data)
}

function RequestMoreSpecialistInfo(payload) {
  return ApiRequest.post(`app/search/requestMoreSpecialistInfo`, payload)
}

function GetConfidential(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/confidentialInsight`)
}

function UpdateConfidential(id, preferences) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/confidentialInsight?preferences=${preferences}`, { id, preferences })
}

function AddOrUpdateMyDetail(payload) {
  return ApiRequest.put(`${BaseRoute}/AddOrUpdateMyDetail`, payload)
}

function SpecialistWithPayment(params) {
  return ApiRequest.get(`${BaseRoute}/SpecialistWithPayment`, {
    params,
  })
}

function ExportSpecialistWithPayment(params) {
  return ApiRequest.get(`${BaseRoute}/exportSpecialistWithPayment`, {
    params,
    responseType: 'arraybuffer',
  })
}

function ExportSpecialistRemittanceList(params) {
  return ApiRequest.get(`${BaseRoute}/exportSpecialistRemittanceList`, {
    params,
    responseType: 'arraybuffer',
  })
}

function PrintSpecialistWithPayment(params) {
  return ApiRequest.get(`${BaseRoute}/printSpecialistWithPayment`, {
    params,
  })
}

function SendCommissionRemittanceStatementToSpecialist(ids, params) {
  return ApiRequest.post(`${BaseRoute}/sendCommissionRemittanceStatementToSpecialist`, ids, {
    params,
  })
}

function GetAssignedUsersCount() {
  return ApiRequest.get(`${BaseRoute}/AssignedUsersCount`)
}

function GetListReportType(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/specialistReportType`)
}

function GetSpecialistForSession(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/getSpecialistForSession`)
}

function DisableReportType(id, isSampleReport = false) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/specialistReportType/${id}/disable?isSampleReport=${isSampleReport}`)
}

function AddSpecialistReportType(params) {
  return ApiRequest.post(`${BaseRoute}/addSpecialistReportType/add`, params)
}

function SpecialistReportTypeAttachment(objectId, fromModule) {
  return ApiRequest.post(`${BaseRoute}/specialistReportTypeAttachment?objectId=${objectId}&fromModule=${fromModule}`)
}

function SpecialistSetDefaultCentre(id, centreId, centreOwnershipType) {
  return ApiRequest.put(
    `${BaseRoute}/${id}/defaultCentre?centreId=${centreId}&centreOwnershipType=${centreOwnershipType}`,
  )
}

function GetsimilarItemsByEmail(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByEmail`, { params })
}

function SyncExcludingServiceToAvailableSessions(id) {
  return ApiRequest.put(`${BaseRoute}/SyncExcludingServiceToAvailableSessions/${id}`)
}

function DownloadImportTemplate() {
  return ApiRequest.post(`${BaseRoute}/template-import`, {}, { responseType: 'arraybuffer' })
}
function UploadFiles(file) {
  return ApiRequest.post(`${BaseRoute}/import`, file)
}

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

function getTurnaroundSpecialist(specialistId) {
  return ApiRequest.post(`${BaseRoute}/get-time-process-complete?specialistId=` + specialistId)
}

function UpdateSpecialistReportFee(id, payload = {}) {
  return ApiRequest.post(`${BaseRoute}/${id}/updateSpecialistReportFee`, payload)
}

async function UpdateEmailUsername(id, data) {
  await queryClient.invalidateQueries([BaseRoute, id])
  await queryClient.invalidateQueries([BaseRoute])
  const resp = await ApiRequest.put(`${BaseRoute}/UpdateEmailUsername/${id}`, data)
  return resp
}

function GetEstimateServiceRates(id, typeOfReportId) {
  return ApiRequest.get(`${BaseRoute}/${id}/getEstimateServiceRates/${typeOfReportId}`)
}

function CalculatePastRates() {
  return ApiRequest.post(`${BaseRoute}/calculatePastRate`)
}
function GetBookingHistory(params = {}) {
  return ApiRequest.get(`${BaseRoute}/bookingHistory`, {
    params,
  })
}
function GetListSpecialistContract(specialistId, params) {
  return ApiRequest.get(`${BaseRoute}/${specialistId}/SpecialistContract`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}
function GetSpecialistContract(specialistId, id) {
  return ApiRequest.get(`${BaseRoute}/${specialistId}/SpecialistContract/${id}`)
}
function UpdateSpecialistContract(specialistId, data) {
  queryClient.invalidateQueries([BaseRoute, specialistId])
  return ApiRequest.put(`${BaseRoute}/${specialistId}/SpecialistContract`, data)
}

function DeleteSpecialistContract(specialistId, id) {
  queryClient.invalidateQueries([BaseRoute, specialistId])
  return ApiRequest.delete(`${BaseRoute}/${specialistId}/SpecialistContract/${id}`)
}

const API = {
  ...baseFunctions,
  Enable,
  Disable,
  RequestMoreSpecialistInfo,
  GetConfidential,
  UpdateConfidential,
  AddOrUpdateMyDetail,
  SpecialistWithPayment,
  ExportSpecialistWithPayment,
  PrintSpecialistWithPayment,
  ExportSpecialistRemittanceList,
  SendCommissionRemittanceStatementToSpecialist,
  GetAssignedUsersCount,
  GetListReportType,
  DisableReportType,
  AddSpecialistReportType,
  SpecialistReportTypeAttachment,
  SpecialistSetDefaultCentre,
  GetsimilarItemsByEmail,
  SyncExcludingServiceToAvailableSessions,
  DownloadImportTemplate,
  UploadFiles,
  GetSpecialistForSession,
  ExportSearchResult,
  UpdateEmailUsername,
  getTurnaroundSpecialist,
  UpdateSpecialistReportFee,
  GetEstimateServiceRates,
  CalculatePastRates,
  GetBookingHistory,
  GetListSpecialistContract,
  GetSpecialistContract,
  UpdateSpecialistContract,
  DeleteSpecialistContract,
}

export default API
