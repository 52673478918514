import './TabMore.scss'

import cx from 'classnames'
import { observe } from 'mobx'
import { Observer } from 'mobx-react'
import React from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { Portal } from 'react-portal'
import { Link } from 'react-router-dom'
import dashboardStore from '@stores/dashboard'
import { dropdownRoot } from './portals'
import { TabCloseIcon } from './Tab'
import { getTabTitle } from './useTab'
import { DashboardRouteService, DashboardRoutes } from 'routes-setting/dashboardRoute'

class TabMore extends React.Component<any> {
  clearObserve
  updatePopperPosition
  componentDidMount() {
    // Manually update the dropdown popper position when sidebar get auto-hide
    this.clearObserve = observe(dashboardStore.sidebar, 'collapsed', () => {
      if (this.updatePopperPosition) {
        this.updatePopperPosition()
      }
    })
  }
  componentWillUnmount() {
    this.clearObserve()
  }

  handleOpenTab = (e, tabId) => {
    e.preventDefault()
    dashboardStore.setActiveTab(tabId)
  }
  renderReference = ({ ref }) => {
    const { openTabDropdown } = dashboardStore
    return (
      <div ref={ref} className="Tab TabMore" onClick={openTabDropdown}>
        <Observer render={this.renderReferenceObserver} />
      </div>
    )
  }
  renderReferenceObserver = () => {
    const { tabs, maxTabs } = dashboardStore
    const count = tabs.length - maxTabs
    return (
      <>
        <div className="Tab-Background" />
        <div className="Tab-Content">
          ... <span className="TabMore-Count">{count}</span>
        </div>
      </>
    )
  }

  renderPopper = ({ ref, style, placement, scheduleUpdate }) => {
    this.updatePopperPosition = scheduleUpdate
    return (
      <div ref={ref} style={style} data-placement={placement}>
        <Observer render={this.renderPopperObserver} />
      </div>
    )
  }
  renderPopperObserver = () => {
    const { tabs, maxTabs, tabDropdownActive: active } = dashboardStore
    return <div className={cx('TabMore-Dropdown', { active })}>{tabs.slice(maxTabs).map(this.renderDropdownItem)}</div>
  }

  renderDropdownItem = ({ path, absUrl, id, title }) => {
    if (!DashboardRouteService.getRouteByPathname(path)) return null
    const tabTitle = getTabTitle(id)
    return (
      <Link key={id} to={absUrl} className="menu-item" title={title} onClick={e => this.handleOpenTab(e, id)}>
        {tabTitle}
        <div className="Tab-Close" onClick={e => dashboardStore.closureCloseDropdownTab(e, path, id)}>
          <TabCloseIcon />
        </div>
      </Link>
    )
  }

  render() {
    return (
      <Manager>
        <Reference children={this.renderReference} />
        <Portal node={dropdownRoot}>
          <Popper placement="bottom-end" children={this.renderPopper} />
        </Portal>
      </Manager>
    )
  }
}

export default TabMore
