import { ApiRequest, CreateParams, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/credit-requests'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function UpdateCreditRequest(data) {
  queryClient.invalidateQueries([BaseRoute, data.id])
  return ApiRequest.put(`${BaseRoute}`, data)
}

function GetListAdvanceRequest(creditRequestId) {
  return ApiRequest.get(`${BaseRoute}/${creditRequestId}/advance-requests`, {
    params: {
      creditRequestId,
    },
  })
}
function CheckOnlyOneActiveAR(creditRequestId) {
  return ApiRequest.get(`${BaseRoute}/${creditRequestId}/advance-requests/checkOnlyOneActiveAR`, {
    params: {
      creditRequestId,
    },
  })
}

function CheckDuplicateCreditRequest(params) {
  let qs = CreateParams(params)
  return ApiRequest.get(`${BaseRoute}/check-duplicate-requests?${qs.join('&')}`)
}

function GetAdvanceRequestById(creditRequestId, advanceRequestId) {
  return ApiRequest.get(`${BaseRoute}/${creditRequestId}/advance-requests/${advanceRequestId}`, {
    params: {
      creditRequestId,
      advanceRequestId,
    },
  })
}

function CreateAdvanceRequest(params) {
  queryClient.invalidateQueries([BaseRoute, params.creditRequestId])
  return ApiRequest.post(`${BaseRoute}/${params.creditRequestId}/advance-requests`, params)
}

function UpdateAdvanceRequest(params) {
  queryClient.invalidateQueries([BaseRoute, params.creditRequestId])
  return ApiRequest.put(`${BaseRoute}/${params.creditRequestId}/advance-requests/${params.id}`, params)
}

function DeleteAdvanceRequestById(creditRequestId, advanceRequestId) {
  queryClient.invalidateQueries([BaseRoute, creditRequestId])
  return ApiRequest.delete(`${BaseRoute}/${creditRequestId}/advance-requests/${advanceRequestId}`)
}

function SendCreditRequest(creditRequestId) {
  queryClient.invalidateQueries([BaseRoute, creditRequestId])
  return ApiRequest.get(`${BaseRoute}/${creditRequestId}/send`)
}

function CaculateInterest(params) {
  return ApiRequest.put(`${BaseRoute}/${params.creditRequestId}/advance-requests/${params.id}/calculate`, params)
}
function GetByFundingTransactionId(fundingTransactionId) {
  queryClient.invalidateQueries([BaseRoute, fundingTransactionId])
  return ApiRequest.get(`${BaseRoute}/getByTransactionId/${fundingTransactionId}`)
}
const API = {
  ...baseFunctions,
  GetListAdvanceRequest,
  GetAdvanceRequestById,
  CheckDuplicateCreditRequest,
  CreateAdvanceRequest,
  UpdateAdvanceRequest,
  DeleteAdvanceRequestById,
  UpdateCreditRequest,
  SendCreditRequest,
  CaculateInterest,
  GetByFundingTransactionId,
  CheckOnlyOneActiveAR: CheckOnlyOneActiveAR,
}
export default API
