import appStore from '@stores/appStore'
import { DatePicker } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

export const AppDateRangePicker = ({
  format = 'DD MMM YYYY',
  size = 'middle',
  value = null,
  disabled = false,
  allowClear = true,
  onChange = date => {},
  placeholder = ['Start date', 'End date'],
  enableTime = false,
  hoverText = '',
  ...props
}) => {
  const handleOnChange = date => {
    if (!date) {
      return onChange(null)
    }

    let fromDate = date[0]
    let toDate = date[1]
    if (fromDate) {
      if (enableTime) {
        fromDate = fromDate.startOf('day').toISOString()
      } else {
        fromDate = fromDate.format('YYYY-MM-DD')
      }
    }
    if (toDate) {
      if (enableTime) {
        toDate = toDate.startOf('day').toISOString()
      } else {
        toDate = toDate.format('YYYY-MM-DD')
      }
    }

    onChange([fromDate, toDate])
  }
  const dateFormat = format || appStore.currentUser?.dateFormat

  return (
    <RangePicker
      format={dateFormat}
      size={size as SizeType}
      value={
        value?.length > 0 && value[0]
          ? [enableTime ? dayjs(value[0]) : dayjs.utc(value[0]), enableTime ? dayjs(value[1]) : dayjs.utc(value[1])]
          : value
      }
      disabled={disabled}
      allowClear={allowClear}
      onChange={handleOnChange}
      placeholder={placeholder as [string, string]}
      placement={props.placement}
      {...props}
    />
  )
}
