import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/quickbook'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Connect() {
  return ApiRequest.get(`${BaseRoute}/connect`)
}

const API = {
  ...baseFunctions,
  Connect,
}

export default API
