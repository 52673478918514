import { GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/sale-targets'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const API = {
  ...baseFunctions,
}

export default API
