import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/CaseDictationReports'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const GetItem = (fromModule, objectId, isGetSummary = false) => {
  return ApiRequest.get(`${BaseRoute}?fromModule=${fromModule}&objectId=${objectId}&isGetSummary=${isGetSummary}`)
}

const UpdateDictationFromIME = data => {
  return ApiRequest.put(`/app/assessments/${data.id}/dictation-report`, data)
}
const UpdateDictationFromSupp = params => {
  return ApiRequest.put(`/app/supplementary/${params.id}/dictation-report`, params)
}

const API = {
  ...baseFunctions,
  GetItem,
  UpdateDictationFromIME,
  UpdateDictationFromSupp,
}
export default API
