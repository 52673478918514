import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/lookup-configs'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const API = {
  ...baseFunctions,
  handleDefaultValue,
  CheckIsUsed,
}

function handleDefaultValue(id, isDefault, type = '') {
  return ApiRequest.put(`${BaseRoute}/${id}/${isDefault ? 'UnsetDefaultValue' : 'SetDefaultValue'}`, { type })
}

function CheckIsUsed(id) {
  return ApiRequest.get(`${BaseRoute}/CheckIsUsed/${id}`)
}

export default API
