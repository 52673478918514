import { action } from 'mobx'
import { ApiService } from '@services/ApiService'
import { BaseStore } from '@stores/baseStore'

const defaultState = {}

class Store extends BaseStore {
  constructor() {
    super()

    this.initStore(defaultState, {
      fetchData: action,
    })
  }

  fetchData = async id => {
    try {
      const res = await ApiService.DisbursementFunding.DaySheetPrint(id)
      return res.data
    } catch (error) {
      throw error
    }
  }

  print = async (filters = {}) => {
    try {
      const res = await ApiService.DisbursementFunding.OutStandingStatementPrint(filters)
      return res.data
    } catch (error) {
      throw error
    }
  }
}

export default new Store()
