import { Checkbox } from 'antd'
import cx from 'classnames'

export const AppCheckbox = ({
  disabled = false,
  checked = false,
  onChange = undefined,
  onClick = undefined,
  className = '',
  children = null,
}) => {
  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={cx(['custom-checkbox items-center', className])}
      onClick={onClick}
    >
      {children}
    </Checkbox>
  )
}
