import appStore from '@stores/appStore'
import { observer } from 'mobx-react'
import { useMemo } from 'react'

interface Props {
  dateTime: string
  timeZone: string
}

const TimeZoneInfo = (props: Props) => {
  const timeZoneString = useMemo(() => {
    return appStore.getTimeZoneDisplayName(props.dateTime, props.timeZone)
  }, [props.dateTime, props.timeZone])

  return <>{timeZoneString}</>
}

export default observer(TimeZoneInfo)
