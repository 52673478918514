import { notification } from 'antd'
// import get from 'lodash/get'

export const messages = {
  NetworkFailed: 'Can not connect to the server',
  ServerError: 'An error occurred on the server',
  Unknown: 'An error occurred, please try again',
  ExistEmail:
    'This email has been registered already. You can reset password or contact support@medicolegalassessmentsgroup.com.au for support',
  Unauthorized:
    'You do not have permission to view this data or have been logged out from the system, please refresh the browser and try again',
  RequireAnonymous: 'You have been logged in already, please refresh the browser and try again',

  InvalidModel: 'Your provided data is invalid',

  LoginIncorrect: 'Your username or password is incorrect',
  AccountDisabled:
    'Your account is currently disabled. Please contact MAG IT at support@medicolegalassessmentsgroup.com.au for support',
  AccountLockedOut: 'Your account has been locked out, please try again in 5 minutes',
  AccountRequireVerify: 'Your account requires verification, please check with your administrator',

  InvalidResetCode: 'The link you followed is invalid or expired',
  CaseNotFound: 'This case number does not exist. Please check again',
  Required: 'This field is required',
  Email: 'Invalid email address',
  Password:
    'Password must have at least 8 characters, including special character: !@#$%&*(), uppercase letter: A-Z, lowercase letter: a-z, and digit: 0-9',
}

export const messageError = {
  email: 'Please enter a valid email address',
  url: 'Please enter a valid url starts with http:// | https://',
  max: 'Must be less than or equal to 10 digits',
  min: 'Must be more than or equal to 6 digits',
  required: 'This field is required',
}

export const NotificationRequiredField = 'Please complete required fields.'

export const showNotificationValidate = ({ arrayErrors }: { arrayErrors: Array<unknown | string> }) => {
  const firstInvalid = arrayErrors[0]
  if (firstInvalid === 'required') {
    showNotification('Error', NotificationRequiredField)
  } else {
    showNotification('Error', NotificationRequiredField)
  }
}

export const showNotification = (title = '', msg = '', type = 'error', duration = 5) => {
  return notification[type]({
    message: title,
    description: msg,
    duration: duration,
  })
}

export const showErrorForDev = err => {
  notification.destroy()
  if (err) {
    if (typeof err === 'string') {
      notification.error({
        message: 'Error',
        description: err,
      })
    }
    if (typeof err === 'object') {
      const errorList = err?.response?.data?.error?.validationErrors
      const allowHosts = ['localhost', 'https://kawaconn-test.com/']
      if (allowHosts.indexOf(window.location.hostname) !== -1 && !!(errorList || []).length) {
        errorList.map(i => showNotification('Error', i?.message || 'An error occurred, please try again'))
      } else {
        if (err?.response?.data?.error?.message) {
          const mess = err.response.data.error.message
          showNotification('Error', mess ? mess : 'An error occurred, please try again')
        } else {
          if (err?.response?.data) {
            let arrayBufferConverted = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))
            showErrorForDev(arrayBufferConverted.error.message)
          } else showNotification('Error', 'An error occurred, please try again')
        }
      }
    }
  } else {
    showNotification('Error', 'An error occurred, please try again')
  }
}

const getErrorMessage = err => messages[err.message] || messages[err] || messages.Unknown

const showFetchError = err => {
  notification.destroy()
  setTimeout(() => {
    notification.error({
      message: 'Error',
      description: getErrorMessage(err),
      duration: 0,
      key: 'FetchError',
    })
  }, 170)
}

const showInvalidFormError = () => {
  notification.destroy()
  setTimeout(() => {
    notification.error({
      message: 'Invalid',
      description: messages.InvalidModel,
      duration: 5,
      key: 'InvalidFormError',
    })
  }, 170)
}

export const showUnexpectedError = (message?) => {
  notification.destroy()
  setTimeout(() => {
    notification.error({
      message: 'Error',
      description: message || messages.Unknown,
      duration: 0,
      key: 'UnexpectedError',
    })
  }, 170)
}

export { getErrorMessage, showFetchError, showInvalidFormError }
