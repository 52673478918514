import { ApiRequest } from '../baseService'

const BaseRoute = 'app/migration'

function Migrate() {
  return ApiRequest.post(`${BaseRoute}/MigrateStoreProcedures`)
}

const API = {
  Migrate,
}

export default API
