import { useEffect, useState } from 'react'
import { Input } from 'antd'
import appStore from 'stores/appStore'
import TimeZoneInfo from '@components/TimeZoneInfo'
import cx from 'classnames'

export const AppDateTimeInfo = ({
  format = null,
  date,
  type = 'label',
  dateOnly = false,
  timeZone = null,
  showTimeZone = false,
  valueClassName = '',
}) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (date) {
      if (dateOnly) {
        const dateString = typeof date === 'string' ? date : date.toISOString()

        const ignoreTimeZone = dateString ? dateString.endsWith('T00:00:00Z') : false

        const dateFormat = format ?? 'DD MMM YYYY'

        if (ignoreTimeZone) {
          setValue(appStore.formatDateIgnoreTimezone(date, dateFormat))
        } else {
          setValue(appStore.formatDate(date, timeZone, dateFormat))
        }
      } else {
        const dateFormat = format ?? 'DD MMM YYYY HH:mm'
        setValue(appStore.formatDateTime(date, timeZone, dateFormat))
      }
    } else {
      setValue('')
    }
  }, [date, format, dateOnly])

  return (
    <>
      {type === 'label' && (
        <>
          <span className={cx([valueClassName])}>{value}</span>
          {showTimeZone && (
            <span className={cx([valueClassName])}>
              {' '}
              <TimeZoneInfo dateTime={date} timeZone={timeZone} />
            </span>
          )}
        </>
      )}
      {type === 'input' && <Input disabled value={value} />}
    </>
  )
}
