import { ApiService } from '@services/ApiService'
import { BaseStore } from '@stores/baseStore'
import { autoRegisterObservable } from '@utils/functions'
import { makeObservable } from 'mobx'

class Store extends BaseStore {
  constructor() {
    super()
    makeObservable(this, autoRegisterObservable(this))
  }

  getConfirmationLetter = async id => {
    try {
      const res = await ApiService.MedicalRecord.ConfirmationLetter(id)

      return res.data
    } catch (error) {
      throw error
    }
  }

  getLOATemplate = async id => {
    try {
      const res = await ApiService.MedicalRecord.LOATemplate(id)

      return res.data
    } catch (error) {
      throw error
    }
  }
  getMedicalRequest = async id => {
    try {
      const res = await ApiService.MedicalRecord.MedicalRequest(id)

      return res.data
    } catch (error) {
      throw error
    }
  }
}

export default new Store()
