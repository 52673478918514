import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/mva'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetClaimDetail(objectId) {
  return ApiRequest.get(`${BaseRoute}/${objectId}/claim-details`)
}
function SendClaimEmail(mvaId, data, insurerId = '', isSMS = false) {
  return ApiRequest.post(`${BaseRoute}/${mvaId}/emails?emailType=${data}&isSMS=${isSMS}&insurerId=${insurerId}`)
}
function GetAccidentRelatedDetail(objectId) {
  return ApiRequest.get(`${BaseRoute}/${objectId}/accident-documents`)
}

function UpdateClaimDetail(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/claim-details`, data)
}

function UpdateAccidentRelatedDetail(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/accident-documents`, data)
}

function UpdateAccidentMedicalProvider(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/accident-document-centres`, data)
}

function RemoveAccidentMedicalProvider(mvaId, medicalproviderId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(`${BaseRoute}/${mvaId}/accident-document-centres/${medicalproviderId}`)
}

function SendAccidentMedicalProviderEmail(mvaId, medicalproviderId, data, isSMS = false) {
  return ApiRequest.post(
    `${BaseRoute}/${mvaId}/accident-document-centres/${medicalproviderId}/emails?emailType=${data}&isSMS=${isSMS}`,
  )
}

function GetInsurers(mvaId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/insurers`)
}

function AddOrUpdateInsurer(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers`, data)
}

function RemoveInsurer(mvaId, insurerItemId) {
  return ApiRequest.delete(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}`)
}

function GetInsurerRequestDetail(mvaId, insurerItemId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/insurer-requests`)
}

function UpdateInsurerRequest(mvaId, insurerItemId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/insurer-requests`, data)
}

function GetTreatmentPlan(mvaId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/treatment-plan`)
}

function UpdateTreatmentPlan(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/treatment-plan`, data)
}

function UpdateMedicalProviderTreatment(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/medical-provider-treatments`, data)
}

function RemoveMedicalProviderTreatment(mvaId, treatmentId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(`${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentId}`)
}
function GetTreatmentPlanById(mvaId, treatmentPlanId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}`)
}
function PrintLoa(mvaId, treatmentPlanId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/print-loa`)
}

function PrintLog(mvaId, treatmentPlanId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/print-log`)
}

function UpdateTreatmentPeriod(mvaId, treatmentPlanId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/periods`, data)
}
function RemoveTreatmentPeriod(mvaId, treatmentPlanId, treatmentPeriodId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(
    `${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/periods/${treatmentPeriodId}`,
  )
}
function SendTreatmentPlanEmail(mvaId, treatmentPlanId, data, isSMS) {
  return ApiRequest.post(
    `${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/emails?emailType=${data}&isSMS=${isSMS}`,
  )
}
function AddUpdatePaymentExpenseOfTreatmentPeriod(mvaId, treatmentPlanId, treatmentPeriodId, data) {
  return ApiRequest.put(
    `${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/periods/${treatmentPeriodId}/payments`,
    data,
  )
}

function GetPaymentExpenses(mvaId, treatmentPlanId, treatmentPeriodId) {
  return ApiRequest.get(
    `${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/periods/${treatmentPeriodId}/payments`,
  )
}

function RemovePaymentExpenseOfTreatmentPeriod(mvaId, treatmentPlanId, treatmentPeriodId, paymentId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(
    `${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/periods/${treatmentPeriodId}/payments/${paymentId}`,
  )
}

function AddUpdateMedicalExpenseOfTreatmentPeriod(mvaId, treatmentPlanId, treatmentPeriodId, data) {
  return ApiRequest.put(
    `${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/periods/${treatmentPeriodId}/services`,
    data,
  )
}

function RemoveMedicalExpenseOfTreatmentPeriod(mvaId, treatmentPlanId, treatmentPeriodId, medicalExpensesId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(
    `${BaseRoute}/${mvaId}/medical-provider-treatments/${treatmentPlanId}/periods/${treatmentPeriodId}/services/${medicalExpensesId}`,
  )
}

function RemoveInsurerOffers(mvaId, insurerItemId, insurerOfferId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/insurer-offers/${insurerOfferId}`)
}
function SetDefaultOffer(mvaId, insurerItemId, insurerOfferId) {
  return ApiRequest.put(
    `${BaseRoute}/${mvaId}/insurers/${insurerItemId}/insurer-offers/${insurerOfferId}/setAsCurrentOffer`,
  )
}

function GetInsurerSettlementDetail(mvaId, insurerId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/insurers/${insurerId}/insurer-settlement`)
}
function UpdateSettlementDetail(mvaId, insurerItemId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/insurer-settlement`, data)
}

function UpdateInsurerSettlement(mvaId, insurerItemId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/insurer-settlement`, data)
}

function AddUpdateOfferInsurerDecision(mvaId, insurerItemId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/insurer-offers`, data)
}

function GetDemandLetter(mvaId, insurerItemId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/demand-letter`)
}

function UpdateDemandLetter(mvaId, insurerItemId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/demand-letter`, data)
}

function UpdateDemandLetterTemplate(mvaId, insurerItemId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/demand-letter/update-template`, data)
}

function UpdateServiceItemFees(mvaId, insurerItemId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/serviceItemFees`, data)
}

function RemoveServiceItemFee(mvaId, insurerItemId, serviceId) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.delete(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/serviceItemFees/${serviceId}`)
}

function SendInsurerOfferEmail(mvaId, insurerOfferId, data, isSMS = false) {
  return ApiRequest.post(
    `${BaseRoute}/${mvaId}/insurer-offers/${insurerOfferId}/emails?emailType=${data}&isSMS=${isSMS}`,
  )
}

function GetClientFeeAgreement(mvaId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/client-fees-agreement`)
}

function UpdateClientFeeAgreement(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/client-fees-agreement`, data)
}
function AddFinalDisbursement(mvaId, insurerItemId) {
  return ApiRequest.post(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/final-disbursement`)
}

function PrintFinalDisbursement(mvaId, insurerItemId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/demand-letter/print`)
}

function PrintDemandLetter(mvaId, insurerItemId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/demand-letter/print-demand-letter`)
}

function PrintTerminationLetter(mvaId, insurerItemId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/termination/termination-letter/print`)
}

function RecalculateFinalDisbursement(mvaId, insurerItemId) {
  return ApiRequest.post(`${BaseRoute}/${mvaId}/insurers/${insurerItemId}/final-disbursement/recalculate`)
}

function CalculateLimitationDate(stateId, accidentDate) {
  return ApiRequest.get(`${BaseRoute}/calculateLimitationDate?stateId=${stateId}&accidentDate=${accidentDate}`)
}

function GetTerminationDetail(mvaId) {
  return ApiRequest.get(`${BaseRoute}/${mvaId}/termination`)
}

function UpdateTerminationDetail(mvaId, data) {
  return ApiRequest.put(`${BaseRoute}/${mvaId}/termination`, data)
}

const API = {
  ...baseFunctions,
  GetClaimDetail,
  UpdateClaimDetail,
  GetAccidentRelatedDetail,
  UpdateAccidentRelatedDetail,
  UpdateAccidentMedicalProvider,
  RemoveAccidentMedicalProvider,

  GetInsurers,
  AddOrUpdateInsurer,
  RemoveInsurer,

  GetInsurerRequestDetail,
  UpdateInsurerRequest,

  GetTreatmentPlan,
  UpdateTreatmentPlan,
  UpdateMedicalProviderTreatment,
  RemoveMedicalProviderTreatment,
  GetTreatmentPlanById,
  PrintLoa,
  PrintLog,
  UpdateTreatmentPeriod,
  RemoveTreatmentPeriod,
  AddUpdatePaymentExpenseOfTreatmentPeriod,
  GetPaymentExpenses,

  AddUpdateMedicalExpenseOfTreatmentPeriod,
  RemoveMedicalExpenseOfTreatmentPeriod,
  RemovePaymentExpenseOfTreatmentPeriod,

  GetInsurerSettlementDetail,
  UpdateInsurerSettlement,
  AddUpdateOfferInsurerDecision,
  UpdateSettlementDetail,
  GetDemandLetter,
  UpdateServiceItemFees,
  UpdateDemandLetter,
  UpdateDemandLetterTemplate,
  RemoveServiceItemFee,
  SendClaimEmail,
  SendAccidentMedicalProviderEmail,
  SendTreatmentPlanEmail,
  SendInsurerOfferEmail,
  RemoveInsurerOffers,
  SetDefaultOffer,
  GetClientFeeAgreement,
  UpdateClientFeeAgreement,
  AddFinalDisbursement,
  PrintFinalDisbursement,
  PrintDemandLetter,
  PrintTerminationLetter,
  RecalculateFinalDisbursement,
  CalculateLimitationDate,
  GetTerminationDetail,
  UpdateTerminationDetail,
}

export default API
