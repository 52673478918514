import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/state-holiday'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function getHolidayInfos(id) {
  return ApiRequest.get(`${BaseRoute}/${id}`)
}

function Enable(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

const API = {
  ...baseFunctions,
  getHolidayInfos,
  Enable,
  Disable,
}

export default API
