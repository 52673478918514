import { ApiService } from '@services/ApiService'
import localAccountManager from '@services/localAccountManager'
import _ from 'lodash'
interface AppLocalStorageSetting {
  sideMenuStatus: boolean
  sidebarCollapsed: boolean

  columnSettings: {
    [key: string]: TableColumnSetting[]
  }
  pageSizes: {
    [key: string]: number
  }
}

interface TableColumnSetting {
  name: string
  hidden?: boolean
}

interface AppSessionStorageSetting {
  tabs: any[]
}

type LocalKey = keyof AppLocalStorageSetting

const SettingKeys = [
  {
    key: 'columnSettings',
    isRemote: true,
  },
  {
    key: 'sideMenuStatus',
    isRemote: false,
  },
  {
    key: 'sidebarCollapsed',
    isRemote: false,
  },
  {
    key: 'pageSizes',
    isRemote: true,
  },
]

const defaultSettings = {
  sidebarCollapsed: false,
  sideMenuStatus: false,
  // columnSettings: {},
  pageSizes: {},
}

class LocalStorageManager {
  private _localSetting: AppLocalStorageSetting = null

  private _userTableSettings: {
    [key: string]: TableColumnSetting[]
  } = {}

  private _userTableSettingsLoaded = false
  sessionSetting: AppSessionStorageSetting

  private _tenantId: string

  private get storageKey() {
    return 'tenant_' + this._tenantId
  }

  constructor() {
    this._tenantId = localAccountManager.currentAccount.tenantId

    const sessionSetting: AppSessionStorageSetting = JSON.parse(sessionStorage.getItem(this.storageKey) ?? 'null')
    if (sessionSetting == null) {
      this.sessionSetting = {
        tabs: [],
      }

      this.saveSessionSetting()
    } else {
      this.sessionSetting = sessionSetting
    }
  }

  loadUserTableSettings() {
    return ApiService.SystemConfig.GetUserTableSettings()
      .then(res => {
        let data = res.data ?? []
        if (data == '') {
          data = []
        }
        const tableSettings: { [key: string]: TableColumnSetting[] } = {}
        data.forEach(item => {
          tableSettings[item.tableKey] = item.columns
        })
        this._userTableSettings = tableSettings
      })
      .finally(() => {
        this._userTableSettingsLoaded = true
      })
  }

  private getUserSetting() {
    try {
      let localSetting: AppLocalStorageSetting = JSON.parse(localStorage.getItem(this.storageKey) ?? 'null')
      if (localSetting == null) {
        return _.cloneDeep(defaultSettings)
      } else {
        this._localSetting = localSetting
        return this._localSetting
      }
    } catch {}
  }

  get localSetting() {
    if (this._localSetting == null) {
      return this.getUserSetting()
    } else {
      return this._localSetting
    }
  }

  setLocalItem(key: LocalKey, value: any) {
    if (this._localSetting == null) {
      this._localSetting = _.cloneDeep(defaultSettings)
    }
    this._localSetting[key] = value
    this.saveLocalSetting()
  }

  saveLocalSetting() {
    localStorage.setItem(this.storageKey, JSON.stringify(this._localSetting))
  }

  saveSessionSetting() {
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.sessionSetting))
  }

  clearSessionSetting() {
    sessionStorage.removeItem(this.storageKey)
  }

  private handleSaveColumnSettingsToDatabase = _.debounce(async (tableKey: string, columns: TableColumnSetting[]) => {
    ApiService.SystemConfig.UpdateUserTableSetting({
      tableKey,
      columns,
    })
  }, 1000)

  saveColumnSettings(tableKey: string, columns: TableColumnSetting[]) {
    this._userTableSettings[tableKey] = columns
    this.handleSaveColumnSettingsToDatabase(tableKey, columns)
  }

  async getTableColumns(tableKey: string) {
    if (!this._userTableSettingsLoaded) {
      await this.loadUserTableSettings()
    }

    return this._userTableSettings[tableKey]
  }
}

export default new LocalStorageManager()
