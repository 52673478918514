import { useEffect } from 'react'
import { observer } from 'mobx-react'
import store from './store'
import { getURLParams, removeAllURLParams } from '@utils/functions'
import Table from './Table'
import TableFilter from './TableFilter'
import { TableWrapper } from '@components/table'
import appStore from '@stores/appStore'

const ClientActiveCount = () => {
  const params: any = getURLParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    removeAllURLParams()

    if (appStore.isHostTenant) {
      await store.fetchTenantsData()
      if (store.tenantsData?.length > 0) {
        store.updateFilter({ ...params, tenantId: store.tenantsData[0].id })
      }
    } else {
      store.updateFilter({ ...params, tenantId: appStore.currentTenant.id })
    }

    store.fetchData()
  }

  return (
    <TableWrapper loading={store.loading && !store.data}>
      <TableFilter />
      <Table />
    </TableWrapper>
  )
}

export default observer(ClientActiveCount)
