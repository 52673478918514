import { GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/time-budget-breakdown'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const API = {
  ...baseFunctions,
}

export default API
