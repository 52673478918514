import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { getQueryParams } from '@utils/functions'
import router from '@stores/router'
import { ApiService } from '@services/ApiService'

const SupplementaryPrint = () => {
  const { id, type } = getQueryParams(router.location.search, router.location.state)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    document.title = getTabName(type)
    if (id) {
      setLoading(true)
      getSupplementaryPrint(id, getFunctionGetSupplementaryPrint(type))
    } else {
      setError(new Error('Missing parameter id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTabName = type => {
    switch (type) {
      case 'printServiceRequest':
        return 'Print Service Request'
      default:
        return 'kawaconn Supplementary Print'
    }
  }

  const getFunctionGetSupplementaryPrint = type => {
    switch (type) {
      case 'printServiceRequest':
        return ApiService.Supplementary.PrintServiceRequest
      default:
        return null
    }
  }

  const getSupplementaryPrint = async (id, functionGetSupplementaryPrint) => {
    try {
      const { data } = await functionGetSupplementaryPrint(id)
      setData(data)
      setLoading(false)
      if (!data) throw new Error('Cannot found')
    } catch (err) {
      setLoading(false)
      setError(error)
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center" style={{ height: window.innerHeight }}>
        <LoadingOutlined style={{ fontSize: '75px', color: 'var(--blue-400)' }} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="container text-lg">
        <h1>Something Error</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  if (!data) return null

  return <div className="flex justify-center items-center mt-12" dangerouslySetInnerHTML={{ __html: data }}></div>
}

export default observer(SupplementaryPrint)
