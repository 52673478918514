import ClinicalPrint from '@pages/clinical-record/clinical-record-print'
import ClinicalLOAPrint from '@pages/clinical-record/clinical-record-print/PrintLOATemplate'
import PrintMedicalRequest from '@pages/clinical-record/clinical-record-print/PrintMedicalRequest'
import InvoicePrint from '@pages/invoice/invoice-print'
import OutstandingStatementPrintInvoice from '@pages/invoice/invoice-print/outstandingStatement'
import DisbursementPrint from '@pages/disbursement/disbursement-funding/print/index'
import RepaymentInvoicePrint from '@pages/disbursement/transaction-invoice/print/index'
import OutstandingStatementPrint from '@pages/disbursement/disbursement-funding/print/outstandingStatement'
import OutstandingStatementNewPrint from '@pages/disbursement/transaction-invoice/print/outstandingStatement'
import DaySheetPrint from '@pages/doctor-routes/DaySheetPrint'
import AssessmentTemplate from '@pages/assessment/assessment-details/components/Template'
import MvaTemplate from '@pages/mva-claim/detail/components/Template'
import NdisTemplate from '@pages/ndis/details/components/Template'
import VideoSession from '@pages/video-meeting/AppVideoMeeting'
import PrintSpecialistWithPayment from '@pages/specialist/specialist-remittance/PrintSpecialistWithPayment'
import SupplementaryPrint from '@pages/supplementary/supplementary-print'
import AdvancePrint from '@pages/disbursement/facility-agreements/detail/advance-print'
import { ROLE_NAME } from '@utils/constants'
import { RouteConfig } from './interface'

export const EmptyRoutes: RouteConfig[] = [
  {
    path: '/view/clinical-notes/print-letter-of-confirmation',
    Component: ClinicalPrint,
    title: 'Print Clinical',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/clinical-notes/print-loa-template',
    Component: ClinicalLOAPrint,
    title: 'Print Clinical',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/clinical-notes/print-medical-request',
    Component: PrintMedicalRequest,
    title: 'Print Medical Request',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/invoices/print-invoice',
    Component: InvoicePrint,
    title: 'Print Invoice',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/invoices/outstanding-statement-print',
    Component: OutstandingStatementPrintInvoice,
    title: 'Print Outstanding statement',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/daysheet-print',
    Component: DaySheetPrint,
    title: 'Booking Daysheet',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.SPECIALIST, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/disbursement-print',
    Component: DisbursementPrint,
    title: 'Funding Transaction Invoice',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/repaymentinvoice-print',
    Component: RepaymentInvoicePrint,
    title: 'Funding Transaction Invoice',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/outstanding-statement-print',
    Component: OutstandingStatementPrint,
    title: 'Outstanding Statement',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/repaymentinvoice/outstanding-statement-print',
    Component: OutstandingStatementNewPrint,
    title: 'Outstanding Statement',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/ime-assessment/template',
    Component: AssessmentTemplate,
    title: 'Assessment Template',
  },
  {
    path: '/view/supplementary/print',
    Component: SupplementaryPrint,
    title: 'Supplementary Print',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.SPECIALIST],
  },
  {
    path: '/view/agreement-advance/print',
    Component: AdvancePrint,
    title: 'Agreement Print',
  },
  {
    path: '/view/video-old',
    Component: VideoSession,
    title: 'View Video',
  },
  {
    path: '/view/specialist-remittance/print',
    Component: PrintSpecialistWithPayment,
    title: 'Print Specialist With Payment',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/mva/template',
    Component: MvaTemplate,
    title: 'Mva Template',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/ndis/template',
    Component: NdisTemplate,
    title: 'Ndis Template',
  },
]
