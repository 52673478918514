import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/background-jobs'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const API = {
  ...baseFunctions,
}

export default API
