import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import { getQueryParams } from '@utils/functions'
import router from '@stores/router'
import { ApiService } from '@services/ApiService'
import PdfViewer from '@components/PdfViewer'

const Template = () => {
  const { id, type } = getQueryParams(router.location.search, router.location.state)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    document.title = getTabName(type)
    if (id) {
      setLoading(true)
      getTemplate(id, getFunctionGetTemplate(type))
    } else {
      setError(new Error('Missing parameter id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTabName = type => {
    switch (type) {
      case 'serviceAgreement':
        return 'Service Agreement'
      case 'consentForm':
        return 'Consent Form'
      case 'intake':
        return 'Intake'
      case 'participantReferalForm':
        return 'NDIS Client Referral Form'
      default:
        return 'kawaconn Template'
    }
  }

  const getFunctionGetTemplate = type => {
    switch (type) {
      case 'serviceAgreement':
        return ApiService.NDIS.ViewServiceAgreement
      case 'participantReferalForm':
        return ApiService.NDIS.ViewParticipantReferalForm
      // case 'consentForm':
      //   return ApiService.NDIS.ViewConsentForm
      // case 'intake':
      //   return ApiService.NDIS.ViewIntake
      default:
        return null
    }
  }

  const getTemplate = async (id, functionGetTemplate) => {
    try {
      const resp = await functionGetTemplate(id)
      setData(resp.data)
      setLoading(false)
      if (!data) throw new Error('Cannot found')
    } catch (err) {
      setLoading(false)
      setError(error)
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center" style={{ height: window.innerHeight }}>
        <LoadingOutlined style={{ fontSize: '75px', color: 'var(--blue-400)' }} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="container text-lg">
        <h1>Something Error</h1>
        <p>{error.message}</p>
      </div>
    )
  }

  if (!data) return null

  return <PdfViewer rawData={data} />
  // return <div className="flex justify-center items-center mt-12" dangerouslySetInnerHTML={{ __html: data }}></div>
}

export default observer(Template)
