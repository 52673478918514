import React, { memo } from 'react'
import cx from 'classnames'
import { Button } from 'antd'
import InputHint from '../InputHint'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import TooltipInfo from '@components/TooltipInfo'

enum Colors {
  default,
  red,
  blue,
  green,
  black,
  yellow,
}

type ColorType = keyof typeof Colors

type Props = {
  children?: React.ReactNode
  className?: string
  color?: ColorType
  size?: SizeType
  rounded?: boolean
  circle?: boolean
  outline?: boolean
  dashed?: boolean
  disabled?: boolean
  tooltip?: React.ReactElement
  onClick?: (e: any) => void
  loading?: boolean
  [rest: string]: any
}

export const AppButton = memo(
  ({
    children = null,
    className = '',
    color = 'default',
    rounded = true,
    circle = false,
    outline = false,
    dashed = false,
    loading = false,
    disabled = false,
    size = 'middle',
    tooltip = null,
    onClick = (e: any) => {},
    ...rest
  }: Props) => {
    const colorClassName = () => {
      if (outline) {
        return `${color}-outline`
      }
      if (dashed) {
        return `${color}-outline-dashed`
      }
      return color
    }

    const getShapeClassName = () => {
      if (circle) {
        return 'circle'
      }

      if (rounded) {
        return 'round'
      }
    }

    const colorText = colorClassName()

    const handleClick = (e: any) => {
      if (disabled) {
        e.preventDefault()
        e.stopPropagation()
        return
      }
      if (onClick) {
        onClick(e)
      }
    }

    return (
      <Button
        shape={getShapeClassName()}
        loading={loading}
        // disabled={disabled}
        onClick={handleClick}
        className={cx([
          'inline-flex items-center justify-center',
          disabled ? 'bg-grey-400 cursor-not-allowed' : 'cursor-pointer',
          className,
          `btn-${colorText}`,
        ])}
        size={size}
        {...rest}
      >
        {children}
        {tooltip && <TooltipInfo iconColor="text-white" text={tooltip} />}
        <InputHint text={rest.hint} />
      </Button>
    )
  },
)
